import AudioApi from '@phoenix7dev/audio-api';

import { AnticipationType } from '../../../global';
import ViewContainer from '../../components/container';
import { layerReelBack } from '../../components/layers/layers';
import {
  EventTypes,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  eventManager,
} from '../../config';

import { ISongs } from './../../../config/audio/sprite.generated';
import { TickerSpine } from './../../components/spine/index';

class ReelsBackgroundContainer extends ViewContainer {
  private bgSpine: TickerSpine<'reelframe'>;

  constructor() {
    super();

    this.bgSpine = new TickerSpine('reelframe');
    this.bgSpine.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.addChild(this.bgSpine);

    this.bgSpine.state.setAnimation(0, 'back', true);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_REELFRAME_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));

    eventManager.addListener(EventTypes.START_CUTIN_ANIMATION, this.startReelFrameAnimation.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));

    //    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupCutinAnimation.bind(this));

    this.parentLayer = layerReelBack;
  }

  private onModeChange(): void {}

  private onStartSpin(): void {}

  private onReelStopped() {}

  private setLongSpinFrameVisible(_value: boolean) {}

  private longSpinFrameAnimationReset() {}
  private forceStopReels(_isTurboSpin: boolean, allowForceReelStop?: boolean) {
    if (allowForceReelStop) {
      this.setLongSpinFrameVisible(false);
      this.longSpinFrameAnimationReset();
    }
  }

  private startReelFrameAnimation() {}

  private onAnticipationAnimationStarts(_reelIdx: number, type: AnticipationType, durationType: number): void {
    if (type != 'None') {
      this.setLongSpinFrameVisible(true);

      const AudioDurationToString = ['short', 'middle', 'long'];
      const AudioSlotType = type === 'Bonus' || type === 'BonusSnow' ? 'SC2' : 'SC1';

      const audioName = `XT004S_${AudioSlotType}_longspin_${AudioDurationToString[durationType]}`;
      AudioApi.play({ type: audioName, stopPrev: true });
    }
  }

  private onAnticipationAnimationEnd(): void {
    this.setLongSpinFrameVisible(false);
    this.longSpinFrameAnimationReset();
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_short, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_middle, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC1_longspin_long, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_short, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_middle, stopPrev: true });
    AudioApi.stop({ type: ISongs.XT004S_SC2_longspin_long, stopPrev: true });
  }
}

export default ReelsBackgroundContainer;
