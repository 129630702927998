import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { MessageFreeSpinsBannerProps } from '../../global';
import i18n from '../../i18next';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine/index';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  FREESPIN_START_BANNER_DESCRIPTION_POS,
  FREESPIN_START_BANNER_MULTIPLIER_POS,
  FREESPIN_START_BANNER_RANK_POS,
  FREESPIN_START_BANNER_RANK_TITLE_POS,
  FREESPIN_START_BANNER_TITLE_PORTRAIT_POS,
  FREESPIN_START_BANNER_TITLE_POS,
} from './config';
import {
  freeSpinStartBannerDescriptionStyles,
  freeSpinStartBannerMultiplierStyles,
  freeSpinStartBannerRankStyles,
  freeSpinStartBannerRankTitleStyles,
  freeSpinStartBannerTitleStyles,
} from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private bg: TickerSpine<'prize'>;

  private title: AutoResizeText;

  private rankTitle: AutoResizeText;
  private rank: AutoResizeText;

  private description: AutoResizeText;
  private multiplier: AutoResizeText;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;

    this.bg = new TickerSpine('prize');
    this.bg.update(0);

    this.title = this.initTitle();
    this.rankTitle = this.initRankTitle();
    this.rank = this.initRank();
    this.description = this.initDescription();
    this.multiplier = this.initMultiplier();
  }

  public override init(): MessageFreeSpinsBanner {
    const fishConvert: Record<FishKind, SpineInterface['prize']['animations']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    super.init();
    this.bg.state.setAnimation(0, 'front', true);
    this.bg.state.setAnimation(1, fishConvert[this.props.fishRank], true);
    this.addChild(this.bg);

    this.addChild(this.title, this.rankTitle, this.rank, this.description, this.multiplier);
    return this;
  }

  protected initTitle(): AutoResizeText {
    const autoResizeText = new AutoResizeText(i18n.t('freeSpinStartBanner.title'), freeSpinStartBannerTitleStyles);
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_TITLE_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_TITLE_POS.y;
    return autoResizeText;
  }

  protected initRankTitle(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.rankTitle'),
      freeSpinStartBannerRankTitleStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_RANK_TITLE_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_RANK_TITLE_POS.y;
    return autoResizeText;
  }

  protected initRank(): AutoResizeText {
    const fishConvert: Record<FishKind, string> = {
      Rank1: 'AAAAA',
      Rank2: 'AAAA',
      Rank3: 'AAA',
      Rank4: 'AA',
      Rank5: 'A',
      Rank6: 'B',
      Rank7: 'C',
    };

    const autoResizeText = new AutoResizeText(fishConvert[this.props.fishRank], freeSpinStartBannerRankStyles);
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_RANK_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_RANK_POS.y;
    return autoResizeText;
  }

  protected initDescription(): AutoResizeText {
    const autoResizeText = new AutoResizeText(
      i18n.t('freeSpinStartBanner.description'),
      freeSpinStartBannerDescriptionStyles,
    );
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_DESCRIPTION_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_DESCRIPTION_POS.y;
    return autoResizeText;
  }

  protected initMultiplier(): AutoResizeText {
    const fishConvert: Record<FishKind, string> = {
      Rank7: 'x15',
      Rank6: 'x40',
      Rank5: 'x100',
      Rank4: 'x300',
      Rank3: 'x800',
      Rank2: 'x2000',
      Rank1: 'x5000',
    };
    const autoResizeText = new AutoResizeText(fishConvert[this.props.fishRank], freeSpinStartBannerMultiplierStyles);
    autoResizeText.anchor.set(0.5);
    autoResizeText.x = FREESPIN_START_BANNER_MULTIPLIER_POS.x;
    autoResizeText.y = FREESPIN_START_BANNER_MULTIPLIER_POS.y;
    return autoResizeText;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;

      this.title.x = FREESPIN_START_BANNER_TITLE_PORTRAIT_POS.x;
      this.title.y = FREESPIN_START_BANNER_TITLE_PORTRAIT_POS.y;
    } else {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;

      this.title.x = FREESPIN_START_BANNER_TITLE_POS.x;
      this.title.y = FREESPIN_START_BANNER_TITLE_POS.y;
    }
  }
}
