import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../config';
import { GameMode } from '../consts';
import { setDefaultReelSetId, setLastBaseReelPositions } from '../gql/cache';
import Tween from '../slotMachine/animations/tween';
import { BgType } from '../slotMachine/background/background';
import { BgmControl } from '../slotMachine/bgmControl/bgmControl';
import { EventTypes, eventManager } from '../slotMachine/config';
import { BonusDataByBonusId, BonusKind, BuyFeatureBonusInfo, FreespinBonusInfo } from '../slotMachine/config/bonusInfo';

export const findBonusInfoByBonusId = (id: string | undefined): FreespinBonusInfo | BuyFeatureBonusInfo | undefined => {
  if (id) {
    return BonusDataByBonusId[id];
  } else {
    return undefined;
  }
};

export const isBuyFeatureTypeByBonusId = (id: string): boolean => {
  const bonusInfo = findBonusInfoByBonusId(id);
  if (bonusInfo?.bonusKind === BonusKind.BUY_FEATURE) {
    return true;
  }
  return false;
};

export const getBgTypeForGameMode = (gamemode: GameMode): BgType => {
  if (gamemode === GameMode.FREE_SPINS) {
    return 'freeSpin01';
  } else {
    return 'default';
  }
};

export const bannerWaitBgmPlay = (waitSound: ISongs, bg: BgType, delay: number) => {
  AudioApi.play({ type: waitSound, stopPrev: true });
  BgmControl.setRestrict(true);
  //const audiodelay = Tween.createDelayAnimation(durationOf(waitSound));
  const audiodelay = Tween.createDelayAnimation(delay);
  audiodelay.addOnComplete(() => {
    BgmControl.setRestrict(false);
    BgmControl.playBgm(bg, true, true);
  });
  audiodelay.start();
};

export const isFishingChallengeMode = (mode: GameMode): boolean => {
  return mode === GameMode.FISH_CHALLENGE;
};

export const transitionToBaseGame = (additionalCallback?: () => void): void => {
  eventManager.emit(EventTypes.TRANSITION_START, {
    type: 'Fade',
    fadeOutDuration: 500,
    fadeInDuration: 500,
    tint: 0x000000,
    callback: () => {
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.BASE_GAME,
        reelSetId: setDefaultReelSetId(),
        reelPositions: setLastBaseReelPositions(),
        bgType: 'default',
      });

      additionalCallback && additionalCallback();
    },
  });
};
