import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs } from '../../config/audio';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { layerAllScreen } from '../components/layers/layers';
import { EventTypes, ReelState, eventManager } from '../config';

class Phoenix extends PIXI.Container {
  private videoResource!: PIXI.VideoResource;

  private video: PIXI.Sprite;

  private isPlay: boolean;

  private windowWidth = 0;
  private windowHeight = 0;

  constructor() {
    super();
    this.visible = false;
    this.isPlay = false;

    const base = new PIXI.Sprite(PIXI.Texture.WHITE);
    base.anchor.set(0.5);
    base.tint = 0;
    base.width = 4096;
    base.height = 4096;

    /*    this.videoResource = new PIXI.VideoResource(PIXI.Loader.shared.resources['phoenixMovie']!.url, {
      autoPlay: false,
    });
    const baseTex = new PIXI.BaseTexture(this.videoResource);
    const tex = new PIXI.Texture(baseTex);
    this.video = new PIXI.Sprite(tex);
    this.video.anchor.set(0.5);
    this.video.scale.set(1.5);
    */
    this.video = this.initVideo();
    this.addChild(base, this.video);
    this.zIndex = 1000;
    this.interactive = false;

    this.initMovieListener();

    eventManager.addListener(EventTypes.PHOENIX_START, this.startMovie.bind(this));

    eventManager.on(EventTypes.APP_VISIBILITY_CHANGE, (isActive: boolean) => {
      this.isPlay = false;
      this.visible = false;
      this.interactive = false;

      if (isActive) {
        this.removeChild(this.video);
        this.video = this.initVideo();
        this.addChild(this.video);

        this.initMovieListener();

        this.resize(this.windowWidth, this.windowHeight);
      }
    });

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    this.parentLayer = layerAllScreen;
  }

  private initVideo(): PIXI.Sprite {
    this.videoResource = new PIXI.VideoResource(PIXI.Loader.shared.resources['phoenixMovie']!.url, {
      autoPlay: false,
    });
    const baseTex = new PIXI.BaseTexture(this.videoResource);
    const tex = new PIXI.Texture(baseTex);
    const videoSprite = new PIXI.Sprite(tex);
    videoSprite.anchor.set(0.5);
    videoSprite.scale.set(1.5);
    return videoSprite;
  }
  private initMovieListener(): void {
    this.videoResource.source.addEventListener('ended', () => {
      this.visible = false;
      this.interactive = false;
    });
    this.videoResource.source.addEventListener('play', () => {
      this.visible = true;
      this.interactive = true;
      this.alpha = 0;

      const chain = new AnimationChain();
      chain.appendAnimation(
        new Tween({
          object: this,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.ALPHA,
          duration: 200,
        }),
      );

      chain.appendAnimation(Tween.createDelayAnimation(this.videoResource.source.duration * 1000 - 600));

      chain.appendAnimation(
        new Tween({
          object: this,
          propertyBeginValue: 1,
          target: 0,
          property: TweenProperties.ALPHA,
          duration: 600,
        }),
      );

      chain.start();
    });

    this.videoResource.source.addEventListener('seeked', () => {
      if (this.isPlay) {
        this.videoResource.source.play();
        this.isPlay = false;
      }
    });
  }

  private startMovie(): void {
    const movieDelay = Tween.createDelayAnimation(750);
    movieDelay.addOnComplete(() => {
      if (SlotMachine.getInstance().reelsContainers[0]?.reels[1]!.state === ReelState.IDLE) {
        return;
      }

      this.videoResource.source.pause();
      if (this.videoResource.source.fastSeek) {
        this.videoResource.source.fastSeek(0);
      } else {
        this.videoResource.source.currentTime = 0;
      }
      this.isPlay = true;
      this.interactive = true;
    });

    AudioApi.play({ type: ISongs.XT004S_phoenix_sign, stopPrev: true });
    movieDelay.start();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    this.video.position.set(width / 2, height / 2);

    const tex = this.video.texture;
    const bgAspectRatio = tex.width / tex.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.video.scale.set(height / 960);
    } else {
      this.video.scale.set(width / 960);
    }
  }
}
export default Phoenix;
