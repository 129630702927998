import * as PIXI from 'pixi.js';

import { GameMode } from '../../consts';
import {
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsStopOnFeatureWin,
  setNextResult,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ISettledBet } from '../../types';
import { hasWin, isBaseGameMode, isFishingChallengeMode, isFreeSpinsBaseMode, isFreeSpinsMode } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { layerMessageWindow } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import {
  EventTypes,
  MESSAGE_WINDOW_PORTRAIT_POSITION_X,
  MESSAGE_WINDOW_PORTRAIT_POSITION_Y,
  MESSAGE_WINDOW_POSITION_X,
  MESSAGE_WINDOW_POSITION_Y,
  eventManager,
  messageWindowAutoResizeTextStyles,
} from '../config';
import { IWinLine } from '../d';

class MessageWindow extends PIXI.Container {
  private frame: TickerSpine<'message_frame'>;

  private spinCounter: number;

  private messageText: AutoResizeText;

  //private preGameMode: GameMode = GameMode.BASE_GAME;

  constructor() {
    super();

    this.frame = new TickerSpine('message_frame');
    this.frame.state.setAnimation(0, 'base');
    this.frame.visible = true;
    this.spinCounter = 0;

    this.messageText = this.initMessageText();

    this.addChild(this.frame, this.messageText);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));

    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.hideLine.bind(this));
    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.showLine.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.onWinMessageBanner.bind(this));

    eventManager.addListener(EventTypes.SET_IS_IN_TRANSITION, this.transitionChange.bind(this));
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.showFreeSpinWin.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.addListener(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = false;
    });
    eventManager.addListener(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.messageText.visible = true;
    });

    eventManager.addListener(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      if (isFreeSpinsBaseMode(setGameMode())) {
        return;
      }
      if (!isAutoSpins) this.messageText.visible = true;
    });

    //fish
    eventManager.addListener(EventTypes.FISHING_WHEEL_SPIN_START, this.fishingSpinStart.bind(this));
    eventManager.addListener(EventTypes.FISHING_WHEEL_SPIN_STOP, this.fishingSpinStop.bind(this));
    eventManager.addListener(EventTypes.FISHING_GAMBLE_SELECT_START, this.fishingGambleSelectStart.bind(this));
    eventManager.addListener(EventTypes.FISHING_G_SELECT_SELECTED, this.fishingGambleSelectSelected.bind(this));
    eventManager.addListener(EventTypes.FISHING_GAMBLE_START, this.fishingGambleStart.bind(this));

    this.x = MESSAGE_WINDOW_POSITION_X;
    this.y = MESSAGE_WINDOW_POSITION_Y;

    this.parentLayer = layerMessageWindow;
  }

  private initMessageText(): AutoResizeText {
    const messageText = new AutoResizeText('', messageWindowAutoResizeTextStyles);
    messageText.x = 0;
    messageText.y = 0;
    messageText.anchor.set(0.5);
    /*
    messageText.style.fill = messageText.context.createPattern(
      PIXI.Loader.shared.resources[ResourceTypes.textPatternMessageFrame]!.data,
      'repeat',
    )!;
    */
    return messageText;
  }

  private updateText(text: string) {
    this.messageText.text = text;
    if (!setIsBuyFeaturePopupOpened()) {
      this.messageText.visible = true;
    }
  }

  private transitionChange(isInTransition: boolean) {
    if (!isInTransition && isBaseGameMode(setGameMode()) && !setIsAutoSpins() && setBrokenBuyFeatureGame() === '') {
      this.updateText(i18n.t('messageWindow.idol'));
    }
  }

  private onStartSpin() {
    if (isFreeSpinsBaseMode(setGameMode())) {
      //console
    } else {
      const messages: string[] = i18n.t('messageWindow.spin', { returnObjects: true });
      this.updateText(messages[this.spinCounter]!);
      this.spinCounter = (this.spinCounter + 1) % messages.length;
    }
  }

  private onReelStopped() {
    if (!isFreeSpinsMode(setGameMode()) && !hasWin(setNextResult())) {
      this.updateText(i18n.t('messageWindow.idol'));
      if (setIsAutoSpins()) {
        this.messageText.visible = false;
      }
    }
  }

  private onStartWinAnimation(_nextResult: ISettledBet): void {
    if (isFreeSpinsBaseMode(setGameMode())) {
      //this.updateText(i18n.t('messageWindow.win'));
    } else {
      this.updateText(i18n.t('messageWindow.win'));
    }
  }

  private showLine(_lines: IWinLine[]): void {
    /*
    if (isFreeSpinsBaseMode(setGameMode())) {
      if (lines.some((line) => line.lineNumber !== null)) {
        this.updateText(i18n.t('messageWindow.fs_win'));
      } else {
        this.updateText(i18n.t('messageWindow.win'));
      }
    }
      */
    this.updateText(i18n.t('messageWindow.win'));
  }

  private showFreeSpinWin() {
    this.updateText(i18n.t('messageWindow.fc_trigger'));
  }

  private hideLine() {
    /*if (isFreeSpinsBaseMode(setGameMode()) || setGameMode() === GameMode.FREE_SPINS_RESPIN) {
      this.updateText('');
    }*/
  }

  private onModeChange(settings: { mode: GameMode }) {
    const message = isFishingChallengeMode(settings.mode) ? 'messageWindow.trigger' : 'messageWindow.idol';
    this.updateText(i18n.t(message));
    if (
      (!isFreeSpinsBaseMode(settings.mode) && setIsAutoSpins()) ||
      (isFreeSpinsBaseMode(settings.mode) && setBrokenGame())
    ) {
      this.messageText.visible = false;
    }

    const isAutoSkipTitle =
      isFreeSpinsBaseMode(settings.mode) && setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin()
        ? true
        : false;
    if (isAutoSkipTitle) {
      this.messageText.visible = false;
    }

    if (settings.mode === GameMode.FREE_SPINS) {
      this.messageText.visible = false;
    }

    if (isFishingChallengeMode(settings.mode)) {
      this.spinCounter = 0;
    }

    //this.preGameMode = settings.mode;
  }

  private onWinMessageBanner() {}

  private fishingSpinStart() {
    const messages: string[] = i18n.t('messageWindow.fc_round', { returnObjects: true });
    this.updateText(messages[this.spinCounter % messages.length]!);
    this.spinCounter = (this.spinCounter + 1) % messages.length;
  }

  private fishingSpinStop(isSuccess: boolean) {
    const message = isSuccess ? 'messageWindow.fc_nice' : 'messageWindow.fc_miss';
    this.updateText(i18n.t(message));
  }

  private fishingGambleSelectStart() {
    const message = 'messageWindow.gc_idol';
    this.updateText(i18n.t(message));
  }

  private fishingGambleSelectSelected(isSelected: boolean) {
    const message = isSelected ? 'messageWindow.gc_confirm' : 'messageWindow.gc_idol';
    this.updateText(i18n.t(message));
  }

  private fishingGambleStart() {
    const message = 'messageWindow.fg_round';
    this.updateText(i18n.t(message));
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = MESSAGE_WINDOW_POSITION_X;
      this.y = MESSAGE_WINDOW_POSITION_Y;
    } else {
      this.x = MESSAGE_WINDOW_PORTRAIT_POSITION_X;
      this.y = MESSAGE_WINDOW_PORTRAIT_POSITION_Y;
    }
  };
}

export default MessageWindow;
