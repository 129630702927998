import { SlotId } from '../../config';
import { IconType } from '../../consts';

export const icons = [
  {
    id: SlotId.A,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.B,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.C,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.D,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.E,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.F,
    type: IconType.REGULAR,
  },
  {
    id: SlotId.SC1,
    type: IconType.SCATTER,
  },
  {
    id: SlotId.SC2,
    type: IconType.SCATTER,
  },
  {
    id: SlotId.SC3,
    type: IconType.SCATTER,
  },
  {
    id: SlotId.SC4,
    type: IconType.SCATTER,
  },
];
