/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import type { FishKind, SlotId } from '../../config';
import type { GameMode } from '../../consts';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  FishKind: { input: FishKind; output: FishKind };
  GameMode: { input: GameMode; output: GameMode };
  JSON: { input: unknown; output: unknown };
  JSONObject: { input: unknown; output: unknown };
  NonNegativeFloat: { input: number; output: number };
  NonNegativeInt: { input: number; output: number };
  SlotId: { input: SlotId; output: SlotId };
  UUID: { input: string; output: string };
  UnsignedInt: { input: number; output: number };
  Void: { input: never; output: never };
};

export type AfricanAnimalsGameMode = 'BASE_GAME' | 'BUY_FEATURE' | 'FREE_SPINS';

export type AfricanAnimalsOutcomeSnapshot = {
  /** 3 row x 5 columns symbol field as flat array of symbol ID's. */
  fieldState: Array<Maybe<Scalars['String']['output']>>;
  /**
   * True if rewarded Free Spins bonus.
   * Next wager will be of FREE_SPINS game mode.
   */
  hasRewardedFreeSpins: Scalars['Boolean']['output'];
  /** Winning combinations. */
  winCombinations: Array<Maybe<WinCombination>>;
};

export type AfricanAnimalsPredicament = {
  /** Positions of Mystery Symbols. Empty array if no mystery symbols on field. */
  mysteryPositions: Array<Maybe<Scalars['Int']['output']>>;
  /**
   * ID of Mystery Symbol Replacement.
   * Can be any Symbol ID except for "MS".
   * Sent regardless if mystery symbols array is empty or not.
   */
  mysterySymbol: Scalars['String']['output'];
  /** Tumbling reel positions. */
  reelPositions: Array<Maybe<Scalars['Int']['output']>>;
};

export type AfricanAnimalsWagerSettings = {
  /** Game Mode signifier. */
  gameMode: AfricanAnimalsGameMode;
  originalGameMode: Maybe<AfricanAnimalsGameMode>;
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<WagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

/** Input type for creating a new bet. */
export type AssignBonusInput = {
  /** ID of the Client relevant to this operation. */
  clientId: Scalars['String']['input'];
  /** Number of coins bet on the Bonus. */
  coinAmount: Scalars['Int']['input'];
  /** Value of each coin bet on the Bonus. */
  coinValue: Scalars['Int']['input'];
  /** Value of data for the Bonus. */
  data: Scalars['String']['input'];
  /** Value of expired for the Bonus. */
  expiredAt: Scalars['NonNegativeInt']['input'];
  /** ID of the player placing the Bonus. */
  playerId: Scalars['String']['input'];
  /** ID of the Slot (Game) which the Bonus is being assigned for. */
  slotId: Scalars['String']['input'];
};

/** Result of placing a bet. */
export type AssignBonusResult = {
  /** Newly created bet with initial outcomes pre-rendered. */
  bonus: Bonus;
};

/** Auth result data. */
export type AuthData = {
  /** Client ID. */
  clientId: Scalars['String']['output'];
  /** Player ID. */
  playerId: Scalars['String']['output'];
  /** Player ID Token. Used across infrastructure (Configuration/Storage Service). */
  playerIdToken: Scalars['String']['output'];
  /** Session id. Use it in `authorization` header. */
  sessionId: Scalars['String']['output'];
};

/** Authenticate options. */
export type AuthInput = {
  /** Integration client id. */
  clientId: Scalars['ID']['input'];
  /** Connect Token. */
  connectToken: Scalars['String']['input'];
};

export type AutoplayCondition = {
  enabled: Scalars['Boolean']['output'];
};

export type AutoplayConditionWithMultipliers = {
  enabled: Scalars['Boolean']['output'];
  multipliers: Array<Scalars['UnsignedInt']['output']>;
};

export type AutoplayConditions = {
  stopIfBalanceDecreasesBy: AutoplayCondition;
  stopIfBalanceIncreasesBy: AutoplayConditionWithMultipliers;
  stopIfFeatureIsWon: AutoplayCondition;
  stopIfSingleWinExceeds: AutoplayConditionWithMultipliers;
  stopOnAnyWin: AutoplayCondition;
};

export type AutoplaySettings = {
  conditions: AutoplayConditions;
  options: Array<Scalars['UnsignedInt']['output']>;
};

export type Balance = {
  /** Amount of coins. */
  amount: Scalars['UnsignedInt']['output'];
  /** Currency code. */
  currency: Scalars['String']['output'];
};

/**
 * Represents a Bet object in the system.
 * A Bet is a single wager made by a player on a single slot.
 * A single Bet can produce multiple Outcomes.
 */
export type Bet = {
  /** Bet storage. */
  betStorage: Maybe<BetStorage>;
  /** Number of coins bet on the Bet. */
  coinAmount: Scalars['Int']['output'];
  /** Multiplier for coinAmount. In bet history reflects factual coin amounts for FreeSpins and BuyFeature bets. */
  coinAmountMultiplier: Scalars['Int']['output'];
  /** Value of each coin bet on the Bet. */
  coinValue: Scalars['Int']['output'];
  /** Timestamp of when the Bet was created. */
  createdAt: Scalars['DateTime']['output'];
  /**
   * Effective coin amount. Calculated as:
   * bet.coinAmount * bet.coinAmountMultiplier * game.getSlotSettings().globalCoinAmountMultiplier
   */
  effectiveCoinAmount: Scalars['Int']['output'];
  /** Unique identifier for the Bet. */
  id: Scalars['String']['output'];
  /** List of Outcomes associated with the Bet. */
  outcomes: Maybe<Array<Outcome>>;
  /** Player ID reference. */
  playerId: Scalars['String']['output'];
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['output'];
  /** Bet status. */
  status: BetStatus;
  /** Reference to the Wager associated with the Bet. */
  wager: Maybe<Wager>;
};

export type BetHistory = {
  /** List of existing Bets for each coinAmount/coinValue. */
  bets: Array<Bet>;
  count: Scalars['Int']['output'];
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

/** Input type for querying bet history. */
export type BetHistoryInput = {
  /** Cursor after which get bets. */
  after?: InputMaybe<Scalars['String']['input']>;
  /** Cursor before which get bets. */
  before?: InputMaybe<Scalars['String']['input']>;
  /** Number of items to take from the start. */
  limit: Scalars['NonNegativeInt']['input'];
  slotId: Scalars['UUID']['input'];
};

/** BetStatus enum represents possible states of the Bet. */
export type BetStatus =
  /** Open bet has outcomes that have not yet been selected and/or closed. */
  | 'OPEN'
  /** Closed bet has completed all outcomes. */
  | 'SETTLED';

export type BetStorage = CommonBetStorage;

export type BillFishRichesOutcomeSnapShot = {
  /** 3 row x 5 columns symbol field as flat array of symbol ID's. */
  fieldState: Array<Maybe<Scalars['SlotId']['output']>>;
  /**
   * True if rewarded Free Spins bonus.
   * Next wager will be of FREE_SPINS game mode.
   */
  hasRewardedFreeSpins: Scalars['Boolean']['output'];
  /** Winning combinations. */
  winCombinations: Array<Maybe<WinCombination>>;
};

export type BillFishRichesPredicament = {
  fishKind: Maybe<Scalars['FishKind']['output']>;
  fishingChallengePosition: Maybe<Scalars['Int']['output']>;
  fishingChallengeResult: Maybe<Scalars['Boolean']['output']>;
  fishingChallengeWheelCnt: Maybe<Scalars['Int']['output']>;
  fishingGamblePosition: Maybe<Scalars['Int']['output']>;
  fishingGambleResult: Maybe<Scalars['Boolean']['output']>;
  fishingScalingMultiplier: Maybe<Scalars['Int']['output']>;
  /** Tumbling reel positions. */
  reelPositions: Array<Maybe<Scalars['Int']['output']>>;
};

export type BillFishRichesWagerSettings = {
  /** Game Mode signifier. */
  gameMode: Scalars['GameMode']['output'];
  originalGameMode: Maybe<Scalars['GameMode']['output']>;
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<WagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type BillfishRichesPredicament = {
  predicamentId: Maybe<Scalars['String']['output']>;
  rewardPrototypes: Maybe<Array<Maybe<RewardPrototype>>>;
  wagerId: Maybe<Scalars['String']['output']>;
};

export type BillfishRichesSnapshot = {
  fieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Winning combinations. */
  winCombinations: Maybe<Array<Maybe<WinCombinationBillfishRiches>>>;
};

export type BillfishRichesWagerSettings = {
  /** Game mode in current time */
  activeGameMode: Maybe<Scalars['String']['output']>;
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** reel position before Gamble game */
  originalFieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** reel position before Gamble game */
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<BillfishRichesWagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  rewardPrototypes: Maybe<Array<Maybe<RewardPrototype>>>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type BillfishRichesWagerStorage = {
  /** History of select outcomes in Gamble */
  gambleOutcomeStorage: Maybe<Array<Maybe<Outcome>>>;
  /** Paid Win Coin Amount. Incremented when actual payouts occur to the player. */
  paidWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Total Win Coin Amount of previous Wager. */
  previousTotalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of rounds already played.
   * Incremented each time a new Bet is created using this Wager.
   * When number of Rounds Played reaches number of Rounds allowed to play,
   * the Wager will be transitioned into CLOSED state.
   */
  roundsPlayed: Maybe<Scalars['Int']['output']>;
  /** Reward per spin */
  spinReward: Maybe<Scalars['Int']['output']>;
  /** Alternative totalWinCoinAmount for wager type is DEFAULT */
  totalReward: Maybe<Scalars['Int']['output']>;
  /**
   * Total amount of WIN coins estimated to be paid for this Wager.
   * Incremented regardless of Wager type.
   *
   * If Wager type is DEFAULT, then this coin amount will be withhold until Wager transitions to CLOSED state. Then win coin amount will be paid to end player.
   * If Wager type if INCREMENTAL, then payouts occur as soon as there is any positive win coin amount on the Bet.
   */
  totalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Metadata pointing if fishing challenge was closed due to wheel spin */
  wheelSpinClose: Maybe<Scalars['Boolean']['output']>;
};

export type Bonus = {
  /** Number of coins bet on the Bonus. */
  coinAmount: Scalars['Int']['output'];
  /** Value of each coin bet on the Bonus. */
  coinValue: Scalars['Int']['output'];
  /** Timestamp of when the Bonus was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Unique identifier for the Bonus. */
  id: Scalars['String']['output'];
  /** Player ID reference. */
  playerId: Scalars['String']['output'];
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['output'];
};

export type BonusColumn = {
  fsCount: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  multiplier: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type BonusData = {
  column: Maybe<Scalars['Int']['output']>;
  fsCount: Maybe<Scalars['Int']['output']>;
  multiplier: Maybe<Scalars['Int']['output']>;
};

/** Input type for "buy feature". */
export type BuyFeatureInput = {
  /** Number of coins bet on the Bet. */
  coinAmount: Scalars['Int']['input'];
  /** Value of each coin bet on the Bet. */
  coinValue: Scalars['Int']['input'];
  /** ID of the purchasable package, or bonus. */
  packageId: Scalars['String']['input'];
  /** ID of the Slot (Game) for which the Bet is being placed. */
  slotId: Scalars['String']['input'];
};

export type BuyFeaturePackages = {
  /**
   * Coin amount multiplier.
   *
   * To calculate final win coin amount, use formula:
   *
   * Bet.CoinAmount * coinAmountMultiplier * GlobalCoinAmountMultiplier
   */
  coinAmountMultiplier: Scalars['NonNegativeInt']['output'];
  /** Package ID. Use for purchasing. */
  packageId: Scalars['String']['output'];
  /** Amount of rounds that will be set on purchased Wager. */
  rounds: Scalars['NonNegativeInt']['output'];
};

/** Result of purchasing feature. */
export type BuyFeatureResult = {
  /** Effective balance. */
  balance: PlayerBalance;
  /** Wager created as result of purchasing feature. */
  wager: Wager;
};

export type Client = {
  /** Adapter URL. */
  adapterUrl: Scalars['String']['output'];
  /** Client settings. */
  clientSettings: Maybe<Scalars['JSON']['output']>;
  /** Is client enabled. */
  enabled: Scalars['Boolean']['output'];
  /** Client ID. */
  id: Scalars['UUID']['output'];
  /** Client name. */
  name: Scalars['String']['output'];
  /** Reference to a Client as an "Operator" for this Client as "Casino". */
  operator: Maybe<Client>;
};

/** Client and Slot association. */
export type ClientOnSlot = {
  /** Client reference. */
  client: Client;
  /** Is this association enabled. */
  enabled: Scalars['Boolean']['output'];
  /** Allowed Slot Features for this association. */
  features: Array<ESlotFeature>;
  /** Allowed Slot Licenses for this association. */
  licenses: Array<ESlotLicense>;
  /** Settings for Slot in association with this Client. */
  settings: ClientSlotSettings;
  /** Slot reference. */
  slot: Slot;
};

/** Collection of settings applied to Slot with use in Client association. */
export type ClientSlotSettings = {
  /** Autoplay settings. */
  autoplaySettings: AutoplaySettings;
  /** Coin Amount settings: allowed coin amounts for placing a bet. */
  betCoinAmountSettings: Array<Scalars['UnsignedInt']['output']>;
  /** Coin Value settings. */
  coinValueSettings: Array<CoinValueWithCurrency>;
  /** [FE setting] Quick bet coin amount settings: for quick bet select, allowed coin amounts for placing a bet. */
  quickBetCoinAmountSettings: Array<Scalars['UnsignedInt']['output']>;
  /** Coin Amounts converted to their Fiat representation. Available when "Currency" filter is set. */
  realBetCoinAmounts: Array<Scalars['Float']['output']>;
  /**
   * Lifetime duration of a Wager expressed in seconds.
   *
   * Wager.expiredAt = Wager.createdAt + wagerExpirationDurationSeconds
   */
  wagerExpirationDurationSeconds: Scalars['Int']['output'];
};

/** Coin Value annotated with Currency code. */
export type CoinValueWithCurrency = {
  /** Currency code. */
  code: Scalars['String']['output'];
  /** Allowed variants of Coin Value for the Currency. */
  variants: Array<Scalars['UnsignedInt']['output']>;
};

export type CommonBetStorage = {
  /**
   * Estimated win coin amount of the bet.
   *
   * Bet.CoinAmount * Sum(CoinRewards)
   */
  estimatedWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Snapshot of historic data when bet was placed. */
  historySnapshot: Maybe<HistorySnapshot>;
};

export type CommonWagerSettings = {
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** Legacy mapping. Do not delete. */
  originalGameMode: Maybe<Scalars['String']['output']>;
  /** Legacy mapping. Do not delete. */
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<WagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type CommonWagerStorage = {
  /** Paid Win Coin Amount. Incremented when actual payouts occur to the player. */
  paidWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Total Win Coin Amount of previous Wager. */
  previousTotalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of rounds already played.
   * Incremented each time a new Bet is created using this Wager.
   * When number of Rounds Played reaches number of Rounds allowed to play,
   * the Wager will be transitioned into CLOSED state.
   */
  roundsPlayed: Maybe<Scalars['Int']['output']>;
  /**
   * Total amount of WIN coins estimated to be paid for this Wager.
   * Incremented regardless of Wager type.
   *
   * If Wager type is DEFAULT, then this coin amount will be withhold until Wager transitions to CLOSED state. Then win coin amount will be paid to end player.
   * If Wager type if INCREMENTAL, then payouts occur as soon as there is any positive win coin amount on the Bet.
   */
  totalWinCoinAmount: Maybe<Scalars['Int']['output']>;
};

export type DiceQuestPredicament = {
  hero: Maybe<Scalars['String']['output']>;
  reelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  wagerId: Maybe<Scalars['String']['output']>;
};

export type DiceQuestSnapshot = {
  allCellDone: Maybe<Scalars['Boolean']['output']>;
  bonusLayoutMultiply: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  cells: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  fakeWin: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  fieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  multiply: Maybe<Scalars['Int']['output']>;
  positions: Maybe<Scalars['Int']['output']>;
  sector: Maybe<Scalars['String']['output']>;
  /** Winning combinations. */
  typeCell: Maybe<Scalars['String']['output']>;
  win: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  winCombinations: Maybe<Array<Maybe<WinCombination>>>;
};

export type DiceQuestWagerSettings = {
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<DiceQuestWagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type DiceQuestWagerStorage = {
  bonusLayoutMultiply: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Paid Win Coin Amount. Incremented when actual payouts occur to the player. */
  paidWinCoinAmount: Maybe<Scalars['Int']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  /** Total Win Coin Amount of previous Wager. */
  previousTotalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of rounds already played.
   * Incremented each time a new Bet is created using this Wager.
   * When number of Rounds Played reaches number of Rounds allowed to play,
   * the Wager will be transitioned into CLOSED state.
   */
  roundsPlayed: Maybe<Scalars['Int']['output']>;
  /**
   * Total amount of WIN coins estimated to be paid for this Wager.
   * Incremented regardless of Wager type.
   *
   * If Wager type is DEFAULT, then this coin amount will be withhold until Wager transitions to CLOSED state. Then win coin amount will be paid to end player.
   * If Wager type if INCREMENTAL, then payouts occur as soon as there is any positive win coin amount on the Bet.
   */
  totalWinCoinAmount: Maybe<Scalars['Int']['output']>;
};

/** Slot features */
export type ESlotFeature = 'ASSIGN_BONUS' | 'BUY_FEATURE' | 'CASCADE' | 'DEMO_MODE' | 'FREE_SPINS';

/** Slot license */
export type ESlotLicense =
  /** Curacao license */
  | 'CURACAO'
  /** Maltese license */
  | 'MALTA'
  /**
   * Use this to mark Slots with no acquired licenses.
   * Cannot be combined with other licenses.
   */
  | 'NONE';

export type ESpecialWildSymbol = 'SW1' | 'SW2' | 'SW3';

export type GameSettings = {
  /** Collection of settings applied to Slot with use in Client association. */
  clientSlotSettings: ClientSlotSettings;
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['output'];
  /** Collection of settings applied to various types of Slot Games. */
  slotSettings: Maybe<TumblingReelSlotSettings>;
};

export type GameSettingsInput = {
  /** ID of the Client relevant to this operation. */
  clientId: Scalars['String']['input'];
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['input'];
};

/** Filter Slots. */
export type GetSlotsFilter = {
  /** [Optional] Client ID filter. */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** [Optional] Feature filter. */
  feature?: InputMaybe<ESlotFeature>;
  /** [Optional] License filter. */
  license?: InputMaybe<ESlotLicense>;
};

/** Snapshot of historic data under Bet. */
export type HistorySnapshot = {
  /** Outcomes as recorded at the time of placing Bet. */
  outcomes: Maybe<Array<Outcome>>;
  /** Primary Wager associated with Bet recorded at the time of placing Bet. */
  wager: Maybe<Wager>;
  /** All wagers associated with Bet as recorded at the time of placing Bet. */
  wagers: Maybe<Array<Wager>>;
};

export type HollywoodWondersPredicament = {
  wagerId: Maybe<Scalars['String']['output']>;
};

export type HollywoodWondersSnapshot = {
  fieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  originFieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  symbolsForReplace: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Winning combinations. */
  winCombinations: Maybe<Array<Maybe<WinCombinationKraken>>>;
};

export type HollywoodWondersWagerSettings = {
  bonusColumn: Maybe<Array<Maybe<BonusColumn>>>;
  bonusMultiplier: Maybe<Scalars['Int']['output']>;
  bonuses: Maybe<Array<Maybe<BonusData>>>;
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** reel position before Gamble game */
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<HollywoodWondersWagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  prize: Maybe<Scalars['Int']['output']>;
  reel: Maybe<Scalars['String']['output']>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

/** Check slot availability. */
export type IsSlotAvailableFilter = {
  /** Client ID. */
  clientId: Scalars['String']['input'];
  /** [Optional] Feature filter. */
  feature?: InputMaybe<ESlotFeature>;
  /** [Optional] License filter. */
  license?: InputMaybe<ESlotLicense>;
  /** Slot ID. */
  slotId: Scalars['String']['input'];
};

export type KrakenPredicament = {
  wagerId: Maybe<Scalars['String']['output']>;
};

export type KrakenSnapshot = {
  fieldState: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  fsFieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  originFieldState: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Winning combinations. */
  winCombinations: Maybe<Array<Maybe<WinCombinationKraken>>>;
};

export type KrakenWagerSettings = {
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** reel position before Gamble game */
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<KrakenWagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type KrakenWagerStorage = {
  hpProgress: Maybe<Scalars['Int']['output']>;
  /** Paid Win Coin Amount. Incremented when actual payouts occur to the player. */
  paidWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Total Win Coin Amount of previous Wager. */
  previousTotalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of rounds already played.
   * Incremented each time a new Bet is created using this Wager.
   * When number of Rounds Played reaches number of Rounds allowed to play,
   * the Wager will be transitioned into CLOSED state.
   */
  roundsPlayed: Maybe<Scalars['Int']['output']>;
  /**
   * Total amount of WIN coins estimated to be paid for this Wager.
   * Incremented regardless of Wager type.
   *
   * If Wager type is DEFAULT, then this coin amount will be withhold until Wager transitions to CLOSED state. Then win coin amount will be paid to end player.
   * If Wager type if INCREMENTAL, then payouts occur as soon as there is any positive win coin amount on the Bet.
   */
  totalWinCoinAmount: Maybe<Scalars['Int']['output']>;
};

export type MavianWreathGameMode = 'BASE_GAME' | 'BUY_FEATURE' | 'FREE_SPINS';

export type MavianWreathPredicament = {
  /** Special Feature Package or undefined. */
  specialFeaturePackage: Maybe<MavianWreathSpecialFeaturePackage>;
  /**
   * Starting from Ring A, all symbols on field as flat array.
   *
   * [RINGS]
   * A = 18
   * B = 12
   * C = 6
   * D = 1
   *
   * Total 37 symbols.
   */
  symbols: Array<Maybe<Scalars['String']['output']>>;
  /** Win Multiplier >= 1. */
  winMultiplier: Scalars['Int']['output'];
};

export type MavianWreathSnapshot = {
  /** Amount of rewarded Free Spins >= 0. */
  amountOfRewardedFreeSpins: Scalars['Int']['output'];
  /** Winning combinations. */
  winCombinations: Array<Maybe<WinCombination>>;
};

export type MavianWreathSpecialFeaturePackage = {
  /** Positions where Additional Wilds fall (if any). */
  additionalWildsPositions: Array<Maybe<Scalars['Int']['output']>>;
  /** Additional Wilds Symbol. SW or WL. */
  additionalWildsSymbol: Scalars['String']['output'];
  /** Amount of Additional Wilds. Can be 0. */
  amountOfRandomWilds: Scalars['Int']['output'];
  /** Kind of special wild symbol. */
  kind: ESpecialWildSymbol;
  /** Win Multiplier >= 1. */
  multiplier: Scalars['Int']['output'];
};

export type MavianWreathWagerSettings = {
  /** Game Mode signifier. */
  gameMode: MavianWreathGameMode;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<WagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

/** Available mutations. */
export type Mutation = {
  /** Create a new Bonus. */
  assignBonus: AssignBonusResult;
  /** Authenticate user by provided clientId and token. Returns session id. */
  auth: Maybe<AuthData>;
  /** Purchasable bonus. */
  buyFeature: BuyFeatureResult;
  /** Clear cache. */
  cacheClear: Scalars['Int']['output'];
  /** Create a new bet. */
  placeBet: PlaceBetResult;
  /** Select outcomes in branching unselected state. */
  selectOutcomes: SelectOutcomes;
  version: VersionData;
};

/** Available mutations. */
export type MutationAssignBonusArgs = {
  input: AssignBonusInput;
};

/** Available mutations. */
export type MutationAuthArgs = {
  input: AuthInput;
};

/** Available mutations. */
export type MutationBuyFeatureArgs = {
  input: BuyFeatureInput;
};

/** Available mutations. */
export type MutationPlaceBetArgs = {
  input: PlaceBetInput;
};

/** Available mutations. */
export type MutationSelectOutcomesArgs = {
  input: SelectOutcomesInput;
};

export type NaughtyJokerPredicament = {
  caseFn: Maybe<Scalars['String']['output']>;
  rewardPrototypes: Maybe<Array<Maybe<RewardPrototype>>>;
  wagerId: Maybe<Scalars['String']['output']>;
};

export type NaughtyJokerSnapshot = {
  fieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Winning combinations. */
  winCombinations: Maybe<Array<Maybe<WinCombinationNaughtyJoker>>>;
};

export type NaughtyJokerWagerSettings = {
  /** Game mode in current time */
  activeGameMode: Maybe<Scalars['String']['output']>;
  /** Game Mode signifier. */
  gameMode: Scalars['String']['output'];
  /** reel position before Gamble game */
  originalFieldState: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** reel position before Gamble game */
  originalReelPositions: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** PackageID of purchasable BuyFeature package (if any). */
  packageId: Maybe<Scalars['String']['output']>;
  /** Automatically recorded Wager Settings of previous Wager. */
  previousWagerSettings: Maybe<NaughtyJokerWagerSettings>;
  /** Automatically recorded Wager Storage of previous Wager. */
  previousWagerStorage: Maybe<WagerStorage>;
  rewardPrototypes: Maybe<Array<Maybe<RewardPrototype>>>;
  /**
   * Total amount of Rounds allowed to play in this Wager,
   * initially assigned to a Wager and potentially extended by Game Implementation.
   */
  rounds: Maybe<Scalars['Int']['output']>;
};

export type NaughtyJokerWagerStorage = {
  /** History of select outcomes in Gamble */
  gambleOutcomeStorage: Maybe<Array<Maybe<Outcome>>>;
  /** Paid Win Coin Amount. Incremented when actual payouts occur to the player. */
  paidWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /** Total Win Coin Amount of previous Wager. */
  previousTotalWinCoinAmount: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of rounds already played.
   * Incremented each time a new Bet is created using this Wager.
   * When number of Rounds Played reaches number of Rounds allowed to play,
   * the Wager will be transitioned into CLOSED state.
   */
  roundsPlayed: Maybe<Scalars['Int']['output']>;
  /** Reward per spin */
  spinReward: Maybe<Scalars['Int']['output']>;
  /** Alternative totalWinCoinAmount for wager type is DEFAULT */
  totalReward: Maybe<Scalars['Int']['output']>;
  /**
   * Total amount of WIN coins estimated to be paid for this Wager.
   * Incremented regardless of Wager type.
   *
   * If Wager type is DEFAULT, then this coin amount will be withhold until Wager transitions to CLOSED state. Then win coin amount will be paid to end player.
   * If Wager type if INCREMENTAL, then payouts occur as soon as there is any positive win coin amount on the Bet.
   */
  totalWinCoinAmount: Maybe<Scalars['Int']['output']>;
};

/** Response when querying next outcomes within Bet. */
export type NextOutcomes = {
  /** Bet data, contains next Outcomes and Bet state. */
  bet: Bet;
};

/** Query next outcomes within Bet. */
export type NextOutcomesInput = {
  /** Bet Identifier. */
  betId: Scalars['String']['input'];
  /** Specify optional limit how much outcomes to render. */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Represents an Outcome object in the system.
 * An Outcome is a single result of a Bet, and contains
 * information about the order of the outcome within the Wager,
 * the associated Wager, a snapshot of the game state at the time
 * the Bet was made, and any Rewards associated with the Outcome.
 */
export type Outcome = {
  /** Reference to the Bet associated with the Outcome. */
  bet: Maybe<Bet>;
  /** Timestamp of when the Outcome was completed. */
  completedAt: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of when the Outcome was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Unique identifier for the Outcome. */
  id: Scalars['String']['output'];
  /** Next outcome in Outcome Tree. */
  nextOutcome: Maybe<Outcome>;
  /** Outcome predicament (reel positions, cascade symbols, complex field modifications, or selectable outcome rewards) */
  predicament: Maybe<BillFishRichesPredicament>;
  /** Previous outcome in Outcome Tree. */
  previousOutcome: Maybe<Outcome>;
  /**
   * Self-reference on an Outcome Tree
   * to create a chain of outcomes from a single bet
   */
  previousOutcomeId: Maybe<Scalars['String']['output']>;
  /** List of Rewards associated with the Outcome. */
  rewards: Maybe<Array<Reward>>;
  /** Selection state */
  selectionState: OutcomeSelectionState;
  /** Snapshot derived from outcome predicament (reel state, cascade field, complex field states) */
  stateSnapshot: Maybe<BillFishRichesOutcomeSnapShot>;
};

export type OutcomePredicament =
  | AfricanAnimalsPredicament
  | BillFishRichesPredicament
  | BillfishRichesPredicament
  | DiceQuestPredicament
  | HollywoodWondersPredicament
  | KrakenPredicament
  | MavianWreathPredicament
  | NaughtyJokerPredicament;

/** Outcome selection state. Relevant to branching when bidding on an outcome. */
export type OutcomeSelectionState =
  /** Default state. */
  | 'SELECTED'
  /** Needs selection. */
  | 'UNSELECTED';

export type OutcomeSnapshot =
  | AfricanAnimalsOutcomeSnapshot
  | BillFishRichesOutcomeSnapShot
  | BillfishRichesSnapshot
  | DiceQuestSnapshot
  | HollywoodWondersSnapshot
  | KrakenSnapshot
  | MavianWreathSnapshot
  | NaughtyJokerSnapshot;

/** Input type for creating a new bet. */
export type PlaceBetInput = {
  /** Number of coins bet on the Bet. */
  coinAmount: Scalars['Int']['input'];
  /** Value of each coin bet on the Bet. */
  coinValue: Scalars['Int']['input'];
  /** ID of the Slot (Game) for which the Bet is being placed. */
  slotId: Scalars['String']['input'];
};

/** Result of placing a bet. */
export type PlaceBetResult = {
  /** Effective balance. */
  balance: PlayerBalance;
  /** Newly created bet with initial outcomes pre-rendered. */
  bet: Bet;
  /** Win coin amount. */
  winCoinAmount: Maybe<Scalars['NonNegativeInt']['output']>;
};

export type PlayerBalance = {
  /** Balance after placing the bet. */
  placed: Balance;
  /** Balance after the bet is settled. */
  settled: Balance;
};

/** Available queries. */
export type Query = {
  autoSpinsAmount: Scalars['Int']['output'];
  autoSpinsLeft: Scalars['Int']['output'];
  autoSpinsStartBalance: Scalars['Int']['output'];
  balance: Balance;
  betAmount: Scalars['Int']['output'];
  betHistory: BetHistory;
  /** Get settings for game. */
  gameSettings: GameSettings;
  isAutoSpins: Scalars['Boolean']['output'];
  isOpenAutoplayPopup: Scalars['Boolean']['output'];
  isOpenBetSettingsPopup: Scalars['Boolean']['output'];
  isOpenHistoryPopup: Scalars['Boolean']['output'];
  isOpenInfoPopup: Scalars['Boolean']['output'];
  isSlotBusy: Scalars['Boolean']['output'];
  isSlotStopped: Scalars['Boolean']['output'];
  isSoundOn: Scalars['Boolean']['output'];
  isSpinInProgress: Scalars['Boolean']['output'];
  isStopOnAnyWin: Scalars['Boolean']['output'];
  isStopOnBalanceDecrease: Scalars['Boolean']['output'];
  isStopOnBalanceIncrease: Scalars['Boolean']['output'];
  isStopOnFeatureWin: Scalars['Boolean']['output'];
  isStopOnWinExceeds: Scalars['Boolean']['output'];
  isTurboSpin: Scalars['Boolean']['output'];
  /** Fetch next outcomes within Bet. */
  nextOutcomes: NextOutcomes;
  /**
   * Determine last known state of the game.
   * Returns list of existing Bets for each coinAmount/coinValue.
   */
  restoreState: RestoredState;
  stopOnBalanceDecrease: Scalars['Int']['output'];
  stopOnBalanceIncrease: Scalars['Int']['output'];
  stopOnWinExceeds: Scalars['Int']['output'];
  version: VersionData;
  winAmount: Scalars['Int']['output'];
};

/** Available queries. */
export type QueryBetHistoryArgs = {
  input: BetHistoryInput;
};

/** Available queries. */
export type QueryGameSettingsArgs = {
  input: GameSettingsInput;
};

/** Available queries. */
export type QueryNextOutcomesArgs = {
  input: NextOutcomesInput;
};

/** Available queries. */
export type QueryRestoreStateArgs = {
  input: RestoreStateInput;
};

export type RangeMinMaxNonNegativeInt = {
  max: Scalars['NonNegativeInt']['output'];
  min: Scalars['NonNegativeInt']['output'];
};

/** Input type for querying last known state of the game. */
export type RestoreStateInput = {
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['input'];
};

/** Response when querying last known state of the game. */
export type RestoredState = {
  /** List of existing Bets for each coinAmount/coinValue. */
  bets: Array<Bet>;
  /** List of existing Wagers for each coinAmount/coinValue. */
  wagers: Array<Wager>;
};

/**
 * Represents a Reward object in the system.
 * A Reward is a prize that can be earned as part of an Outcome.
 */
export type Reward = {
  /** Internal identifier of rewarded entity (e.g. bonus id), relevant to the rules of the game. */
  entityId: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the Reward. */
  id: Scalars['String']['output'];
  /** Multiplier value. */
  multiplier: Maybe<Scalars['Float']['output']>;
  /** Reference to the Outcome the reward belongs to. */
  outcome: Maybe<Outcome>;
  /** Reward type. */
  type: RewardType;
  /** Coin value, or amount of bonuses. */
  value: Scalars['Int']['output'];
};

/** Reward without ID and mapping to OutcomeId. */
export type RewardPrototype = {
  /** Internal identifier of rewarded entity (e.g. bonus id), relevant to the rules of the game. */
  entityId: Maybe<Scalars['String']['output']>;
  /** Multiplier value. */
  multiplier: Maybe<Scalars['Float']['output']>;
  /** Reward type. */
  type: RewardType;
  /** Coin value, or amount of bonuses. */
  value: Scalars['Int']['output'];
};

/** RewardType enum represents different types of rewards. */
export type RewardType =
  /**
   * Bonus. `value` of a Reward signifies amount of bonus rounds.
   * `entityId` signifies internal identifier of rewarded entity (e.g. bonus).
   */
  | 'BONUS'
  /**
   * Coins reward. `value` of a Reward signifies amount of coins.
   * `multiplier` signifies multiplier value.
   */
  | 'COINS';

/** Role Type. */
export type RoleEnum = 'PLAYER' | 'SERVICE' | 'UNKNOWN';

/** Result of selecting outcomes. */
export type SelectOutcomes = {
  /** Effective balance. */
  balance: PlayerBalance;
  /** Bet data, reflects selected Outcomes and Bet state. */
  bet: Bet;
  /** Win coin amount. */
  winCoinAmount: Maybe<Scalars['NonNegativeInt']['output']>;
};

/** Input type for selecting outcomes. */
export type SelectOutcomesInput = {
  /** Bet Identifier. */
  betId: Scalars['String']['input'];
  /** Coin Amount. */
  coinAmount: Scalars['Int']['input'];
  /** Coin Value. */
  coinValue: Scalars['Int']['input'];
  /** List of outcomes to select. */
  outcomeIds: Array<Scalars['String']['input']>;
  /** Slot Identifier. */
  slotId: Scalars['String']['input'];
};

/** Slot. */
export type Slot = {
  /** All Supported Slot features */
  features: Array<ESlotFeature>;
  /** Slot id */
  id: Scalars['UUID']['output'];
  /** All Acquired Slot licenses */
  licenses: Array<ESlotLicense>;
  /** Slot name */
  name: Scalars['String']['output'];
  /** URL slug of this Slot. */
  slug: Scalars['String']['output'];
};

export type TumblingReelSlotSettings = {
  /** Bet Lines (min/max). */
  betLines: RangeMinMaxNonNegativeInt;
  /** Purchasable BuyFeature packages. */
  buyFeaturePackages: Array<BuyFeaturePackages>;
  /** Amount of Columns of tumbling reels. */
  cols: Scalars['NonNegativeInt']['output'];
  /** Global Coin Amount Multiplier (usually equals to amount of lines). */
  globalCoinAmountMultiplier: Scalars['NonNegativeInt']['output'];
  /** Amount of Rows of tumbling reels. */
  rows: Scalars['NonNegativeInt']['output'];
  /** Array of starting reel positions for when player initially opens the game for first time. */
  startingPositions: Array<Scalars['NonNegativeInt']['output']>;
};

/** Version data. */
export type VersionData = {
  time: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/**
 * Represents a Wager object in the system.
 * A Wager is a set of Bets made by a single player on a single slot.
 * The Wager can be in one of two states: OPEN or CLOSED.
 */
export type Wager = {
  /** List of bets related to the wager. */
  bet: Maybe<Array<Bet>>;
  /** Coin Amount. */
  coinAmount: Maybe<Scalars['Int']['output']>;
  /** Coin Value. */
  coinValue: Maybe<Scalars['Int']['output']>;
  /** Timestamp. */
  createdAt: Scalars['DateTime']['output'];
  /** Unique identifier for the Wager. */
  id: Scalars['String']['output'];
  /** Explicit order of the Wager, when creating multiple bonus rounds where order matters. */
  order: Maybe<Scalars['Int']['output']>;
  /** Player ID reference. */
  playerId: Scalars['String']['output'];
  /** ID of the Slot (Game). */
  slotId: Scalars['String']['output'];
  /** Wager state. */
  state: WagerState;
  /** Wager type. */
  type: WagerType;
  /** Metadata of wager relevant to rules of the game. */
  wagerSettings: WagerSettings;
  /**
   * Cumulative update of the wager.
   * Maintain state between bets.
   */
  wagerStorage: Maybe<WagerStorage>;
};

export type WagerSettings =
  | AfricanAnimalsWagerSettings
  | BillFishRichesWagerSettings
  | BillfishRichesWagerSettings
  | CommonWagerSettings
  | DiceQuestWagerSettings
  | HollywoodWondersWagerSettings
  | KrakenWagerSettings
  | MavianWreathWagerSettings
  | NaughtyJokerWagerSettings;

/** WagerState enum represents the possible states of a Wager. */
export type WagerState =
  /** Closed wager has completed all bets. */
  | 'CLOSED'
  /** Open wager has unresolved bets. */
  | 'OPEN';

export type WagerStorage =
  | BillfishRichesWagerStorage
  | CommonWagerStorage
  | DiceQuestWagerStorage
  | KrakenWagerStorage
  | NaughtyJokerWagerStorage;

/** WagerType enum represents the possible types of a Wager. */
export type WagerType =
  /** Default wager. When closed, rewards will be processed to transactions. */
  | 'DEFAULT'
  /** Incremental payout. Payout occurs when any BET associated with WAGER is closed. */
  | 'INCREMENTAL';

/** Win combination of Symbol Field with Attribution. */
export type WinCombination = {
  /** Attribution maps this winning combination to a specific set of positions on a Symbol Field. */
  attribution: Array<Maybe<Scalars['Int']['output']>>;
  /**
   * Line number from which this win combination originates.
   * (Optional: Only applies to Line Combinations.)
   */
  lineNumber: Maybe<Scalars['Int']['output']>;
  /** Rewards. */
  rewards: Array<Maybe<RewardPrototype>>;
};

export type WinCombinationBillfishRiches = {
  /** Attribution maps this winning combination to a specific set of positions on a Symbol Field. */
  attribution: Array<Maybe<Scalars['Int']['output']>>;
  /**
   * Line number from which this win combination originates.
   * (Optional: Only applies to Line Combinations.)
   */
  lineNumber: Maybe<Scalars['Int']['output']>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Rewards. */
  rewards: Maybe<Array<Maybe<RewardPrototype>>>;
};

export type WinCombinationKraken = {
  /** Attribution maps this winning combination to a specific set of positions on a Symbol Field. */
  attribution: Array<Maybe<Scalars['Int']['output']>>;
  /**
   * Line number from which this win combination originates.
   * (Optional: Only applies to Line Combinations.)
   */
  lineNumber: Maybe<Scalars['Int']['output']>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Rewards. */
  rewards: Maybe<Array<Maybe<RewardPrototype>>>;
};

export type WinCombinationNaughtyJoker = {
  /** Attribution maps this winning combination to a specific set of positions on a Symbol Field. */
  attribution: Array<Maybe<Scalars['Int']['output']>>;
  /**
   * Line number from which this win combination originates.
   * (Optional: Only applies to Line Combinations.)
   */
  lineNumber: Maybe<Scalars['Int']['output']>;
  reelPosition: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Rewards. */
  rewards: Maybe<Array<Maybe<RewardPrototype>>>;
};

export type OutcomeFragment = {
  id: string;
  selectionState: OutcomeSelectionState;
  previousOutcomeId: string | null;
  createdAt: string;
  completedAt: string | null;
  previousOutcome: { id: string } | null;
  nextOutcome: { id: string } | null;
  predicament: {
    __typename: 'BillFishRichesPredicament';
    reelPositions: Array<number | null>;
    fishKind: FishKind | null;
    fishingChallengeResult: boolean | null;
    fishingChallengePosition: number | null;
    fishingChallengeWheelCnt: number | null;
    fishingGambleResult: boolean | null;
    fishingGamblePosition: number | null;
    fishingScalingMultiplier: number | null;
  } | null;
  stateSnapshot: {
    fieldState: Array<SlotId | null>;
    hasRewardedFreeSpins: boolean;
    winCombinations: Array<{
      attribution: Array<number | null>;
      lineNumber: number | null;
      rewards: Array<{ type: RewardType; value: number; multiplier: number | null; entityId: string | null } | null>;
    } | null>;
  } | null;
  rewards: Array<{
    id: string;
    type: RewardType;
    entityId: string | null;
    value: number;
    multiplier: number | null;
  }> | null;
} & { ' $fragmentName'?: 'OutcomeFragment' };

export type WagerFragment = {
  id: string;
  coinAmount: number | null;
  coinValue: number | null;
  state: WagerState;
  order: number | null;
  createdAt: string;
  wagerSettings:
    | { __typename: 'AfricanAnimalsWagerSettings' }
    | {
        __typename: 'BillFishRichesWagerSettings';
        gameMode: GameMode;
        rounds: number | null;
        packageId: string | null;
        originalReelPositions: Array<number | null> | null;
        originalGameMode: GameMode | null;
      }
    | { __typename: 'BillfishRichesWagerSettings' }
    | { __typename: 'CommonWagerSettings' }
    | { __typename: 'DiceQuestWagerSettings' }
    | { __typename: 'HollywoodWondersWagerSettings' }
    | { __typename: 'KrakenWagerSettings' }
    | { __typename: 'MavianWreathWagerSettings' }
    | { __typename: 'NaughtyJokerWagerSettings' };
  wagerStorage:
    | { __typename: 'BillfishRichesWagerStorage' }
    | {
        __typename: 'CommonWagerStorage';
        roundsPlayed: number | null;
        totalWinCoinAmount: number | null;
        previousTotalWinCoinAmount: number | null;
        paidWinCoinAmount: number | null;
      }
    | { __typename: 'DiceQuestWagerStorage' }
    | { __typename: 'KrakenWagerStorage' }
    | { __typename: 'NaughtyJokerWagerStorage' }
    | null;
} & { ' $fragmentName'?: 'WagerFragment' };

export type BetFragment = {
  id: string;
  slotId: string;
  playerId: string;
  status: BetStatus;
  coinAmount: number;
  coinAmountMultiplier: number;
  coinValue: number;
  effectiveCoinAmount: number;
  createdAt: string;
  wager: { ' $fragmentRefs'?: { WagerFragment: WagerFragment } } | null;
  betStorage: { estimatedWinCoinAmount: number | null } | null;
  outcomes: Array<{ ' $fragmentRefs'?: { OutcomeFragment: OutcomeFragment } }> | null;
} & { ' $fragmentName'?: 'BetFragment' };

export type AuthMutationVariables = Exact<{
  input: AuthInput;
}>;

export type AuthMutation = { auth: { sessionId: string } | null };

export type PlaceBetMutationVariables = Exact<{
  input: PlaceBetInput;
}>;

export type PlaceBetMutation = {
  placeBet: {
    winCoinAmount: number | null;
    bet: { ' $fragmentRefs'?: { BetFragment: BetFragment } };
    balance: { settled: { currency: string; amount: number }; placed: { currency: string; amount: number } };
  };
};

export type BuyFeatureMutationVariables = Exact<{
  input: BuyFeatureInput;
}>;

export type BuyFeatureMutation = {
  buyFeature: {
    wager: { ' $fragmentRefs'?: { WagerFragment: WagerFragment } };
    balance: { settled: { currency: string; amount: number }; placed: { currency: string; amount: number } };
  };
};

export type SelectOutcomesMutationVariables = Exact<{
  input: SelectOutcomesInput;
}>;

export type SelectOutcomesMutation = {
  selectOutcomes: {
    winCoinAmount: number | null;
    bet: { ' $fragmentRefs'?: { BetFragment: BetFragment } };
    balance: { settled: { currency: string; amount: number }; placed: { currency: string; amount: number } };
  };
};

export type GetBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type GetBalanceQuery = { balance: { currency: string; amount: number } };

export type GetRestoreStateQueryVariables = Exact<{
  input: RestoreStateInput;
}>;

export type GetRestoreStateQuery = {
  restoreState: {
    bets: Array<{ ' $fragmentRefs'?: { BetFragment: BetFragment } }>;
    wagers: Array<{ ' $fragmentRefs'?: { WagerFragment: WagerFragment } }>;
  };
};

export type SlotHistoryQueryVariables = Exact<{
  input: BetHistoryInput;
}>;

export type SlotHistoryQuery = {
  betHistory: {
    count: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    startCursor: string;
    endCursor: string;
    bets: Array<{ ' $fragmentRefs'?: { BetFragment: BetFragment } }>;
  };
};

export type GetNextOutcomesQueryVariables = Exact<{
  input: NextOutcomesInput;
}>;

export type GetNextOutcomesQuery = { nextOutcomes: { bet: { ' $fragmentRefs'?: { BetFragment: BetFragment } } } };

export type GameSettingsQueryVariables = Exact<{
  input: GameSettingsInput;
}>;

export type GameSettingsQuery = {
  gameSettings: {
    slotId: string;
    clientSlotSettings: {
      betCoinAmountSettings: Array<number>;
      quickBetCoinAmountSettings: Array<number>;
      wagerExpirationDurationSeconds: number;
      realBetCoinAmounts: Array<number>;
      coinValueSettings: Array<{ code: string; variants: Array<number> }>;
      autoplaySettings: {
        options: Array<number>;
        conditions: {
          stopOnAnyWin: { enabled: boolean };
          stopIfFeatureIsWon: { enabled: boolean };
          stopIfSingleWinExceeds: { enabled: boolean; multipliers: Array<number> };
          stopIfBalanceDecreasesBy: { enabled: boolean };
          stopIfBalanceIncreasesBy: { enabled: boolean; multipliers: Array<number> };
        };
      };
    };
    slotSettings: {
      cols: number;
      rows: number;
      startingPositions: Array<number>;
      globalCoinAmountMultiplier: number;
      betLines: { min: number; max: number };
      buyFeaturePackages: Array<{ packageId: string; coinAmountMultiplier: number; rounds: number }>;
    } | null;
  };
};

export type ConfigQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigQuery = {
  isTurboSpin: boolean;
  isSoundOn: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenBetSettingsPopup: boolean;
  isOpenAutoplayPopup: boolean;
  isOpenInfoPopup: boolean;
  isOpenHistoryPopup: boolean;
};

export type GetBetQueryVariables = Exact<{ [key: string]: never }>;

export type GetBetQuery = { betAmount: number };

export type GetWinQueryVariables = Exact<{ [key: string]: never }>;

export type GetWinQuery = { winAmount: number };

export type GetAutoSpinsSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutoSpinsSettingsQuery = {
  autoSpinsAmount: number;
  stopOnWinExceeds: number;
  stopOnBalanceIncrease: number;
  stopOnBalanceDecrease: number;
  isStopOnAnyWin: boolean;
  isStopOnFeatureWin: boolean;
  isStopOnWinExceeds: boolean;
  isStopOnBalanceIncrease: boolean;
  isStopOnBalanceDecrease: boolean;
  autoSpinsStartBalance: number;
};

export type GetAutoSpinQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutoSpinQuery = { isAutoSpins: boolean; autoSpinsLeft: number };

export type IsStoppedQueryVariables = Exact<{ [key: string]: never }>;

export type IsStoppedQuery = { isSlotStopped: boolean };

export const WagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WagerFragment, unknown>;
export const OutcomeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutcomeFragment, unknown>;
export const BetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BetFragment, unknown>;
export const AuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Auth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sessionId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthMutation, AuthMutationVariables>;
export const PlaceBetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PlaceBet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaceBetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'placeBet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'bet' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlaceBetMutation, PlaceBetMutationVariables>;
export const BuyFeatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buyFeature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyFeatureInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wager' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyFeatureMutation, BuyFeatureMutationVariables>;
export const SelectOutcomesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectOutcomes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectOutcomesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOutcomes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'bet' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectOutcomesMutation, SelectOutcomesMutationVariables>;
export const GetBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBalance' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'balance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBalanceQuery, GetBalanceQueryVariables>;
export const GetRestoreStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRestoreState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RestoreStateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'bet' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wagers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRestoreStateQuery, GetRestoreStateQueryVariables>;
export const SlotHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'slotHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BetHistoryInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'bet' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SlotHistoryQuery, SlotHistoryQueryVariables>;
export const GetNextOutcomesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNextOutcomes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NextOutcomesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcomes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'bet' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesWagerSettings' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalReelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalGameMode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wagerStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonWagerStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previousTotalWinCoinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paidWinCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'outcome' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Outcome' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectionState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousOutcomeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predicament' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesPredicament' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishKind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingChallengeWheelCnt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGambleResult' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingGamblePosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fishingScalingMultiplier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillFishRichesOutcomeSnapShot' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldState' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'winCombinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'attribution' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lineNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRewardedFreeSpins' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'wager' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betStorage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CommonBetStorage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'estimatedWinCoinAmount' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outcomes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'outcome' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveCoinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNextOutcomesQuery, GetNextOutcomesQueryVariables>;
export const GameSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'gameSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GameSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gameSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSlotSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'betCoinAmountSettings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quickBetCoinAmountSettings' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coinValueSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variants' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoplaySettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conditions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopOnAnyWin' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfFeatureIsWon' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfSingleWinExceeds' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multipliers' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfBalanceDecreasesBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfBalanceIncreasesBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multipliers' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'wagerExpirationDurationSeconds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'realBetCoinAmounts' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slotSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TumblingReelSlotSettings' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cols' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startingPositions' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'betLines' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'globalCoinAmountMultiplier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'buyFeaturePackages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'packageId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameSettingsQuery, GameSettingsQueryVariables>;
export const ConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Config' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isTurboSpin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSoundOn' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSpinInProgress' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotBusy' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotStopped' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenBetSettingsPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenAutoplayPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenInfoPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenHistoryPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfigQuery, ConfigQueryVariables>;
export const GetBetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBet' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBetQuery, GetBetQueryVariables>;
export const GetWinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'winAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWinQuery, GetWinQueryVariables>;
export const GetAutoSpinsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAutoSpinsSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnWinExceeds' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnBalanceIncrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnBalanceDecrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnAnyWin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnFeatureWin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnWinExceeds' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnBalanceIncrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnBalanceDecrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsStartBalance' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAutoSpinsSettingsQuery, GetAutoSpinsSettingsQueryVariables>;
export const GetAutoSpinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAutoSpin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAutoSpins' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsLeft' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAutoSpinQuery, GetAutoSpinQueryVariables>;
export const IsStoppedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsStopped' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotStopped' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsStoppedQuery, IsStoppedQueryVariables>;
