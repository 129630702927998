import * as PIXI from 'pixi.js';

import { MessageFreeSpinEndBannerProps } from '../../global';
import { setCurrentBonus, setResumeDuringFreeSpin } from '../../gql/cache';
import i18n from '../../i18next';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_FS_END_BANNER_RESPIN_Y,
  MESSAGE_FS_END_BANNER_TITLE_Y,
  MESSAGE_FS_END_BANNER_WIN_AMOUNT_Y,
} from './config';
import { freeSpinEndBannerRoundTextStyle, totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageFreeSpinEndBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinEndBannerProps;

  private banner: TickerSpine<'title_logo'>;
  private bannerFront!: TickerSpine<'title_logo'>;

  private totalWin?: PIXI.Text;

  constructor(props: MessageFreeSpinEndBannerProps) {
    super(props);
    this.props = props;

    this.banner = this.initBanner();
    this.banner.visible = false;
    this.addChild(this.banner);
  }

  public override init(): MessageFreeSpinEndBanner {
    super.init();

    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.banner.scale.set(1.0);
    this.banner.visible = true;
    const title = this.initTitle();
    const roundTxt = this.initRound();

    this.bannerFront = this.initBannerFront();

    this.addChild(title, this.totalWin, roundTxt, this.bannerFront);

    if (setResumeDuringFreeSpin()) {
      roundTxt.visible = false;
      title.y = title.y + 50;
      this.totalWin.y = this.totalWin.y + 100;
    }
    setResumeDuringFreeSpin(false);

    return this;
  }

  protected initBanner(): TickerSpine {
    const banner = new TickerSpine('title_logo');
    banner.update(0);
    banner.skeleton.setSkinByName(`default`);
    banner.state.setAnimation(0, 'base', true);
    return banner;
  }

  protected initBannerFront(): TickerSpine {
    const banner = new TickerSpine('title_logo');
    banner.update(0);
    banner.skeleton.setSkinByName(`default`);
    banner.state.setAnimation(0, 'base', true);
    return banner;
  }
  protected initTitle(): PIXI.Sprite {
    const winTitle = new AutoResizeText(i18n.t('freeSpinsTotalWin.title'), totalWinTitleStyles);
    winTitle.anchor.set(0.5);
    winTitle.y = MESSAGE_FS_END_BANNER_TITLE_Y;
    return winTitle;
  }

  protected initWinTotal(totalWin: string): AutoResizeText {
    const winTotal = new AutoResizeText(totalWin, totalWinAmountTextStyles);
    winTotal.updateText(true);
    winTotal.anchor.set(0.5);
    winTotal.y = MESSAGE_FS_END_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  protected initRound(): PIXI.Sprite {
    const roundTxt = new AutoResizeText(
      i18n.t('freeSpinsTotalWin.round', { round: setCurrentBonus().roundsPlayed }),
      freeSpinEndBannerRoundTextStyle,
    );
    roundTxt.anchor.set(0.5);
    roundTxt.x = 0;
    roundTxt.y = MESSAGE_FS_END_BANNER_RESPIN_Y;
    return roundTxt;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    } else {
      this.x = SLOTS_CONTAINER_WIDTH / 2;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
    }
  }
}
