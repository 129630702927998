import { Combos } from '../slotMachine/d';

export enum SlotId {
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

//TO DO
export enum PayTableSlotId {
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  Any7 = 'H',
}

export const config = {
  failureRetries: 5,
  autoplay: {
    timeOut: 1000,
    defaultAutoSpinsAmount: 100,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    {
      slug: SlotId.SC1,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.SC2,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.SC3,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.SC4,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.A,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.B,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.C,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: PayTableSlotId.Any7,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.D,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.E,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
    {
      slug: SlotId.F,
      combos: [
        { pattern: 'x5', multiplier: 5000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 100 },
        { pattern: 'x2', multiplier: 10 },
      ] as Combos,
    },
  ],
};

export type lowerCaseSymbol = Lowercase<(typeof SlotId)[keyof typeof SlotId]>;
/*export type lowerCaseSymbol = Lowercase<
  Exclude<(typeof SlotId)[keyof typeof SlotId], SlotId.SC> | 'SC1' | 'SC2' | 'SC3' | 'SC4'
>;
*/

export type FishKind = 'Rank1' | 'Rank2' | 'Rank3' | 'Rank4' | 'Rank5' | 'Rank6' | 'Rank7';
