import { gql } from './__generated__';

export const authGql = gql(`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
    }
  }
`);

export const placeBetGql = gql(`
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
     bet {
      ... bet
    }
    balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
    }
    winCoinAmount
  }
}
`);

//done
export const buyBonusGql = gql(`
  mutation buyFeature($input: BuyFeatureInput!) {
  buyFeature(input: $input) {
    wager {
    ... wager
    }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
  }
}
`);

export const gambleGql = gql(`
  mutation SelectOutcomes($input: SelectOutcomesInput!) {
    selectOutcomes(input: $input) {
      bet {
        ...bet
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
      winCoinAmount
    }
  }
`);
