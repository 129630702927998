import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { setBetAmount, setCurrency, setGameMode, setIsTurboSpin } from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { ISettledBet } from '../../types';
import { isFreeSpinsMode, normalizeCoins, showCurrency } from '../../utils';
import { getBetData } from '../../utils/fragments';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import AutoResizeText from '../components/autoResizeText';
//import { LayeredBitmapText } from '../components/layeredBitmapText';
import { layerWin } from '../components/layers/layers';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_END_DURATION,
  DOUBLE_WIN_AMOUNT_LIMIT,
  EventTypes,
  GREAT_WIN_AMOUNT_LIMIT,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MINIMUM_FRACTION_DIGITS,
  SLOT_REELMASK_WIDTH,
  WINCOUNTUP_LANDSCAPE_TEXT_POS_Y,
  WINCOUNTUP_PORTRAIT_TEXT_POS_Y,
  WINCOUNTUP_TEXT_POS_X,
  WINCOUNTUP_TURBO_MULTIPILIER,
  WIN_CHECK_TIME_DURATION_ON_FREE_SPIN,
  WinStages,
  baseCountUpStyle,
  eventManager,
} from '../config';

import { BigWinStage, WinCountUpAnimationSettingType, WinCountUpAnimationSettings } from './winCountUpMessageConfig';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winAmount = 0.0;

  private isDuringWinAnimation = false;

  private isTurboSpin = false;

  private winValueText = new AutoResizeText(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    baseCountUpStyle,
  );

  constructor() {
    super();
    this.winValueText.x = WINCOUNTUP_TEXT_POS_X;
    this.winValueText.y = WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
    this.winValueText.visible = false;
    this.winValueText.anchor.set(0.5);
    this.addChild(this.winValueText);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerWin;
  }

  private hideWinCountUpMessage(): void {
    this.winValueText.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(nextResult: ISettledBet): void {
    const winAmount = normalizeCoins(getBetData(nextResult).betStorage?.estimatedWinCoinAmount!);
    this.startWinAnimationbyNumber(winAmount);
  }

  private startWinAnimationbyNumber(winAmount: number): void {
    this.createWinAnimation(winAmount, () => {
      eventManager.emit(EventTypes.COUNT_UP_END);
    }).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  public createWinAnimation(winCoinAmount: number, onCountUpEnd?: () => void): Animation {
    this.isTurboSpin = setIsTurboSpin();
    this.isDuringWinAnimation = true;
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(winCoinAmount);
    const stage = this.countStage(betAmount, winAmount);

    this.winAmount = winAmount;

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    if (stage <= WinStages.BaseWin) {
      this.winValueText.y = WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
      this.winValueText.pivot.y = 0;

      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      baseWinAnimation.addOnStart(() => {
        this.playWinLoopSound();
      });
      baseWinAnimation.addOnComplete(() => {
        this.stopWinLoopSound();
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        this.stopWinLoopSound();
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      this.winValueText.y = WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
      //this.winValueText.pivot.y = 70;

      animationChain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_COINS);
      });
      animationChain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });

      for (let createStage = WinStages.BigWin; createStage <= stage; createStage++) {
        const animation = this.createCommonWinAnimation(
          winAmount,
          betAmount,
          WinCountUpAnimationSettings[createStage as BigWinStage],
        );
        animationChain.appendAnimation(animation);

        if (createStage === WinStages.EpicWin) {
          animation.addOnSkip(() => {
            this.setWinValue(winAmount);
            this.winValueText.updateText(true);
            this.setWinScaleX(WinCountUpAnimationSettings[WinStages.EpicWin].targetScale);
            this.setWinScaleY(WinCountUpAnimationSettings[WinStages.EpicWin].targetScale);
          });
        }
      }
      BgmControl.stopAll();
      this.playWinLoopSound();
      AudioApi.playAsync({
        type: ISongs.XT004S_bigwin_1shot,
        stopPrev: true,
      }).then(() => {
        if (this.isDuringWinAnimation) {
          BgmControl.playBgm();
        }
      });
    }
    if (stage > WinStages.BaseWin) {
      const bigWinEndDelay = Tween.createDelayAnimation(WIN_CHECK_TIME_DURATION_ON_FREE_SPIN);
      bigWinEndDelay.addOnStart(() => {
        this.stopWinLoopSound();
      });
      bigWinEndDelay.addOnSkip(() => {});
      bigWinEndDelay.addOnComplete(() => {});
      animationChain.appendAnimation(bigWinEndDelay);
    } else {
      const winEndDuration = isFreeSpinsMode(setGameMode())
        ? WIN_CHECK_TIME_DURATION_ON_FREE_SPIN
        : this.isTurboSpin
        ? WIN_CHECK_TIME_DURATION_ON_FREE_SPIN / 3
        : WIN_CHECK_TIME_DURATION_ON_FREE_SPIN;

      const winEndDelay = Tween.createDelayAnimation(winEndDuration);
      animationChain.appendAnimation(winEndDelay);
    }
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValueText,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: BIG_WIN_END_DURATION,
    });

    fadeOutAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      onCountUpEnd?.();
    });
    fadeOutAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      if (winAmount > 0) {
        this.winValueText.alpha = 1;
        this.winValueText.visible = true;
      }
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private clean(): void {
    AudioApi.stop({ type: ISongs.XT004S_win_epic });
    AudioApi.stop({ type: ISongs.XT004S_win_great });
    AudioApi.stop({ type: ISongs.XT004S_win_mega });
    AudioApi.stop({ type: ISongs.XT004S_win_big });
    AudioApi.stop({ type: ISongs.XT004S_win_loop });
    AudioApi.stop({ type: ISongs.XT004S_bigwin_1shot });

    BgmControl.playBgm();

    this.winValueText.visible = false;
    this.winValueText.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
    this.winAmount = 0;
    this.isDuringWinAnimation = false;
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const turboSpinFactor = this.isTurboSpin ? WINCOUNTUP_TURBO_MULTIPILIER : 1.0;
    const baseWinAnimation = new AnimationGroup({});
    const duration = ((win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000) / turboSpinFactor;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createCommonWinAnimation(win: number, bet: number, settings: WinCountUpAnimationSettingType): Animation {
    const { sound, ownStage, amountLimit, countUpMultiplier, beginValue, beginScale, targetScale } = settings;
    const animation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    const turboSpinFactor = this.isTurboSpin ? WINCOUNTUP_TURBO_MULTIPILIER : 1.0;

    animation.addOnStart(() => {
      AudioApi.play({ type: sound, stopPrev: true });
      eventManager.emit(EventTypes.SET_WIN_VISIBILITY, ownStage);
    });
    animation.addOnSkip(() => {
      AudioApi.stop({ type: sound });
    });
    animation.addOnComplete(() => {
      AudioApi.stop({ type: sound });
    });

    const duration =
      ((Math.min(win / bet, amountLimit) / (countUpMultiplier * APPLICATION_FPS)) * 1000) / turboSpinFactor;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * beginValue,
      target: Math.min(win, bet * amountLimit),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * amountLimit));
      this.winValueText.texture.update();
      this.setWinScaleX(targetScale);
      this.setWinScaleY(targetScale);
    });
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: beginScale,
      target: targetScale,
      property: TweenProperties.X,
      update: this.setWinScaleX.bind(this),
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: beginScale,
      target: targetScale,
      property: TweenProperties.Y,
      update: this.setWinScaleY.bind(this),
      duration,
    });

    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    animation.appendAnimation(countUpAnimationGroup);

    return animation;
  }

  private countStage(bet: number, win: number): WinStages {
    const multiplier = win / bet;

    if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
      return WinStages.None;
    }
    if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinStages.BaseWin;
    }
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinStages.BigWin;
    }
    if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
    if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;

    return WinStages.EpicWin;
  }

  private playWinLoopSound() {
    AudioApi.play({ type: ISongs.XT004S_win_loop, stopPrev: true });
  }

  private stopWinLoopSound() {
    if (AudioApi.isPlaying(ISongs.XT004S_win_loop)) {
      AudioApi.stop({ type: ISongs.XT004S_win_loop });
      AudioApi.play({ type: ISongs.XT004S_win_end, stopPrev: true });
    }
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winValueText.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;

    if (this.winAmount <= this.winValue) {
      this.stopWinLoopSound();
    }
  }

  public setWinScaleX(scale: number): void {
    this.winValueText.scale.x = Math.min(SLOT_REELMASK_WIDTH / this.winValueText.texture.width, scale);
  }

  public setWinScaleY(scale: number): void {
    this.winValueText.scale.y = Math.min(SLOT_REELMASK_WIDTH / this.winValueText.texture.width, scale);
  }

  private applicationResize = (width: number, height: number): void => {
    if (height > width) {
      this.winValueText.y = WINCOUNTUP_PORTRAIT_TEXT_POS_Y;
    } else {
      this.winValueText.y = WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
    }
  };
}

export default WinCountUpMessage;
