import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { FeatureTypes } from '../../consts';
import { getFragmentData } from '../../gql';
import { setCoinAmount, setCoinValue, setSlotConfig } from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import { isStoppedGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';

import { wagerFragment } from './../../gql/fragment';

const useBuyFeature = () => {
  const [buyBonus] = useMutation(buyBonusGql, {
    onCompleted(data) {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      const wager = getFragmentData(wagerFragment, data.buyFeature.wager);
      eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, wager.id); // Triggers free spins TO DO
    },
  });

  useEffect(() => {
    const handleBuyBonus = (index: number) => {
      const bonuses = setSlotConfig()?.slotSettings?.buyFeaturePackages;
      const bonus = bonuses?.filter(
        (bonus) =>
          bonus.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS_NORMAL ||
          bonus.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS_SPECIAL,
      );
      const packageId = bonus
        ? bonus[index]?.packageId!
          ? bonus[index]?.packageId
          : FeatureTypes.BUY_FEATURE_FREE_SPINS_NORMAL
        : FeatureTypes.BUY_FEATURE_FREE_SPINS_NORMAL;

      buyBonus({
        variables: {
          input: {
            slotId: setSlotConfig().slotId,
            packageId: packageId!,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    };

    eventManager.on(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    return () => {
      eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    };
  }, [buyBonus]);
};

export default useBuyFeature;
