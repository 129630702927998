import * as PIXI from 'pixi.js';

import { FishKind } from '../../config';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { transitionToBaseGame } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingGamble, layerUI } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout, fishingGambleDistanceTextStyles, fishingGambleTitleTextStyles } from './config';

class FishGamble extends ViewContainer {
  private rod: TickerSpine<'fg_indi'>;
  private sign: TickerSpine<'fg_indi'>;
  private effect: TickerSpine<'fg_indi'>;
  private meter: TickerSpine<'fg_indi'>;
  private miss: TickerSpine<'fg_indi'>;

  private layout: 'portrait' | 'landscape';

  private fishRank: FishKind;
  private target = 0;

  private nowPosition = 0;

  private meterPos = { x: 0, y: 0 };

  private titleText: AutoResizeText;

  private distText: AutoResizeText;

  private gambleStartBtn: PIXI.Container;

  private meterStop = true;

  constructor() {
    super();

    this.fishRank = 'Rank1';
    this.layout = 'landscape';

    this.rod = new TickerSpine('fg_indi');
    this.rod.state.setAnimation(0, 'rod', true);

    this.sign = new TickerSpine('fg_indi');
    this.sign.state.setAnimation(0, 'sgin', true);

    this.effect = new TickerSpine('fg_indi');
    this.effect.state.setAnimation(0, 'start', true);

    this.meter = new TickerSpine('fg_indi');
    this.meter.state.setAnimation(0, 'meter', true);

    this.miss = new TickerSpine('fg_indi');

    this.titleText = new AutoResizeText(i18n.t('fishing.gamble.title'), fishingGambleTitleTextStyles);
    this.titleText.anchor.set(0.5);

    this.distText = new AutoResizeText('100M', fishingGambleDistanceTextStyles);
    this.distText.anchor.set(0.5);
    this.distText.visible = false;

    this.meterStop = true;

    //this.miss = new TickerSpine('fg_indi');
    //this.miss.state.setAnimation(0, 'y_miss', true);

    this.gambleStartBtn = this.initButtonContainer(
      FishingLayout.Gamble.gambleStartBtnArea.x,
      FishingLayout.Gamble.gambleStartBtnArea.y,
      FishingLayout.Gamble.gambleStartBtnArea.w,
      FishingLayout.Gamble.gambleStartBtnArea.h,
      this.onGambleStartClick.bind(this),
    );

    this.addChild(
      this.rod,
      this.sign,
      this.meter,
      this.effect,
      this.titleText,
      this.distText,
      this.miss,
      this.gambleStartBtn,
    );

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.FISHING_GAMBLE_START, this.start.bind(this));

    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));

    this.parentLayer = layerFishingGamble;

    this.visible = false;

    this.gambleStartBtn.parentLayer = layerUI;
  }

  private initButtonContainer(x: number, y: number, w: number, h: number, callback: () => void) {
    const container = new PIXI.Container();
    container.interactive = true;
    container.buttonMode = true;
    container.hitArea = new PIXI.Rectangle(x, y, w, h);
    container.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });
    container.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });

    container.addListener('mouseover', () => {});
    container.addListener('mouseout', () => {});
    container.addListener('mousedown', () => {});
    container.addListener('mouseup', () => {});
    return container;
  }

  private onGambleStartClick() {
    this.gambleStartBtn.interactive = false;

    this.meter.state.setAnimation(0, 'meter', true);
    this.meterStop = false;
    this.createMeterAnimation();
    this.titleText.visible = false;
    this.distText.visible = true;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private onEndGotcha() {
    this.visible = false;
  }

  private start(fishRank: FishKind, target: number, _result: boolean) {
    this.fishRank = fishRank;
    this.nowPosition = 0;

    this.titleText.visible = true;
    this.distText.visible = false;

    this.effect.state.setAnimation(0, 'start', false);
    this.meter.state.setEmptyAnimation(0);
    this.target = target;
    this.visible = true;
    this.gambleStartBtn.interactive = true;
  }

  private createMeterAnimation() {
    const AnimationDurationMax = 3299;
    const AnimationTargetMax = 99;

    const down = new Tween({
      object: this.meterPos,
      property: TweenProperties.X,
      propertyBeginValue: 0,
      target: AnimationTargetMax,
      easing: (t) => t,
      //delay: startingDelay,
      update: this.updateMeterPos.bind(this),
      duration: AnimationDurationMax,
    });
    const up = new Tween({
      object: this.meterPos,
      property: TweenProperties.X,
      propertyBeginValue: AnimationTargetMax,
      target: 0,
      update: this.updateMeterPos.bind(this),
      easing: (t) => t,
      //delay: startingDelay,
      duration: AnimationDurationMax,
    });

    const meterAnimatinChain = new AnimationChain({ isLoop: true });

    meterAnimatinChain.appendAnimation(down);
    meterAnimatinChain.appendAnimation(up);

    const animationGroup = new AnimationGroup();
    const distAnimation = new Tween({
      object: this.meterPos,
      property: TweenProperties.X,
      propertyBeginValue: 0,
      target: this.target,
      easing: (t) => t,
      //delay: startingDelay,
      update: this.updateDistance.bind(this),
      duration: (AnimationDurationMax * 2 * this.target) / 100,
    });

    animationGroup.addAnimation(meterAnimatinChain);
    animationGroup.addAnimation(distAnimation);

    distAnimation.addOnComplete(() => {
      this.meterStop = true;
      animationGroup.end();
      distAnimation.end();
      meterAnimatinChain.end();
      this.meter.state.timeScale = 0;

      //rout
      if (this.target === 100) {
        const delay = Tween.createDelayAnimation(1000);
        delay.addOnComplete(() => {
          eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishRank);
        });
        delay.start();
      } else {
        const delay = Tween.createDelayAnimation(1500);
        this.miss.state.setAnimation(0, 'miss', false);
        delay.addOnComplete(() => {
          transitionToBaseGame(() => {
            this.visible = false;
          });
        });
        delay.start();
      }
    });
    animationGroup.start();
  }

  private updateMeterPos(pos: number) {
    console.log(pos);
    if (!this.meterStop) {
      this.updateMeter(pos);
    }
  }

  private updateDistance(dist: number) {
    console.log(dist);
    this.distText.text = `${100 - Math.floor(dist)}M`;
    this.distText.updateText(false);
  }

  private updateMeter(frame: number) {
    // アニメーションを特定のフレームに設定する
    //0 - 100F
    //this.meter.state.getCurrent(0).trackTime = this.frameToTime(10);
    this.nowPosition = this.frameToTime(frame);
    this.meter.state.getCurrent(0).trackTime = this.nowPosition;
  }

  // フレーム数を時間（秒）に変換する関数
  private frameToTime(frame: number) {
    const animation = this.meter.spineData.findAnimation('meter');
    const frameTime = (frame / 100) * animation.duration; // * animation.timelines[0]!.frames.length;
    return frameTime;
  }

  private startMiss() {
    this.meter.state.setAnimation(0, 'miss', false);
  }

  private success() {
    this.meter.state.setAnimation(0, 'meter', false);
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? FishingLayout.Gamble.landscape : FishingLayout.Gamble.portrait;

    this.sign.position.set(layout.Sign.x, layout.Sign.y);
    this.rod.position.set(layout.rod.x, layout.rod.y);
    this.meter.position.set(layout.meter.x, layout.meter.y);
    this.effect.position.set(layout.effect.x, layout.effect.y);
    this.titleText.position.set(layout.textStart.x, layout.textStart.y);
    this.distText.position.set(layout.textStart.x, layout.textStart.y);
    this.miss.position.set(layout.miss.x, layout.miss.y);

    if (width > height) {
      this.layout = 'landscape';
    } else {
      this.layout = 'portrait';
    }
  };
}

export default FishGamble;
