export enum ResourceTypes {
  infoPaylines = 'infoPaylines',
  textPatternBannerRsEndLimitA = 'textPatternBannerRsEndLimitA',
  logo = 'logo',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolSc1 = 'symbolSc1',
  symbolSc2 = 'symbolSc2',
  symbolSc3 = 'symbolSc3',
  symbolSc4 = 'symbolSc4',
}
