import { SlotId } from '../../config';
import { GameMode } from '../../consts';
import { ReelSet } from '../../types';

const commonReelLayout = [
  [
    'A',
    'SC1',
    'E',
    'SC1',
    'B',
    'SC1',
    'C',
    'F',
    'SC1',
    'E',
    'SC1',
    'D',
    'F',
    'SC1',
    'C',
    'SC1',
    'B',
    'E',
    'SC1',
    'D',
    'SC1',
    'F',
    'SC1',
    'C',
    'D',
    'SC1',
    'A',
    'E',
    'SC1',
    'F',
    'SC1',
    'C',
    'SC1',
    'F',
    'B',
    'SC1',
    'E',
    'F',
    'SC1',
    'D',
    'E',
    'SC1',
    'C',
    'B',
    'SC1',
    'E',
    'F',
    'SC1',
    'D',
    'SC1',
    'E',
    'F',
    'SC1',
    'E',
  ] as SlotId[],
  [
    'B',
    'SC2',
    'E',
    'SC2',
    'E',
    'SC2',
    'C',
    'SC2',
    'E',
    'SC2',
    'D',
    'SC2',
    'F',
    'SC2',
    'E',
    'SC2',
    'B',
    'F',
    'SC2',
    'D',
    'SC2',
    'E',
    'SC2',
    'C',
    'F',
    'SC2',
    'B',
    'E',
    'SC2',
    'A',
    'SC2',
    'E',
    'SC2',
    'F',
    'D',
    'SC2',
    'E',
    'B',
    'SC2',
    'F',
    'C',
    'SC2',
    'E',
    'F',
    'SC2',
    'D',
    'E',
    'SC2',
    'E',
    'SC2',
    'A',
    'SC2',
    'F',
    'SC2',
    'E',
  ] as SlotId[],
  [
    'C',
    'SC3',
    'D',
    'A',
    'SC3',
    'F',
    'E',
    'SC3',
    'B',
    'SC3',
    'D',
    'E',
    'SC3',
    'F',
    'C',
    'SC3',
    'F',
    'B',
    'SC3',
    'E',
    'F',
    'SC3',
    'C',
    'D',
    'SC3',
    'F',
    'E',
    'SC3',
    'A',
    'C',
    'SC3',
    'F',
    'SC3',
    'B',
    'F',
    'SC3',
    'D',
    'E',
    'SC3',
    'F',
    'C',
    'SC3',
    'E',
    'D',
    'SC3',
    'B',
    'SC3',
    'E',
    'SC3',
    'C',
    'SC3',
    'F',
    'SC3',
    'A',
    'SC3',
    'F',
  ] as SlotId[],
  [
    'D',
    'SC4',
    'E',
    'SC4',
    'C',
    'SC4',
    'F',
    'SC4',
    'A',
    'SC4',
    'C',
    'SC4',
    'B',
    'E',
    'SC4',
    'D',
    'SC4',
    'F',
    'SC4',
    'C',
    'A',
    'SC4',
    'D',
    'SC4',
    'F',
    'SC4',
    'C',
    'SC4',
    'E',
    'SC4',
    'D',
    'SC4',
    'C',
    'SC4',
    'D',
    'SC4',
    'C',
    'SC4',
    'E',
    'SC4',
    'D',
    'C',
    'SC4',
    'F',
    'SC4',
    'D',
    'SC4',
    'B',
    'SC4',
    'C',
    'SC4',
    'F',
    'SC4',
    'D',
    'SC4',
    'F',
  ] as SlotId[],
];

export const reels: ReelSet[] = [
  {
    type: GameMode.BASE_GAME,
    layout: commonReelLayout,
  },
  {
    type: GameMode.BUY_FEATURE,
    layout: commonReelLayout,
  },
  {
    type: GameMode.BUY_FEATURE_SP,
    layout: commonReelLayout,
  },
];
