/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment outcome on Outcome {\n    id\n    selectionState\n    previousOutcomeId\n    previousOutcome {\n      id\n    }\n    nextOutcome {\n      id\n    }\n    predicament {\n      ... on BillFishRichesPredicament{\n        reelPositions\n        fishKind\n        fishingChallengeResult\n        fishingChallengePosition\n        fishingChallengeWheelCnt\n        fishingGambleResult\n        fishingGamblePosition\n        fishingScalingMultiplier\n      }\n        __typename\n    }\n    stateSnapshot {\n      ... on BillFishRichesOutcomeSnapShot {\n        fieldState\n        winCombinations {\n          attribution\n          lineNumber\n          rewards {\n            type\n            value\n            multiplier\n            entityId\n          }\n        }\n        hasRewardedFreeSpins\n      }\n    }\n    rewards {\n      id\n      type\n      entityId\n      value\n      multiplier\n    }\n    createdAt\n    completedAt\n  }\n':
    types.OutcomeFragmentDoc,
  '\n  fragment wager on Wager {\n    id\n    coinAmount\n    coinValue\n    wagerSettings {\n    ... on BillFishRichesWagerSettings{\n        gameMode\n        rounds\n        packageId\n        originalReelPositions\n        originalGameMode\n      }\n        __typename\n      }\n    wagerStorage {\n    ... on CommonWagerStorage {\n        roundsPlayed\n        totalWinCoinAmount\n        previousTotalWinCoinAmount\n        paidWinCoinAmount\n      }\n        __typename\n      }\n    state\n    order\n    createdAt\n  }\n':
    types.WagerFragmentDoc,
  '\n  fragment bet on Bet {\n      id\n      slotId\n      playerId\n      wager{\n      ... wager\n      }\n      status\n      betStorage {\n        ... on CommonBetStorage {\n        estimatedWinCoinAmount\n        }\n      }\n      outcomes{\n      ... outcome\n      }\n      coinAmount\n      coinAmountMultiplier\n      coinValue\n      effectiveCoinAmount\n      createdAt\n}\n':
    types.BetFragmentDoc,
  '\n  mutation Auth($input: AuthInput!) {\n    auth(input: $input) {\n      sessionId\n    }\n  }\n':
    types.AuthDocument,
  '\n  mutation PlaceBet($input: PlaceBetInput!) {\n    placeBet(input: $input) {\n     bet {\n      ... bet\n    }\n    balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n    }\n    winCoinAmount\n  }\n}\n':
    types.PlaceBetDocument,
  '\n  mutation buyFeature($input: BuyFeatureInput!) {\n  buyFeature(input: $input) {\n    wager {\n    ... wager\n    }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n  }\n}\n':
    types.BuyFeatureDocument,
  '\n  mutation SelectOutcomes($input: SelectOutcomesInput!) {\n    selectOutcomes(input: $input) {\n      bet {\n        ...bet\n      }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n      winCoinAmount\n    }\n  }\n':
    types.SelectOutcomesDocument,
  '\n  query GetBalance {\n    balance {\n      currency\n      amount\n    }\n  }\n': types.GetBalanceDocument,
  '\n  query getRestoreState($input: RestoreStateInput!) {\n    restoreState(input: $input) {\n      bets {\n        ... bet\n      }\n      wagers {\n        ... wager\n      }\n    }\n  }\n':
    types.GetRestoreStateDocument,
  '\n  query slotHistory($input: BetHistoryInput!) {\n    betHistory(input: $input) {\n      bets {\n      ... bet\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n':
    types.SlotHistoryDocument,
  '\n  query getNextOutcomes($input: NextOutcomesInput!) {\n    nextOutcomes(input: $input) {\n      bet {\n      ... bet\n      }\n    }\n  }\n':
    types.GetNextOutcomesDocument,
  '\n  query gameSettings($input: GameSettingsInput!) {\n    gameSettings(input: $input) {\n      slotId\n      clientSlotSettings {\n        betCoinAmountSettings\n        quickBetCoinAmountSettings\n        coinValueSettings {\n          code\n          variants\n        }\n        autoplaySettings {\n          options\n          conditions {\n            stopOnAnyWin {\n              enabled\n            }\n            stopIfFeatureIsWon {\n              enabled\n            }\n            stopIfSingleWinExceeds {\n              enabled\n              multipliers\n            }\n            stopIfBalanceDecreasesBy {\n              enabled\n            }\n            stopIfBalanceIncreasesBy {\n              enabled\n              multipliers\n            }\n          }\n        }\n        wagerExpirationDurationSeconds\n        realBetCoinAmounts\n      }\n      slotSettings {\n        ... on TumblingReelSlotSettings {\n          cols\n          rows\n          startingPositions\n          betLines {\n            min\n            max\n          }\n          globalCoinAmountMultiplier\n          buyFeaturePackages {\n            packageId\n            coinAmountMultiplier\n            rounds\n          }\n        }\n      }\n    }\n  }\n':
    types.GameSettingsDocument,
  '\n  query Config {\n    isTurboSpin @client\n    isSoundOn @client\n    isSpinInProgress @client\n    isSlotBusy @client\n    isSlotStopped @client\n    isOpenBetSettingsPopup @client\n    isOpenAutoplayPopup @client\n    isOpenInfoPopup @client\n    isOpenHistoryPopup @client\n  }\n':
    types.ConfigDocument,
  '\n  query GetBet {\n    betAmount @client\n  }\n': types.GetBetDocument,
  '\n  query GetWin {\n    winAmount @client\n  }\n': types.GetWinDocument,
  '\n  query GetAutoSpinsSettings {\n    autoSpinsAmount @client\n    stopOnWinExceeds @client\n    stopOnBalanceIncrease @client\n    stopOnBalanceDecrease @client\n    isStopOnAnyWin @client\n    isStopOnFeatureWin @client\n    isStopOnWinExceeds @client\n    isStopOnBalanceIncrease @client\n    isStopOnBalanceDecrease @client\n    autoSpinsStartBalance @client\n  }\n':
    types.GetAutoSpinsSettingsDocument,
  '\n  query GetAutoSpin {\n    isAutoSpins @client\n    autoSpinsLeft @client\n  }\n': types.GetAutoSpinDocument,
  '\n  query IsStopped {\n    isSlotStopped @client\n  }\n': types.IsStoppedDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment outcome on Outcome {\n    id\n    selectionState\n    previousOutcomeId\n    previousOutcome {\n      id\n    }\n    nextOutcome {\n      id\n    }\n    predicament {\n      ... on BillFishRichesPredicament{\n        reelPositions\n        fishKind\n        fishingChallengeResult\n        fishingChallengePosition\n        fishingChallengeWheelCnt\n        fishingGambleResult\n        fishingGamblePosition\n        fishingScalingMultiplier\n      }\n        __typename\n    }\n    stateSnapshot {\n      ... on BillFishRichesOutcomeSnapShot {\n        fieldState\n        winCombinations {\n          attribution\n          lineNumber\n          rewards {\n            type\n            value\n            multiplier\n            entityId\n          }\n        }\n        hasRewardedFreeSpins\n      }\n    }\n    rewards {\n      id\n      type\n      entityId\n      value\n      multiplier\n    }\n    createdAt\n    completedAt\n  }\n',
): (typeof documents)['\n  fragment outcome on Outcome {\n    id\n    selectionState\n    previousOutcomeId\n    previousOutcome {\n      id\n    }\n    nextOutcome {\n      id\n    }\n    predicament {\n      ... on BillFishRichesPredicament{\n        reelPositions\n        fishKind\n        fishingChallengeResult\n        fishingChallengePosition\n        fishingChallengeWheelCnt\n        fishingGambleResult\n        fishingGamblePosition\n        fishingScalingMultiplier\n      }\n        __typename\n    }\n    stateSnapshot {\n      ... on BillFishRichesOutcomeSnapShot {\n        fieldState\n        winCombinations {\n          attribution\n          lineNumber\n          rewards {\n            type\n            value\n            multiplier\n            entityId\n          }\n        }\n        hasRewardedFreeSpins\n      }\n    }\n    rewards {\n      id\n      type\n      entityId\n      value\n      multiplier\n    }\n    createdAt\n    completedAt\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment wager on Wager {\n    id\n    coinAmount\n    coinValue\n    wagerSettings {\n    ... on BillFishRichesWagerSettings{\n        gameMode\n        rounds\n        packageId\n        originalReelPositions\n        originalGameMode\n      }\n        __typename\n      }\n    wagerStorage {\n    ... on CommonWagerStorage {\n        roundsPlayed\n        totalWinCoinAmount\n        previousTotalWinCoinAmount\n        paidWinCoinAmount\n      }\n        __typename\n      }\n    state\n    order\n    createdAt\n  }\n',
): (typeof documents)['\n  fragment wager on Wager {\n    id\n    coinAmount\n    coinValue\n    wagerSettings {\n    ... on BillFishRichesWagerSettings{\n        gameMode\n        rounds\n        packageId\n        originalReelPositions\n        originalGameMode\n      }\n        __typename\n      }\n    wagerStorage {\n    ... on CommonWagerStorage {\n        roundsPlayed\n        totalWinCoinAmount\n        previousTotalWinCoinAmount\n        paidWinCoinAmount\n      }\n        __typename\n      }\n    state\n    order\n    createdAt\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment bet on Bet {\n      id\n      slotId\n      playerId\n      wager{\n      ... wager\n      }\n      status\n      betStorage {\n        ... on CommonBetStorage {\n        estimatedWinCoinAmount\n        }\n      }\n      outcomes{\n      ... outcome\n      }\n      coinAmount\n      coinAmountMultiplier\n      coinValue\n      effectiveCoinAmount\n      createdAt\n}\n',
): (typeof documents)['\n  fragment bet on Bet {\n      id\n      slotId\n      playerId\n      wager{\n      ... wager\n      }\n      status\n      betStorage {\n        ... on CommonBetStorage {\n        estimatedWinCoinAmount\n        }\n      }\n      outcomes{\n      ... outcome\n      }\n      coinAmount\n      coinAmountMultiplier\n      coinValue\n      effectiveCoinAmount\n      createdAt\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation Auth($input: AuthInput!) {\n    auth(input: $input) {\n      sessionId\n    }\n  }\n',
): (typeof documents)['\n  mutation Auth($input: AuthInput!) {\n    auth(input: $input) {\n      sessionId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation PlaceBet($input: PlaceBetInput!) {\n    placeBet(input: $input) {\n     bet {\n      ... bet\n    }\n    balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n    }\n    winCoinAmount\n  }\n}\n',
): (typeof documents)['\n  mutation PlaceBet($input: PlaceBetInput!) {\n    placeBet(input: $input) {\n     bet {\n      ... bet\n    }\n    balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n    }\n    winCoinAmount\n  }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation buyFeature($input: BuyFeatureInput!) {\n  buyFeature(input: $input) {\n    wager {\n    ... wager\n    }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n  }\n}\n',
): (typeof documents)['\n  mutation buyFeature($input: BuyFeatureInput!) {\n  buyFeature(input: $input) {\n    wager {\n    ... wager\n    }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n  }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SelectOutcomes($input: SelectOutcomesInput!) {\n    selectOutcomes(input: $input) {\n      bet {\n        ...bet\n      }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n      winCoinAmount\n    }\n  }\n',
): (typeof documents)['\n  mutation SelectOutcomes($input: SelectOutcomesInput!) {\n    selectOutcomes(input: $input) {\n      bet {\n        ...bet\n      }\n      balance {\n        settled {\n          currency\n          amount\n        }\n        placed {\n          currency\n          amount\n        }\n      }\n      winCoinAmount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBalance {\n    balance {\n      currency\n      amount\n    }\n  }\n',
): (typeof documents)['\n  query GetBalance {\n    balance {\n      currency\n      amount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getRestoreState($input: RestoreStateInput!) {\n    restoreState(input: $input) {\n      bets {\n        ... bet\n      }\n      wagers {\n        ... wager\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getRestoreState($input: RestoreStateInput!) {\n    restoreState(input: $input) {\n      bets {\n        ... bet\n      }\n      wagers {\n        ... wager\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query slotHistory($input: BetHistoryInput!) {\n    betHistory(input: $input) {\n      bets {\n      ... bet\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n',
): (typeof documents)['\n  query slotHistory($input: BetHistoryInput!) {\n    betHistory(input: $input) {\n      bets {\n      ... bet\n      }\n      count\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getNextOutcomes($input: NextOutcomesInput!) {\n    nextOutcomes(input: $input) {\n      bet {\n      ... bet\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getNextOutcomes($input: NextOutcomesInput!) {\n    nextOutcomes(input: $input) {\n      bet {\n      ... bet\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query gameSettings($input: GameSettingsInput!) {\n    gameSettings(input: $input) {\n      slotId\n      clientSlotSettings {\n        betCoinAmountSettings\n        quickBetCoinAmountSettings\n        coinValueSettings {\n          code\n          variants\n        }\n        autoplaySettings {\n          options\n          conditions {\n            stopOnAnyWin {\n              enabled\n            }\n            stopIfFeatureIsWon {\n              enabled\n            }\n            stopIfSingleWinExceeds {\n              enabled\n              multipliers\n            }\n            stopIfBalanceDecreasesBy {\n              enabled\n            }\n            stopIfBalanceIncreasesBy {\n              enabled\n              multipliers\n            }\n          }\n        }\n        wagerExpirationDurationSeconds\n        realBetCoinAmounts\n      }\n      slotSettings {\n        ... on TumblingReelSlotSettings {\n          cols\n          rows\n          startingPositions\n          betLines {\n            min\n            max\n          }\n          globalCoinAmountMultiplier\n          buyFeaturePackages {\n            packageId\n            coinAmountMultiplier\n            rounds\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query gameSettings($input: GameSettingsInput!) {\n    gameSettings(input: $input) {\n      slotId\n      clientSlotSettings {\n        betCoinAmountSettings\n        quickBetCoinAmountSettings\n        coinValueSettings {\n          code\n          variants\n        }\n        autoplaySettings {\n          options\n          conditions {\n            stopOnAnyWin {\n              enabled\n            }\n            stopIfFeatureIsWon {\n              enabled\n            }\n            stopIfSingleWinExceeds {\n              enabled\n              multipliers\n            }\n            stopIfBalanceDecreasesBy {\n              enabled\n            }\n            stopIfBalanceIncreasesBy {\n              enabled\n              multipliers\n            }\n          }\n        }\n        wagerExpirationDurationSeconds\n        realBetCoinAmounts\n      }\n      slotSettings {\n        ... on TumblingReelSlotSettings {\n          cols\n          rows\n          startingPositions\n          betLines {\n            min\n            max\n          }\n          globalCoinAmountMultiplier\n          buyFeaturePackages {\n            packageId\n            coinAmountMultiplier\n            rounds\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Config {\n    isTurboSpin @client\n    isSoundOn @client\n    isSpinInProgress @client\n    isSlotBusy @client\n    isSlotStopped @client\n    isOpenBetSettingsPopup @client\n    isOpenAutoplayPopup @client\n    isOpenInfoPopup @client\n    isOpenHistoryPopup @client\n  }\n',
): (typeof documents)['\n  query Config {\n    isTurboSpin @client\n    isSoundOn @client\n    isSpinInProgress @client\n    isSlotBusy @client\n    isSlotStopped @client\n    isOpenBetSettingsPopup @client\n    isOpenAutoplayPopup @client\n    isOpenInfoPopup @client\n    isOpenHistoryPopup @client\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBet {\n    betAmount @client\n  }\n',
): (typeof documents)['\n  query GetBet {\n    betAmount @client\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetWin {\n    winAmount @client\n  }\n',
): (typeof documents)['\n  query GetWin {\n    winAmount @client\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetAutoSpinsSettings {\n    autoSpinsAmount @client\n    stopOnWinExceeds @client\n    stopOnBalanceIncrease @client\n    stopOnBalanceDecrease @client\n    isStopOnAnyWin @client\n    isStopOnFeatureWin @client\n    isStopOnWinExceeds @client\n    isStopOnBalanceIncrease @client\n    isStopOnBalanceDecrease @client\n    autoSpinsStartBalance @client\n  }\n',
): (typeof documents)['\n  query GetAutoSpinsSettings {\n    autoSpinsAmount @client\n    stopOnWinExceeds @client\n    stopOnBalanceIncrease @client\n    stopOnBalanceDecrease @client\n    isStopOnAnyWin @client\n    isStopOnFeatureWin @client\n    isStopOnWinExceeds @client\n    isStopOnBalanceIncrease @client\n    isStopOnBalanceDecrease @client\n    autoSpinsStartBalance @client\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetAutoSpin {\n    isAutoSpins @client\n    autoSpinsLeft @client\n  }\n',
): (typeof documents)['\n  query GetAutoSpin {\n    isAutoSpins @client\n    autoSpinsLeft @client\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query IsStopped {\n    isSlotStopped @client\n  }\n',
): (typeof documents)['\n  query IsStopped {\n    isSlotStopped @client\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never;
