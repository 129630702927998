import { IPointData } from 'pixi.js';

import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

/*
const test = {
  a: {
    key: 'title',
    type: 'AutoResizeText',
    position: { x: 0, y: 0 },
    anchor: { x: 0, y: 0 },
    i18n:
    style:
  },
};
*/
export const FEATURE_BTN_DEFAULT_SCALE = 0.95;
export const FEATURE_BTN_MOUSE_OVER_SCALE = 1.13 * FEATURE_BTN_DEFAULT_SCALE;
export const FEATURE_BTN_MOUSE_CLICK_SCALE = 0.9 * FEATURE_BTN_DEFAULT_SCALE;

export const FEATURE_BTN_LANDSCAPE_X = -380;
export const FEATURE_BTN_LANDSCAPE_Y = 730;

export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 0;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 4;

const FEATURE_POPUP_BASE_X = 428;
const FEATURE_POPUP_BASE_Y = 84;
export const FEATURE_POPUP_SPINE_WIDTH = 1208;
export const FEATURE_POPUP_SPINE_HEIGHT = 794;
export const FEATURE_POPUP_CENTER_X = FEATURE_POPUP_SPINE_WIDTH / 2;

export const FEATURE_POPUP_POSITIONS = {
  portrait: { x: 0, y: 0 },
  landscape: { x: 90, y: 120 },
  scale: { x: 930 / 1162, y: 1029 / 1286 },
  BaseContainer: {
    Portrait: { x: 462, y: 239 },
    Landscape: { x: 442, y: 328 },
    Base: { x: 0, y: 0 },
    Title: { x: -2, y: -352 },
    CancelBtn: { x: -203, y: 458 },
    OkBtn: { x: 203, y: 458 },
  },
  MenuContainer: {
    Portrait: { x: -119, y: -404 },
    Landscape: { x: -22, y: -186 },
    Normal: {
      size: { w: 480, h: 390 },
      Container: { x: 82, y: 412 },
      Base: { x: 240, y: 195 },
      Title: { x: 225, y: 58 },
      Cost: { x: 229, y: 290 },
    },
    Special: {
      size: { w: 480, h: 390 },
      Container: { x: 592, y: 412 },
      Base: { x: 240, y: 195 },
      Title: { x: 238, y: 58 },
      Cost: { x: 238, y: 289 },
      Description: { x: 238, y: 334 },
    },
    BetMenu: {
      Container: { x: 310, y: 889 },
      BetTitle: { x: 257, y: -50 },
      MinusBtn: { x: -53, y: 60 },
      PlusBtn: { x: 569, y: 62 },
      BetBase: { x: 252, y: 59 },
      BetValue: { x: 253, y: 60 },
    },
  },
  Confirm: {
    description: { x: 569, y: 423 },
    iconContainer: { x: 569 - 240, y: 671 - 195 },
    totalCostText: { x: 569, y: 881 },
    totalCostValue: { x: 569, y: 959 },
  },
};

export const FEATURE_POPUP_CONFIRM_POSITIONS = {
  BG: { x: -FEATURE_POPUP_SPINE_WIDTH / 2, y: -FEATURE_POPUP_SPINE_HEIGHT / 2 } as IPointData,
  TITLE: { x: FEATURE_POPUP_CENTER_X, y: 130 },
  TOTAL_COST_TITLE: { x: FEATURE_POPUP_CENTER_X, y: 367 },
  TOTAL_COST_VALUE: { x: FEATURE_POPUP_CENTER_X, y: 442 - 17 },
  CANCEL_BUTTON: { x: 358 + 142 / 2, y: 558 + 158 / 2 },
  OK_BUTTON: { x: 720 + 142 / 2, y: 558 + 158 / 2 },
};
export const FEATURE_POPUP_MP_BTN_HITAREA_SCALE = 1.8;

export const FEATURE_POPUP_LANDSCAPE_POSITION_X = FEATURE_POPUP_BASE_X + SAFE_AREA_LANDSCAPE_PIVOT_X;
export const FEATURE_POPUP_LANDSCAPE_POSITION_Y = FEATURE_POPUP_BASE_Y + SAFE_AREA_LANDSCAPE_PIVOT_Y;
export const FEATURE_POPUP_PORTRAIT_POSITION_X = 1 + SAFE_AREA_PORTRAIT_PIVOT_X;
export const FEATURE_POPUP_PORTRAIT_POSITION_Y = 596 + SAFE_AREA_PORTRAIT_PIVOT_Y;
