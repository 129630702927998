import { GameMode } from '../../consts';
import ViewContainer from '../components/container';
import { layerRod } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import {
  EventTypes,
  ROD_PORTRAIT_POSITION_X,
  ROD_PORTRAIT_POSITION_Y,
  ROD_POSITION_X,
  ROD_POSITION_Y,
  eventManager,
} from '../config';

class Rod extends ViewContainer {
  private spine: TickerSpine<'rod'>;

  constructor() {
    super();

    this.spine = new TickerSpine('rod');
    this.spine.position.x = ROD_POSITION_X;
    this.spine.position.y = ROD_POSITION_Y;
    this.addChild(this.spine);

    this.spine.state.setAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerRod;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.spine.visible = false;
    } else {
      this.spine.visible = true;
    }
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.spine.state.setAnimation(0, 'base', true);
      this.spine.position.x = ROD_POSITION_X;
      this.spine.position.y = ROD_POSITION_Y;
    } else {
      this.spine.state.setAnimation(0, 'base', true);
      this.spine.position.x = ROD_PORTRAIT_POSITION_X;
      this.spine.position.y = ROD_PORTRAIT_POSITION_Y;
    }
  };
}

export default Rod;
