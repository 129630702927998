import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

// Button Text Style
export const buyFeatureBtnTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 58,
  lineHeight: 58,
  maxWidth: 188,
  maxHeight: 84,
  fill: Colors.GAME_COLOR,
  letterSpacing: 0,
  lineJoin: 'round',
};

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 490,
  fontSize: 70,
  fill: ['#FFE17B', '#C36D24', '#AA3D00'],
  fillGradientStops: [0.18, 0.65, 0.91],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#201307',
  strokeThickness: 10,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.75,
};

export const buyFeatureNormalTitleTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 300,
  fontSize: 48,
  fill: ['#60B2FF', '#00539F'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const buyFeatureNormalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 300,
  fontSize: 48,
  fill: '#FFE24C',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const buyFeatureSpecialTitleTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 300,
  fontSize: 48,
  fill: ['#EC0000', '#FFDA58'],
  fillGradientStops: [0.27, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const buyFeatureSpecialDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 27,
  fill: ['#FF2408', '#FFD912'],
  fillGradientStops: [0.36, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const buyFeatureBetDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 48,
  fontFamily: font,
  maxWidth: 700,
  fill: '#FFD912',
  fontWeight: '700',
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 64,
  fontFamily: font,
  fill: '#FFE24D',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'center',
  maxWidth: 450,
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45,
  fontFamily: font,
  letterSpacing: 0,
  align: 'left',
  maxWidth: 700,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const confirmTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 700,
  fontSize: 45,
  fill: ['#FFD912', '#E8A700'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const confirmTotalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 700,
  fontSize: 48,
  fill: ['#FFD912', '#FF6B00'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 700,
  fontSize: 110,
  fill: ['#FFD912', '#FFA800'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
