import { Layer } from '@pixi/layers';
import { IPointData } from 'pixi.js';

import { layerReel0, layerReel1, layerReel2, layerReel3 } from '../components/layers/layers';

export interface ReelInfo {
  ID: number;
  position: IPointData;
  parentLayer: Layer;
}
export const REELS_SETTING: ReelInfo[] = [
  {
    ID: 0,
    position: { x: 0, y: 0 },
    parentLayer: layerReel0,
  },
  {
    ID: 1,
    position: { x: 0, y: 0 },
    parentLayer: layerReel1,
  },
  {
    ID: 2,
    position: { x: 0, y: 0 },
    parentLayer: layerReel2,
  },
  {
    ID: 3,
    position: { x: 0, y: 0 },
    parentLayer: layerReel3,
  },
];
