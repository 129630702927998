import { BonusStatus, GameMode } from '../../consts';
import { getFragmentData } from '../../gql';
import {
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { wagerFragment } from '../../gql/fragment';
import { getRestoreStateGql } from '../../gql/query';
import { IWagerFragment } from '../../types';
import { getNonNullableValue, getWagerSettingsFromWager, getWagerStorageFromWager } from '../../utils';

export const useRestoreState = () => {
  const checkBrokenGame = async () => {
    const { data: restoreStateData } = await client.query({
      query: getRestoreStateGql,
      variables: { input: { slotId: setSlotConfig().slotId } },
      fetchPolicy: 'network-only',
    });

    if (restoreStateData.restoreState.wagers.length > 0) {
      const wagers = getFragmentData(
        wagerFragment,
        getNonNullableValue(restoreStateData.restoreState.wagers),
      ) as IWagerFragment[];
      const buyFeatureBonus = wagers.find((wager) => {
        return 'gameMode' in wager.wagerSettings && wager.wagerSettings.gameMode === GameMode.BUY_FEATURE
          ? wager
          : undefined;
      }) as IWagerFragment;
      const freeSpinsBonus = wagers.find((wager) => {
        return 'gameMode' in wager.wagerSettings && wager.wagerSettings.gameMode === GameMode.FREE_SPINS
          ? wager
          : undefined;
      });

      const wagerStorage = getNonNullableValue(getWagerStorageFromWager(buyFeatureBonus));
      const wagerSettings = getNonNullableValue(getWagerSettingsFromWager(buyFeatureBonus));

      if (!wagerSettings) return;
      if (!wagerStorage) return;

      if (buyFeatureBonus) {
        //TO DO : if buybonus has multi id
        setBrokenBuyFeatureGame(wagerSettings.packageId!);
        setIsBuyFeaturePopupOpened(true);
        setCurrentBonus({
          packageId: wagerSettings.packageId,
          gameMode: wagerSettings.gameMode,
          rounds: wagerSettings.rounds,
          roundsPlayed: wagerStorage.roundsPlayed,
          state: buyFeatureBonus.state as BonusStatus,
          coinAmount: buyFeatureBonus.coinAmount!,
          coinValue: buyFeatureBonus.coinValue!,
          originalReelPositions: wagerSettings.originalReelPositions,
          isBuyFeature: true,
        });
      } else if (freeSpinsBonus) {
        setBrokenGame(true);
        setCurrentFreeSpinsTotalWin(wagerStorage.previousTotalWinCoinAmount + wagerStorage.totalWinCoinAmount);
        setCurrentBonus({
          packageId: wagerSettings.packageId,
          gameMode: wagerSettings.gameMode as GameMode,
          rounds: wagerSettings.rounds,
          roundsPlayed: wagerStorage.roundsPlayed,
          state: freeSpinsBonus.state as BonusStatus,
          coinAmount: freeSpinsBonus.coinAmount!,
          coinValue: freeSpinsBonus.coinValue!,
          originalReelPositions: wagerSettings.originalReelPositions,
          isBuyFeature: wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
        });
      }
    }
  };

  return {
    checkBrokenGame,
  };
};
