import { gql } from './__generated__';

export const outcomeFragment = gql(`
  fragment outcome on Outcome {
    id
    selectionState
    previousOutcomeId
    previousOutcome {
      id
    }
    nextOutcome {
      id
    }
    predicament {
      ... on BillFishRichesPredicament{
        reelPositions
        fishKind
        fishingChallengeResult
        fishingChallengePosition
        fishingChallengeWheelCnt
        fishingGambleResult
        fishingGamblePosition
        fishingScalingMultiplier
      }
        __typename
    }
    stateSnapshot {
      ... on BillFishRichesOutcomeSnapShot {
        fieldState
        winCombinations {
          attribution
          lineNumber
          rewards {
            type
            value
            multiplier
            entityId
          }
        }
        hasRewardedFreeSpins
      }
    }
    rewards {
      id
      type
      entityId
      value
      multiplier
    }
    createdAt
    completedAt
  }
`);

export const wagerFragment = gql(`
  fragment wager on Wager {
    id
    coinAmount
    coinValue
    wagerSettings {
    ... on BillFishRichesWagerSettings{
        gameMode
        rounds
        packageId
        originalReelPositions
        originalGameMode
      }
        __typename
      }
    wagerStorage {
    ... on CommonWagerStorage {
        roundsPlayed
        totalWinCoinAmount
        previousTotalWinCoinAmount
        paidWinCoinAmount
      }
        __typename
      }
    state
    order
    createdAt
  }
`);

export const betFragment = gql(`
  fragment bet on Bet {
      id
      slotId
      playerId
      wager{
      ... wager
      }
      status
      betStorage {
        ... on CommonBetStorage {
        estimatedWinCoinAmount
        }
      }
      outcomes{
      ... outcome
      }
      coinAmount
      coinAmountMultiplier
      coinValue
      effectiveCoinAmount
      createdAt
}
`);
