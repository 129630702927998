import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';
import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

export const FISHING_PRIZE_POS = { x: 960 + SAFE_AREA_LANDSCAPE_PIVOT_X, y: 540 + SAFE_AREA_LANDSCAPE_PIVOT_Y };
export const FISHING_PRIZE_PORTRAIT_POS = { x: 606 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 1078 + SAFE_AREA_PORTRAIT_PIVOT_Y };

export const FishingLayout = {
  Challenge: {
    Scale: 880 / 1023,
    Container: {
      Landscape: { x: 484 - 560, y: 88 - 66 },
      PortRait: { x: 7 - 146, y: 402 - 448 },
    },
    Wheel: {
      Base: { x: 623, y: 453 },
      ResultText: { x: 623, y: 453 },
    },
    Sign: {
      Base: { x: 109, y: 484 },
      TextNice: { x: 107, y: 70 + 330 },
      TextMiss: { x: 107, y: 237 + 330 },
    },
    Meter: {
      landscape: {
        Container: { x: 600, y: -100 },
        base: { x: 20, y: 71 },
        Title: { x: -351, y: 0 },
        Rank: { x: -125, y: 0 },
        Name: { x: 129, y: 0 },
      },
      Portrait: {
        Container: { x: 600, y: -200 },
        base: { x: -1, y: 143 },
        Title: { x: -392, y: 0 },
        Rank: { x: -134, y: 0 },
        Name: { x: 150, y: 0 },
      },
    },
  },
  GambleSelect: {
    common: {
      base: { x: 0, y: 0 },
      iconL: { x: -305, y: 23 },
      iconR: { x: 306, y: 22 },
      textL: { x: -311, y: -310 },
      textR: { x: 311, y: -310 },
      gambleBtnArea: { x: -1192 / 2, y: -728 / 2, w: 1192 / 2, h: 728 },
      collectBtnArea: { x: 0, y: -728 / 2, w: 1192 / 2, h: 728 },
    },
    portrait: {
      container: { x: 460, y: 338 },
      containerScale: 1192 / 1192,
      title: { x: 450, y: -149 },
      description: { x: 460, y: 772 },
      cancelBtn: { x: 239, y: 791 },
      okBtn: { x: 700, y: 791 },
    },
    landscape: {
      container: { x: 427, y: 346 },
      title: { x: 428.5, y: -22 },
      description: { x: 456.5, y: 725 },
      containerScale: 1084 / 1192,
      cancelBtn: { x: 228, y: 723 },
      okBtn: { x: 647, y: 723 },
    },
    iconScale: 605 / 1210,
  },
  Gamble: {
    gambleStartBtnArea: { x: -4000 / 2, y: -4000 / 2, w: 4000, h: 4000 },
    landscape: {
      Sign: { x: -15, y: 612 },
      rod: { x: -141, y: 806 },
      meter: { x: 831, y: 358 },
      effect: { x: 372, y: 400 },
      textStart: { x: 370, y: 391 },
      miss: { x: 401, y: 413 },
    },
    portrait: {
      Sign: { x: -9, y: 442 },
      rod: { x: 352, y: 648 },
      meter: { x: 919, y: 334 },
      effect: { x: 439, y: 297 },
      textStart: { x: -91, y: 246 },
      miss: { x: 462, y: 339 },
    },
  },
  Scaling: {
    landscape: {
      base: { x: 400, y: 474 },
      rankTitle: { x: 44, y: -5 },
      rank: { x: 207, y: -5 },
      name: { x: 567, y: -5 },
      multiplier: { x: 349, y: 101 },
      winMessage: { x: 346, y: 193 },
      winValueText: { x: 396, y: 810 },
      winMultiplier: { x: 348, y: 3 },
      winMessageScale: 600 / 1000,
    },
    portrait: {
      base: { x: 460, y: 630 },
      rankTitle: { x: 107, y: -245 },
      rank: { x: 263, y: -245 },
      name: { x: 623, y: -245 },
      multiplier: { x: 451, y: 46 },
      winMessage: { x: 349, y: 101 },
      winValueText: { x: 349, y: 101 },
      winMultiplier: { x: 0, y: 0 },
      winMessageScale: 1,
    },
  },
};

export const fishingChallengeSignTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 48,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFB800',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 4,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowDistance: Math.sqrt(4 * 4 + 0 * 0),
  dropShadowAlpha: 0.7,
  maxWidth: 120,
  maxHeight: 72,
};

export const fishingChallengeResultTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 72,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFFFFF',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 4,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowDistance: Math.sqrt(4 * 4 + 0 * 0),
  dropShadowAlpha: 0.7,
  maxWidth: 800,
  maxHeight: 200,
};

export const fishingChallengeMeterTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 34,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFFFFF',
  align: 'left',
  maxWidth: 210,
  maxHeight: 46,
};

export const fishingChallengeMeterPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 45,
  lineJoin: 'round',
  fontWeight: 'bold',
  fill: '#FFFFFF',
  align: 'left',
  maxWidth: 270,
  maxHeight: 62,
};

export const gambleSelectTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 59,
  fill: ['#FF0000', '#FF4D19', '#FFED4D'],
  fillGradientStops: [0, 0.46, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4.5, 0),
  dropShadowBlur: 3.6,
  dropShadowDistance: Math.sqrt(4.5 * 4.5),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const gambleSelectTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 900,
  fontSize: 62,
  fill: '#FFAB00',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const gambleSelectDescriptionTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1110,
  fontSize: 32,
  fill: '#FFFFFF',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 4,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.25,
};

export const fishingGambleTitleTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1084,
  fontSize: 122,
  fontWeight: 'bold',
  fill: ['#FF0000', '#F9B300'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#000000',
  strokeThickness: 10,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(15, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(15 * 15),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const fishingGambleDistanceTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1084,
  fontSize: 183,
  fontWeight: 'bold',
  fill: ['#C87800', '#E7B600', '#E0CA00'],
  fillGradientStops: [0, 0.56, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#000000',
  strokeThickness: 4,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(5, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(5 * 5),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const fishingScalingRankTitlePortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 223,
  fontSize: 80,
  fontWeight: 'bold',
  fill: '#FF0606',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
};

export const fishingScalingRankTitleLandScapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingRankTitlePortraitTextStyles,
  maxWidth: 200,
  fontSize: 70,
};

export const fishingScalingRankPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 600,
  fontSize: 90,
  fontWeight: 'bold',
  fill: '#FF9A03',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
};

export const fishingScalingRankLandScapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingRankPortraitTextStyles,
  maxWidth: 500,
  fontSize: 80,
};

export const fishingScalingFishNamePortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 600,
  fontSize: 80,
  fontWeight: 'bold',
  fill: '#FF0000',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
};

export const fishingScalingFishNameLandScapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingFishNamePortraitTextStyles,
  maxWidth: 500,
  fontSize: 70,
};
export const fishingScalingMultiplierPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 200,
  fontWeight: 'bold',
  fill: '#FF0505',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
};

export const fishingScalingMultiplierLandScapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingMultiplierPortraitTextStyles,
  maxWidth: 500,
  fontSize: 98,
};

export const fishingScalingWinMultiplierPortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 98,
  fontWeight: 'bold',
  fill: '#FF0505',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const fishingScalingWinMultiplierLandscapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingWinMultiplierPortraitTextStyles,
  maxWidth: 1200,
  fontSize: 200,
  strokeThickness: 14,
};
export const fishingScalingWinValuePortraitTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 1200,
  fontSize: 127,
  fontWeight: 'bold',
  fill: ['#FF5C00', '#FFE606'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const fishingScalingWinValueLandscapeTextStyles: Partial<AutoResizeTextStyle> = {
  ...fishingScalingWinValuePortraitTextStyles,
  maxWidth: 1100,
  fontSize: 226,
  strokeThickness: 16,
};
