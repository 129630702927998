import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { SlotId } from '../../config';
import { setCoinAmount, setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import {
  EventTypes,
  SLOT_RESOURCE_HEIGHT,
  SLOT_RESOURCE_WIDTH,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private currency = '';

  public iconId: SlotId;

  public combos: Combos = [];

  public multipliers: PIXI.Text;

  public multipliersValue: PIXI.Text;

  public wrap: PIXI.Container;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();

    this.id = id;
    this.x = 0;
    this.y = SLOT_RESOURCE_HEIGHT / 2;
    this.iconId = icon.id;
    this.visible = false;

    //const bg = this.initBg();
    //this.addChild(bg);
    this.wrap = new PIXI.Container();
    this.addChild(this.wrap);
    this.multipliers = this.initMultipliers();
    this.multipliersValue = this.initMultipliersValue();
    this.wrap.addChild(this.multipliers);
    this.wrap.addChild(this.multipliersValue);
    this.currency = setCurrency();
    this.setPayTableData(icon, combos);
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => this.showPayTable(i));
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, this.handleHide);
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.handleHide);
    eventManager.addListener(EventTypes.HANDLE_CHANGE_BET_AMOUNT, () => this.handleChangeBetAmount());
  };

  private handleHide = (): void => {
    this.visible = false;
  };

  /*  private initBg = (): TickerSpine<'minipaytable'> => {
    const bg = new TickerSpine<'minipaytable'>('minipaytable');
    //bg.anchor.set(0, 0.5);
    bg.update(0);
    bg.pivot.x = -180;
    bg.state.setAnimation(0, 'base');
    bg.x = 0;
    bg.width = REEL_WIDTH + 40;
    bg.height = SLOT_RESOURCE_HEIGHT;
    return bg;
  };
*/
  private initMultipliers = (): PIXI.Text => {
    const multipliers = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableNumberStyle,
    });
    if (PIXI.utils.isMobile) {
      multipliers.resolution = 1;
    }
    multipliers.anchor.set(0, 0);
    return multipliers;
  };

  private initMultipliersValue = (): PIXI.Text => {
    const multipliersValue = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableTextStyle,
    });
    if (PIXI.utils.isMobile.phone) {
      multipliersValue.resolution = 1;
    }
    multipliersValue.anchor.set(0, 0);
    return multipliersValue;
  };

  private calcMultiplier(multiplier: number): number {
    //TO DO
    const betMultiplier = this.iconId === SlotId.SC1 ? multiplier * 25 : multiplier;
    return normalizeCoins(setCoinAmount() * betMultiplier);
  }

  private getCombosNumbers(): string {
    return this.combos.reduce((acc: string, curr: IconCombo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${formatNumber({
            currency: this.currency,
            value: this.calcMultiplier(curr.multiplier),
            showCurrency: showCurrency(this.currency),
          })}\n`,
        '',
      ) || ''
    );
  }

  private handleChangeBetAmount(): void {
    this.multipliersValue.text = this.getCombos();
    this.updatePayTableData();
  }

  public setPayTableData(icon: Icon, combos: Combos): void {
    this.iconId = icon.id;
    this.combos = combos;
    //    this.combos = getCombosDataFromIcon(icon);
    this.multipliers.text = this.getCombosNumbers();

    this.multipliersValue.text = this.getCombos();
    this.multipliersValue.x = this.multipliers.x + this.multipliers.width + 6;

    this.wrap.x = SLOT_RESOURCE_WIDTH / 2 - this.wrap.width / 2;
    this.wrap.y = -this.wrap.height / 2;
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!this.combos?.length) return;
    if (this.iconId === SlotId.SC1) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private updatePayTableData(): void {
    this.wrap.x = SLOT_RESOURCE_WIDTH / 2 - this.wrap.width / 2;
    this.wrap.y = -this.wrap.height / 2;
  }
}

export default MiniPayTable;
