import { getFragmentData } from '../gql';
import { IWinCombination } from '../gql/d';
import { betFragment, outcomeFragment, wagerFragment } from '../gql/fragment';
import {
  IBetFragment,
  IOutcomeFragment,
  IPredicament,
  ISettledBet,
  IWagerFragment,
  IWagerSettings,
  IWagerStorage,
} from '../types';

import { getNonNullableValue } from './helper';

export const getBetData = (nextResult: ISettledBet) => {
  return getFragmentData(betFragment, nextResult.bet);
};

export const getOutcomeFromBet = (bet: IBetFragment): IOutcomeFragment | undefined => {
  const outcomes = getNonNullableValue(getFragmentData(outcomeFragment, bet.outcomes));
  return outcomes.length > 0 ? (outcomes[0] as unknown as IOutcomeFragment) : undefined;
};

export const getReelPositions = (nextResult: ISettledBet): number[] => {
  const bet = getBetData(nextResult!);
  const outcome = getOutcomeFromBet(bet);
  const predicament = outcome && outcome?.predicament;
  const reelPositions = predicament ? (predicament.reelPositions as number[]) : [0, 0, 0, 0];

  return reelPositions;
};

export const getWinCombinations = (nextResult: ISettledBet): IWinCombination[] | undefined => {
  const bet = getBetData(nextResult!);
  const outcome = getOutcomeFromBet(bet);

  const stateSnapshot = outcome && outcome.stateSnapshot;
  return stateSnapshot?.winCombinations as IWinCombination[];
};

export const getWagerSettings = (nextResult: ISettledBet): IWagerSettings | undefined => {
  const bet = getBetData(nextResult!);
  const wager = getFragmentData(wagerFragment, bet.wager)!;
  return getWagerSettingsFromWager(wager);
};

export const getWagerSettingsFromWager = (wager: IWagerFragment): IWagerSettings | undefined => {
  return wager.wagerSettings && wager.wagerSettings.__typename === 'BillFishRichesWagerSettings'
    ? getNonNullableValue(wager.wagerSettings)
    : undefined;
};

export const getWagerStorageFromWager = (wager: IWagerFragment): IWagerStorage | undefined => {
  return wager.wagerStorage && wager.wagerStorage.__typename === 'CommonWagerStorage'
    ? getNonNullableValue(wager.wagerStorage)
    : undefined;
};

export const getOutcomePredicament = (nextResult: ISettledBet): IPredicament | undefined => {
  const bet = getBetData(nextResult!);
  const outcome = getOutcomeFromBet(bet);

  return outcome && outcome.predicament && outcome.predicament.__typename === 'BillFishRichesPredicament'
    ? getNonNullableValue(outcome.predicament)
    : undefined;
};

export const getBonusOutcomePredicament = (nextResult: ISettledBet): IPredicament | undefined => {
  const bet = getBetData(nextResult!);
  const outcomes = getNonNullableValue(getFragmentData(outcomeFragment, bet.outcomes));

  const bonusOutcome = outcomes.find((outcome) => {
    return outcome.rewards.find((rewards) => rewards.type === 'BONUS');
  });

  if (bonusOutcome) {
    return bonusOutcome.predicament;
  }

  return undefined;
};
