import { Bone, Event, TrackEntry } from '@pixi-spine/all-4.1';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setFishingResult, setIsWheelSpinning } from '../../gql/cache';
import i18n from '../../i18next';
import { isFishingChallengeMode, transitionToBaseGame } from '../../utils';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingChallenge } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout, fishingChallengeResultTextStyles, fishingChallengeSignTextStyles } from './config';
import FishMeter from './fishMeter';

class FishingChallenge extends ViewContainer {
  private fishRank: FishKind;
  private sign: TickerSpine<'fc_wheel'>;
  private wheel: TickerSpine<'fc_wheel'>;

  private textResultNice: AutoResizeText;
  private textResultMiss: AutoResizeText;

  private textSignNice: AutoResizeText;
  private textSignMiss: AutoResizeText;

  private wheelBone: Bone;

  private meter: FishMeter;

  private targetPos: number;

  constructor() {
    super();

    this.fishRank = 'Rank1';
    this.targetPos = 0;

    this.sign = new TickerSpine('fc_wheel');
    this.sign.position.copyFrom(FishingLayout.Challenge.Sign.Base);
    this.sign.state.setAnimation(0, 'sgin', true);

    this.wheel = new TickerSpine('fc_wheel');
    this.wheel.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.wheel.state.setAnimation(0, 'pnl_wait', true);

    this.textResultNice = new AutoResizeText(i18n.t('fishing.challenge.result.nice'), fishingChallengeResultTextStyles);
    this.textResultNice.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.textResultNice.anchor.set(0.5);
    this.textResultNice.visible = false;

    this.textResultMiss = new AutoResizeText(i18n.t('fishing.challenge.result.miss'), fishingChallengeResultTextStyles);
    this.textResultMiss.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.textResultMiss.anchor.set(0.5);
    this.textResultMiss.visible = false;

    this.textSignNice = new AutoResizeText(i18n.t('fishing.challenge.sign.nice'), fishingChallengeSignTextStyles);
    this.textSignNice.position.copyFrom(FishingLayout.Challenge.Sign.TextNice);
    this.textSignNice.anchor.set(0.5);

    this.textSignMiss = new AutoResizeText(i18n.t('fishing.challenge.sign.miss'), fishingChallengeSignTextStyles);

    this.textSignMiss.position.copyFrom(FishingLayout.Challenge.Sign.TextMiss);
    this.textSignMiss.anchor.set(0.5);

    this.meter = new FishMeter();

    this.addChild(
      this.sign,
      this.wheel,
      this.textSignMiss,
      this.textSignNice,
      this.meter,
      this.textResultNice,
      this.textResultMiss,
    );
    this.wheelBone = this.wheel.skeleton.findBone('prg_rot');

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_WHEEL_SPIN_START, this.startSpin.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));

    this.parentLayer = layerFishingChallenge;

    //const rot0 = this.wheelBone.rotation;
    this.wheel.state.addListener({
      start(entry) {
        if (entry.animation?.name === 'pnl_rot') {
          //AudioApi.play({ type: ISongs.XT002S_Wheel_Rotate_com });
        }
      },
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'pnl_rot') {
          this.endSpin();
        } else if (entry.animation?.name === 'start') {
          console.log();
        }
      },
      event: (_entry: TrackEntry, event: Event) => {
        const WHEEL_PRIZE_CNT_MAX = 12;
        const WHEEL_ONE_PRIZE_ROT = -360 / WHEEL_PRIZE_CNT_MAX;

        if (this.wheelBone.rotation != null && event.data.name === 'changeRot') {
          const destPos = (WHEEL_PRIZE_CNT_MAX - this.targetPos) % WHEEL_PRIZE_CNT_MAX;
          this.wheelBone.rotation = destPos * WHEEL_ONE_PRIZE_ROT;

          console.log('target:', this.targetPos);
          console.log('dest:', destPos);
          console.log('rotation:', this.wheelBone.rotation);
        }
      },
    });

    this.visible = false;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    const fishRankToSkins: Record<FishKind, SpineInterface['fc_wheel']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    if (isFishingChallengeMode(settings.mode)) {
      this.fishRank = settings.fishKind ?? 'Rank1';
      const skin = fishRankToSkins[this.fishRank];
      this.wheel.skeleton.setSkinByName(skin);
      this.wheel.state.addAnimation(0, 'pnl_wait', true);
      this.wheel.update(0);
      this.visible = true;

      this.targetPos = 0;
    } else {
      this.visible = false;
      this.wheel.state.addAnimation(0, 'pnl_wait', true);
    }
  }

  private startSpin() {
    setIsWheelSpinning(true);
    this.targetPos = setFishingResult()?.fishingChallengePosition!;
    this.wheel.state.setAnimation(0, 'pnl_rot', false);

    this.textResultNice.visible = false;
    this.textResultMiss.visible = false;
  }

  private endSpin() {
    eventManager.emit(EventTypes.FISHING_WHEEL_SPIN_STOP, setFishingResult()?.fishingChallengeResult ? true : false);
    const wheelCount = setFishingResult() ? setFishingResult()?.fishingChallengeWheelCnt ?? 0 : 0;
    const delayDuration = setFishingResult()?.fishingChallengeResult === true && wheelCount >= 3 ? 500 : 1000;
    const delay = Tween.createDelayAnimation(delayDuration);

    if (setFishingResult()?.fishingChallengeResult === true) {
      this.textResultNice.visible = true;
      this.textResultMiss.visible = false;
    } else {
      this.textResultNice.visible = false;
      this.textResultMiss.visible = true;
    }

    this.wheel.state.addAnimation(0, 'pnl_wait', true);
    delay.addOnComplete(() => {
      this.textResultNice.visible = false;
      this.textResultMiss.visible = false;

      if (setFishingResult()?.fishingChallengeResult === true && wheelCount >= 3) {
        this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishRank);
      } else if (setFishingResult()?.fishingChallengeResult === true) {
        //disp text nice
        this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.COUNT_UP_END); //
        setIsWheelSpinning(false);

        if (wheelCount === 1) {
          BgmControl.playBgm('freeSpin02', true, true);
        } else if (wheelCount === 2) {
          BgmControl.playBgm('freeSpin03', true, true);
        }
      } else {
        //disp text miss

        //fade black
        transitionToBaseGame(() => {
          this.wheelBone.rotation = 0;
          setIsWheelSpinning(false);
        });

        /*
        eventManager.emit(EventTypes.TRANSITION_START, {
          type: 'Fade',
          fadeOutDuration: 500,
          fadeInDuration: 500,
          tint: 0x000000,
          callback: () => {
            eventManager.emit(EventTypes.CHANGE_MODE, {
              mode: GameMode.BASE_GAME,
              reelSetId: setDefaultReelSetId(),
              reelPositions: setLastBaseReelPositions(),
              bgType: 'default',
            });
            this.wheelBone.rotation = 0;
          },
        });
        */
      }
    });
    delay.start();
  }

  private onEndGotcha() {
    this.visible = false;
    setIsWheelSpinning(false);
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = FishingLayout.Challenge.Container.Landscape.x;
      this.y = FishingLayout.Challenge.Container.Landscape.y;
      this.scale.set(FishingLayout.Challenge.Scale);
      this.wheel.visible = true;
    } else {
      this.x = FishingLayout.Challenge.Container.PortRait.x;
      this.y = FishingLayout.Challenge.Container.PortRait.y;
      this.wheel.visible = true;
      this.scale.set(1);
    }
  };
}

export default FishingChallenge;
