export enum BonusKind {
  FREE_SPINS = 'FreeSpins',
  BUY_FEATURE = 'BuyFeature',
}

export type FreespinBonusInfo = {
  bonusKind: BonusKind.FREE_SPINS;
};

export type BuyFeatureBonusInfo = {
  bonusKind: BonusKind.BUY_FEATURE;
};

//TO DO
export const BonusDataByBonusId: Record<string, FreespinBonusInfo | BuyFeatureBonusInfo> = {
  'f43bb43d-79d3-4d49-aad7-b395a1e4fc2a': {
    bonusKind: BonusKind.FREE_SPINS,
  },
  '2322f0dd-ed02-4b7c-a884-da67fbfd96e4': {
    bonusKind: BonusKind.BUY_FEATURE,
  },
};
