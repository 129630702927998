import { SlotId } from '../config';
import { REELS_AMOUNT, SLOTS_PER_REEL_AMOUNT } from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

/**
 * ⚠️CAUTION⚠️
 * This function ignores null checks and is unsafe
 * @param value Nullable value
 * @returns value typecast to NonNullable
 */
export function getNonNullableValue<T>(value: T): RecursiveNonNullable<T> {
  return value as RecursiveNonNullable<T>;
}

export const transposeMatrix = <T>(matrix: T[][]): T[][] => {
  return matrix[0]!.map((_, i) => matrix.map((row) => row[i]!));
};

export const urlSearchParams = new URLSearchParams(window.location.search);

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const getCssVariable = (cssVar: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const transposeFlatIconToSlotIdArray = (flatArray: Icon[]): SlotId[][] => {
  if (flatArray.length !== SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT) {
    throw new Error('Array must have exactly 15 elements.');
  }
  return Array.from({ length: REELS_AMOUNT }).map((_, i) =>
    Array.from({ length: SLOTS_PER_REEL_AMOUNT }, (_, j) => flatArray[j * REELS_AMOUNT + i]?.id!),
  );
};

export const queryParams = new URLSearchParams(window.location.search);
