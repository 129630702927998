import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, RangeSlider } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { setBetAmount, setCoinAmount, setCoinValue, setSlotConfig, setUserBalance } from '../../gql/cache';
import { getBetsSetting } from '../../gql/fromFragment';
import { getBetAmountGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { countCoins, normalizeCoins, showCurrency, wrap } from '../../utils';

import styles from './betSettings.module.scss';
import { IBetSettingsProps } from './d';

const BetSettings: React.FC<IBetSettingsProps> = (props) => {
  const { className = '' } = props;
  const { data } = useQuery(getBetAmountGql);
  const betSettings = getBetsSetting();
  const { t } = useTranslation();
  const value = data?.betAmount ?? betSettings.minBet;
  const coinMultiplier = setSlotConfig().lineSet.coinAmountMultiplier;
  const userBalance = useReactiveVar(setUserBalance);

  const changeBet = (v: number) => {
    if (betSettings.maxBet === v) {
      AudioApi.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
    setCoinAmount(v);
    setBetAmount(v * coinMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
  };

  const changeSliderBet = (v: number) => {
    if (betSettings.maxBet === betSettings.bets[v - 1]) {
      AudioApi.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
    setCoinAmount(betSettings.bets[v - 1]);
    setBetAmount(coinMultiplier * (betSettings.bets[v - 1] || 0));
    eventManager.emit(EventTypes.UPDATE_BET);
  };

  return (
    <div className={`${styles['bet-settings']} ${className}`}>
      <div className={styles.wrap}>
        <div className={styles.currency}>{userBalance.currency}</div>
        <div className={styles['fast-bet']}>
          {betSettings.quick.map((item) => (
            <div key={item} className={styles['fast-bet__item']}>
              <Button
                intent="betSettingsBtn"
                className={styles.btn}
                isActive={value === item * coinMultiplier}
                title={formatNumber({
                  currency: userBalance.currency,
                  value: countCoins({
                    coinAmount: item,
                    coinValue: setCoinValue(),
                    lines: coinMultiplier,
                  }),
                })}
                onClick={wrap(changeBet, item)}
              />
            </div>
          ))}
        </div>
        <div className={styles.amount}>
          {t('betSettingsSetManually')}{' '}
          <span className={styles.amount_value}>
            {formatNumber({
              currency: userBalance.currency,
              value: normalizeCoins(countCoins({ totalAmount: data?.betAmount! })),
              showCurrency: showCurrency(userBalance.currency),
            })}
          </span>
        </div>
        <RangeSlider
          className={`range-slider ${styles['range-slider']}`}
          min={1}
          max={betSettings.bets.length}
          value={betSettings.bets.findIndex((bet) => bet === value / coinMultiplier) + 1}
          onChange={changeSliderBet}
        />
      </div>
    </div>
  );
};

export default BetSettings;
