import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT004S_SC1_longspin_long: 'XT004S_SC1_longspin_long',
  XT004S_SC1_longspin_middle: 'XT004S_SC1_longspin_middle',
  XT004S_SC1_longspin_short: 'XT004S_SC1_longspin_short',
  XT004S_SC1_stop: 'XT004S_SC1_stop',
  XT004S_SC1_triple_stop: 'XT004S_SC1_triple_stop',
  XT004S_SC2_longspin_long: 'XT004S_SC2_longspin_long',
  XT004S_SC2_longspin_middle: 'XT004S_SC2_longspin_middle',
  XT004S_SC2_longspin_short: 'XT004S_SC2_longspin_short',
  XT004S_SC2_stop1: 'XT004S_SC2_stop1',
  XT004S_SC2_stop2: 'XT004S_SC2_stop2',
  XT004S_SC2_stop3: 'XT004S_SC2_stop3',
  XT004S_additional_spin_feature1: 'XT004S_additional_spin_feature1',
  XT004S_additional_spin_feature2: 'XT004S_additional_spin_feature2',
  XT004S_additional_spin_feature3: 'XT004S_additional_spin_feature3',
  XT004S_addtional_spin: 'XT004S_addtional_spin',
  XT004S_anticipation_high: 'XT004S_anticipation_high',
  XT004S_anticipation_low: 'XT004S_anticipation_low',
  XT004S_bigwin_1shot: 'XT004S_bigwin_1shot',
  XT004S_feature_end_high: 'XT004S_feature_end_high',
  XT004S_feature_end_low: 'XT004S_feature_end_low',
  XT004S_freeze: 'XT004S_freeze',
  XT004S_freeze_break: 'XT004S_freeze_break',
  XT004S_longspin: 'XT004S_longspin',
  XT004S_phoenix_sign: 'XT004S_phoenix_sign',
  XT004S_push_buy_feature: 'XT004S_push_buy_feature',
  XT004S_respin: 'XT004S_respin',
  XT004S_snow_add_1: 'XT004S_snow_add_1',
  XT004S_snow_add_2: 'XT004S_snow_add_2',
  XT004S_snow_add_3: 'XT004S_snow_add_3',
  XT004S_snow_add_4: 'XT004S_snow_add_4',
  XT004S_snow_add_5: 'XT004S_snow_add_5',
  XT004S_snow_add_6: 'XT004S_snow_add_6',
  XT004S_snow_add_7: 'XT004S_snow_add_7',
  XT004S_snow_add_8: 'XT004S_snow_add_8',
  XT004S_snow_max: 'XT004S_snow_max',
  XT004S_snow_particle: 'XT004S_snow_particle',
  XT004S_spin_loop: 'XT004S_spin_loop',
  XT004S_spin_start: 'XT004S_spin_start',
  XT004S_spin_stop1: 'XT004S_spin_stop1',
  XT004S_spin_stop2: 'XT004S_spin_stop2',
  XT004S_spin_stop3: 'XT004S_spin_stop3',
  XT004S_spin_stop4: 'XT004S_spin_stop4',
  XT004S_spin_stop5: 'XT004S_spin_stop5',
  XT004S_win_big: 'XT004S_win_big',
  XT004S_win_end: 'XT004S_win_end',
  XT004S_win_epic: 'XT004S_win_epic',
  XT004S_win_great: 'XT004S_win_great',
  XT004S_win_loop: 'XT004S_win_loop',
  XT004S_win_mega: 'XT004S_win_mega',
  XT005S_BG_IT002: 'XT005S_BG_IT002',
  XT005S_bg_base_loop: 'XT005S_bg_base_loop',
  XT005S_bg_challenge1_loop: 'XT005S_bg_challenge1_loop',
  XT005S_bg_challenge2_loop: 'XT005S_bg_challenge2_loop',
  XT005S_bg_challenge3_loop: 'XT005S_bg_challenge3_loop',
  XT005S_feature_trigger: 'XT005S_feature_trigger',
  kari_wave_bgm: 'kari_wave_bgm',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT004S_SC1_longspin_long: [14000, 3301.020408163264],
  XT004S_SC1_longspin_middle: [19000, 2766.6893424036266],
  XT004S_SC1_longspin_short: [23000, 2266.6666666666656],
  XT004S_SC1_stop: [27000, 1666.6666666666679],
  XT004S_SC1_triple_stop: [30000, 2433.33333333333],
  XT004S_SC2_longspin_long: [34000, 7766.666666666666],
  XT004S_SC2_longspin_middle: [43000, 6066.666666666662],
  XT004S_SC2_longspin_short: [51000, 4100.000000000002],
  XT004S_SC2_stop1: [57000, 3166.689342403629],
  XT004S_SC2_stop2: [62000, 2366.66666666666],
  XT004S_SC2_stop3: [66000, 2366.66666666666],
  XT004S_additional_spin_feature1: [70000, 2714.353741496595],
  XT004S_additional_spin_feature2: [74000, 2714.353741496595],
  XT004S_additional_spin_feature3: [78000, 6142.925170068025],
  XT004S_addtional_spin: [86000, 5261.90476190476],
  XT004S_anticipation_high: [93000, 3633.33333333334],
  XT004S_anticipation_low: [98000, 4000],
  XT004S_bigwin_1shot: [103000, 3428.6167800453454],
  XT004S_feature_end_high: [108000, 7295.623582766439],
  XT004S_feature_end_low: [117000, 6857.165532879819],
  XT004S_freeze: [125000, 2366.6893424036316],
  XT004S_freeze_break: [129000, 1433.3560090703088],
  XT004S_longspin: [132000, 4000.0453514739147],
  XT004S_phoenix_sign: [138000, 4033.333333333331],
  XT004S_push_buy_feature: [144000, 366.66666666667425],
  XT004S_respin: [146000, 1763.0158730158598],
  XT004S_snow_add_1: [149000, 2166.666666666657],
  XT004S_snow_add_2: [153000, 2166.689342403629],
  XT004S_snow_add_3: [157000, 2166.666666666657],
  XT004S_snow_add_4: [161000, 2166.666666666657],
  XT004S_snow_add_5: [165000, 2166.666666666657],
  XT004S_snow_add_6: [169000, 2166.666666666657],
  XT004S_snow_add_7: [173000, 2166.666666666657],
  XT004S_snow_add_8: [177000, 2166.666666666657],
  XT004S_snow_max: [181000, 3366.6893424036175],
  XT004S_snow_particle: [186000, 1966.6666666666686],
  XT004S_spin_loop: [189000, 3614.807256235821, true],
  XT004S_spin_start: [194000, 1333.356009070286],
  XT004S_spin_stop1: [197000, 766.6666666666799],
  XT004S_spin_stop2: [199000, 833.3333333333428],
  XT004S_spin_stop3: [201000, 733.3333333333201],
  XT004S_spin_stop4: [203000, 766.6666666666799],
  XT004S_spin_stop5: [205000, 733.3333333333201],
  XT004S_win_big: [207000, 1800.068027210898],
  XT004S_win_end: [210000, 1000],
  XT004S_win_epic: [212000, 3000.0453514739147],
  XT004S_win_great: [217000, 1800.068027210898],
  XT004S_win_loop: [220000, 1000, true],
  XT004S_win_mega: [222000, 1800.068027210898],
  XT005S_BG_IT002: [225000, 11162.789115646263],
  XT005S_bg_base_loop: [238000, 79285.71428571428, true],
  XT005S_bg_challenge1_loop: [319000, 10666.689342403628, true],
  XT005S_bg_challenge2_loop: [331000, 10666.689342403628, true],
  XT005S_bg_challenge3_loop: [343000, 10666.689342403628, true],
  XT005S_feature_trigger: [355000, 6000],
  kari_wave_bgm: [362000, 109290.77097505672],
};
