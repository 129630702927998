import { gql } from './__generated__';

//done
export const getBalanceGql = gql(`
  query GetBalance {
    balance {
      currency
      amount
    }
  }
`);

//done kari
export const getRestoreStateGql = gql(`
  query getRestoreState($input: RestoreStateInput!) {
    restoreState(input: $input) {
      bets {
        ... bet
      }
      wagers {
        ... wager
      }
    }
  }
`);

//done kari
export const slotHistoryGql = gql(`
  query slotHistory($input: BetHistoryInput!) {
    betHistory(input: $input) {
      bets {
      ... bet
      }
      count
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
`);

export const getNextOutcomesGql = gql(`
  query getNextOutcomes($input: NextOutcomesInput!) {
    nextOutcomes(input: $input) {
      bet {
      ... bet
      }
    }
  }
`);

export const getGameSettingsGql = gql(`
  query gameSettings($input: GameSettingsInput!) {
    gameSettings(input: $input) {
      slotId
      clientSlotSettings {
        betCoinAmountSettings
        quickBetCoinAmountSettings
        coinValueSettings {
          code
          variants
        }
        autoplaySettings {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
        wagerExpirationDurationSeconds
        realBetCoinAmounts
      }
      slotSettings {
        ... on TumblingReelSlotSettings {
          cols
          rows
          startingPositions
          betLines {
            min
            max
          }
          globalCoinAmountMultiplier
          buyFeaturePackages {
            packageId
            coinAmountMultiplier
            rounds
          }
        }
      }
    }
  }
`);

export const configGql = gql(`
  query Config {
    isTurboSpin @client
    isSoundOn @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
    isOpenHistoryPopup @client
  }
`);

export const getBetAmountGql = gql(`
  query GetBet {
    betAmount @client
  }
`);

export const getWinAmountGql = gql(`
  query GetWin {
    winAmount @client
  }
`);

export const getAutoSpinsSettingsGql = gql(`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`);

export const getAutoSpinsGql = gql(`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`);

export const isStoppedGql = gql(`
  query IsStopped {
    isSlotStopped @client
  }
`);
