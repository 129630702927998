import * as PIXI from 'pixi.js';

import { REEL_LINE_Z_ORDER } from '../components/layers/config';
import { layerReel } from '../components/layers/layers';
import { EventTypes, eventManager } from '../config';
import { IWinLine } from '../d';

class LinesContainer extends PIXI.Container {
  public linesData: number[][];

  //public line: TickerSpine<'win_line'>;

  constructor(linesData: number[][]) {
    super();

    this.linesData = linesData;
    //this.line = new TickerSpine<'win_line'>('win_line');
    //this.line.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    //this.addChild(this.line);

    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.showLines.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.hideLines.bind(this));

    this.parentLayer = layerReel;
    this.zOrder = REEL_LINE_Z_ORDER;
  }

  public showLines(lines: IWinLine[]): void {
    lines.forEach((line) => {
      if (line.lineNumber !== null) this.setLineVisibility(line.lineNumber, true);
    });
  }

  public hideLines(lines: IWinLine[]): void {
    if (!lines) {
      for (let i = 0; i < this.linesData.length; i++) {
        this.setLineVisibility(i, false);
      }
    } else {
      lines.forEach((line) => {
        if (line.lineNumber !== null) this.setLineVisibility(line.lineNumber, false);
      });
    }
  }

  public setLineVisibility(_id: number, visibility: boolean): void {
    //this.line.visible = visibility;

    if (visibility) {
      // const lineAnimationName =
      //   id <= 8
      //     ? (`pos_0${id + 1}` as SpineInterface['win_line']['animations'])
      //     : (`pos_${id + 1}` as SpineInterface['win_line']['animations']);
      // this.line.state.setAnimation(id, lineAnimationName, true);
    } else {
      //this.line.state.setEmptyAnimation(id);
    }
  }
}

export default LinesContainer;
