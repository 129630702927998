export enum GameMode {
  BASE_GAME = 'BASE_GAME',
  FISH_CHALLENGE = 'FISH_CHALLENGE',
  FISH_GAMBLE = 'FISH_GAMBLE',
  FISH_GAMBLE_SELECT = 'FISH_GAMBLE_SELECT',
  SCALING = 'SCALING',
  FREE_SPINS = 'FREE_SPINS',
  BUY_FEATURE = 'BUY_FEATURE',
  BUY_FEATURE_SP = 'BUY_FEATURE_SP',
}

export enum PopupOpeningTypes {
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null as unknown as number,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',

  TURBO_SPIN = 'TURBO_SPIN',
}

export enum BonusStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

//BE
export enum FeatureTypes {
  BUY_FEATURE_FREE_SPINS_NORMAL = 'BUY_FEATURE_FREE_SPINS_NORMAL',
  BUY_FEATURE_FREE_SPINS_SPECIAL = 'BUY_FEATURE_FREE_SPINS_SPECIAL',
  FREE_SPIN = 'FREE_SPIN',
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}
