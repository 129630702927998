import { Loader } from 'pixi.js';

import { SlotId } from '../../config';
import { GameMode } from '../../consts';
import { AnticipationInfo, AnticipationReelType } from '../../global';
import { setForceReelStop, setIsRevokeThrowingError } from '../../gql/cache';
import { ISettledBet } from '../../types';
import { getNonNullableValue, isScatter } from '../../utils';
import { getWinCombinations } from '../../utils/fragments';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { AnnounceType } from '../announce/config';
import ViewContainer from '../components/container';
import {
  REELS_Z_ORDER_HIGHLIGHT_SLOT,
  REELS_Z_ORDER_STOP_SLOTS,
  REELS_Z_ORDER_STOP_SLOTS_SC,
  REELS_Z_ORDER_WIN_SLOTS,
  REELS_Z_ORDER_WIN_SLOTS_SC,
} from '../components/layers/config';
import { layerReel } from '../components/layers/layers';
import {
  EventTypes,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SLOT_RESOURCE_WIDTH,
  WIN_ANIMATION_INTERVAL,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';
import { IAnimateSlot, animateSlotFactory } from '../slot/animateSlot';

export class SlotsAnimationContainer extends ViewContainer {
  private slotSymbols: IAnimateSlot[] = [];

  constructor() {
    super();

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupSymbols.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(
      EventTypes.ANTICIPATION_STOPPED_SLOT_ANIMATIONS_START,
      this.onAnticipationAnimationStarts.bind(this),
    );
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_BONUS_ANIMATIONS_END, this.onAnticipationEndOnlySC.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult) => {
      this.initSymbols(spinResult);
      this.slotSymbols.forEach((slot) => (slot.visible = true));
    });
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.onStartFSWinAnimation.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_BY_REEL, this.startSpinByReel.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_BY_REEL_ON_COMPLETE, this.startSpinByReelonComplete.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    //eventManager.addListener(EventTypes.END_RESPIN_FREEZE_ANIMATION, this.skipWinSlotsAnimation.bind(this));

    this.parentLayer = layerReel;
  }

  private initSymbols(spinResult: Icon[]) {
    this.cleanSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = animateSlotFactory(spinResult[i * REELS_AMOUNT + j]!.id, j);
        // symbol.x = REEL_WIDTH * Math.floor(j / 2) + SLOT_RESOURCE_WIDTH / 2;
        // symbol.y = SLOT_HEIGHT * (j % 2) + SLOT_RESOURCE_HEIGHT / 2;

        symbol.x = REEL_WIDTH * (j % 2) + SLOT_RESOURCE_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * Math.floor(j / 2) + SLOT_RESOURCE_HEIGHT / 2;

        symbol.manualUpdate();
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
    this.setAllSlotZOrder();
  }
  private onStartSpin(): void {}

  private setupSymbols(
    _reelPositions: number[],
    _anticipationInfo: AnticipationInfo,
    _announceType: AnnounceType,
    spinResult: Icon[],
  ): void {
    this.initSymbols(spinResult);
    this.slotSymbols.forEach((slot) => {
      slot.setSpinningAnimation();
    });
  }

  private cleanSymbols(): void {
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private onChangeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {}

  // Stop
  private onReelStopped(reelId: number, anticipationInfo: AnticipationReelType): void {
    interface AnticipationSettings {
      [key: string]: { slotId: SlotId };
    }
    const tintSettings: AnticipationSettings = {
      Snow: { slotId: SlotId.SC1 },
      Bonus: { slotId: SlotId.SC1 },
    };
    const currentSetting = tintSettings[anticipationInfo.type as keyof AnticipationSettings];

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelId]!;
      if (currentSetting) {
        slot.zOrder =
          slot.slotId === currentSetting.slotId
            ? REELS_Z_ORDER_HIGHLIGHT_SLOT[reelId]!
            : REELS_Z_ORDER_STOP_SLOTS[reelId]!;
      }
      slot.manualUpdate(); // avoid to effect next frame
      if (reelId === 3 && anticipationInfo.type === 'Bonus' && slot.slotId === SlotId.SC1 && !setForceReelStop()) {
        slot.startLongSpinStopAnimation();
      } else {
        slot.startStopAnimation();
      }
      slot.visible = true;
    }
  }

  // Anticipation
  private onAnticipationAnimationStarts(reachSymbols: SlotId[], reelIdx: number, isStopWait = true): void {
    const stopWait = isStopWait
      ? Loader.shared.resources['symbol_sc']!.spineData?.findAnimation('stop').duration! * 1000
      : 1;

    //no sc stop
    const stopReelSymbols = this.slotSymbols.filter(
      (slot, index) => index % REELS_AMOUNT === reelIdx && slot.slotId === reachSymbols[0],
    );
    if (stopReelSymbols.length === 0) {
      return;
    }

    const slotStopDelay = Tween.createDelayAnimation(stopWait);
    slotStopDelay.addOnComplete(() => {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        for (let j = 0; j <= reelIdx; j++) {
          const slot = this.slotSymbols[i * REELS_AMOUNT + j]!;
          if (reachSymbols.includes(slot.slotId)) {
            slot.zOrder = REELS_Z_ORDER_HIGHLIGHT_SLOT[j]!;
            slot.startLongspinAnimation();
          } else {
            slot.zOrder = REELS_Z_ORDER_STOP_SLOTS[j]!;
            slot.skip();
          }
        }
      }
    });
    slotStopDelay.start();
  }

  private onAnticipationEnd(): void {
    this.slotSymbols.forEach((slot) => {
      slot.skip();
    });
  }

  private onAnticipationEndOnlySC(): void {
    this.slotSymbols.forEach((slot) => {
      if (slot.slotId === SlotId.SC1) {
        slot.skip();
      }
    });
  }

  // Winning
  private skipWinSlotsAnimation(_resetTint = true): void {
    this.animation?.skip();
    this.slotSymbols.forEach((slot) => slot.forceStop());
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean): void {
    this.showWin(nextResult);
  }

  private animation?: AnimationChain | undefined;

  private showWin(nextResult: ISettledBet): void {
    const paylines = getWinCombinations(nextResult)! as unknown as IWinLine[];
    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines as unknown as IWinLine[]); // TO DO
    });

    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.attribution.forEach((position) => {
        set.add(getNonNullableValue(position));
      });
    });

    const allSlotsHighlight = this.highlightSlots(Array.from(set));
    const allSlotsHighlight2 = this.highlightSlots(Array.from(set));

    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
      this.setWinSlotZOrder(Array.from(set));
      //eventManager.emit(EventTypes.SHOW_TINT, true);
    });
    allSlotsHighlight2.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.setAllSlotZOrder();
    });

    this.animation.appendAnimation(allSlotsHighlight);
    this.animation.appendAnimation(allSlotsHighlight2);

    //QA-94
    //if (setGameMode() === GameMode.BASE_GAME_RESPIN || setGameMode() === GameMode.BASE_GAME) {
    const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
    this.animation.appendAnimation(eachSlotsHighlight);
    //}

    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((slotPos) => {
      if (!isScatter(this.slotSymbols[slotPos]?.slotId!)) {
        animationGroup.addAnimation(this.slotSymbols[slotPos]!.getWinAnimation());
      }
    });
    return animationGroup;
  }
  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.attribution);

      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        this.setWinSlotZOrder(payline.attribution);
        //eventManager.emit(EventTypes.SHOW_TINT, true);
      });

      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
        this.setAllSlotZOrder();
        eventManager.emit(EventTypes.SHOW_TINT, false);
      });
      animationChain.appendAnimation(chain);
    });
    animationChain.appendAnimation(Tween.createDelayAnimation(WIN_ANIMATION_INTERVAL));
    return animationChain;
  }

  private setAllSlotZOrder(): void {
    this.slotSymbols.forEach((slot, index) => {
      const x = index % REELS_AMOUNT;
      slot.zOrder = slot.slotId === SlotId.SC1 ? REELS_Z_ORDER_STOP_SLOTS_SC[x]! : REELS_Z_ORDER_STOP_SLOTS[x]!;
    });
  }
  private setWinSlotZOrder(slotPositions: number[]): void {
    this.setAllSlotZOrder();

    slotPositions.forEach((slot) => {
      const x = slot % REELS_AMOUNT;
      this.slotSymbols[slot]!.zOrder =
        this.slotSymbols[slot]!.slotId === SlotId.SC1 ? REELS_Z_ORDER_WIN_SLOTS_SC[x]! : REELS_Z_ORDER_WIN_SLOTS[x]!;
    });
  }

  //TO DO
  private onStartFSWinAnimation(): void {
    this.slotSymbols.forEach((slot, index) => {
      if (isScatter(slot.slotId)) {
        const x = index % REELS_AMOUNT;
        slot.zOrder = REELS_Z_ORDER_WIN_SLOTS_SC[x]!;
        slot.startFSWinAnimation();
      } else {
        slot.skip();
      }
    });
  }

  private startSpinByReel(reelIndex: number): void {
    if (setIsRevokeThrowingError()) return;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[REELS_AMOUNT * i + reelIndex]!;
      slot.startSpinStartAnimation();
      slot.visible = true;
    }
  }

  private startSpinByReelonComplete(reelIndex: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelIndex]!;
      slot.visible = false;
    }
  }
}
