import { GameMode } from '../../consts';
import { IAuthInput } from '../../global';
import {
  setBetAmount,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setReelSetType,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getGameSettingsGql } from '../../gql/query';
import { parseQuery } from '../../utils';

export const useGameSetting = () => {
  const getGameSettingsData = async () => {
    const { slotId, clientId } = parseQuery<Omit<IAuthInput, 'lng'>>();
    const {
      data: { gameSettings },
    } = await client.query({
      query: getGameSettingsGql,
      variables: { input: { slotId, clientId } },
      fetchPolicy: 'network-only',
    });
    setSlotConfig({
      ...setSlotConfig(),
      clientSlotSettings: gameSettings.clientSlotSettings,
      slotSettings: gameSettings.slotSettings,
      slotId: gameSettings.slotId,

      //      lineSet: gameSettings.lineSet,
      //      sessionId: gameSettings.sessionId,
      //      reels: gameSettings.reels,
      //      settings: gameSettings.settings,
      //      icons: gameSettings.icons,
      //      lines: gameSettings.lines,
      //      wagerExpirationDurationSeconds: gameSettings.wagerExpirationDurationSeconds,
    });
    setGameMode(GameMode.BASE_GAME);
    setReelSetType(GameMode.BASE_GAME); // TO DO ?

    const coinValue = setUserLastBetResult()?.id
      ? setUserLastBetResult()?.coinValue
      : gameSettings.clientSlotSettings.coinValueSettings.find((elem) => elem.code === setCurrency())?.variants[0];
    setCoinValue(coinValue);
    let coinAmount;
    if (setBrokenGame() || setBrokenBuyFeatureGame()) {
      coinAmount = setCurrentBonus().coinAmount;
      setGameMode(setCurrentBonus().gameMode);
    } else {
      coinAmount = setUserLastBetResult()?.id
        ? setUserLastBetResult()?.coinAmount
        : gameSettings.clientSlotSettings.betCoinAmountSettings[0];
    }
    setCoinAmount(coinAmount);
    setWinAmount(setUserLastBetResult()?.betStorage?.estimatedWinCoinAmount!);
    setBetAmount(setCoinAmount() * gameSettings.slotSettings?.globalCoinAmountMultiplier!);
  };

  return { getGameSettingsData };
};
