import { setCurrency, setUserBalance } from '../../gql/cache';
import client from '../../gql/client';
import { getBalanceGql } from '../../gql/query';

export const useUserBalance = () => {
  const getUserBalance = async () => {
    const userBalance = await client.query({
      query: getBalanceGql,
      fetchPolicy: 'network-only',
    });
    setUserBalance(userBalance.data.balance);
    setCurrency(userBalance.data.balance.currency);
  };

  return {
    getUserBalance,
  };
};
