import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, PayTableSlotId, config } from '../../config';
import { setCoinValue, setSlotConfig, setUserBalance } from '../../gql/cache';
import { getBetAmountGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, getCombosDataFromIcon, getNonNullableValue } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  const totalAmount =
    slug === ResourceTypes.symbolSc1 || slug === ResourceTypes.symbolSc2
      ? betAmount
      : betAmount / setSlotConfig().lineSet.coinAmountMultiplier;
  return (
    countCoins({
      totalAmount: totalAmount,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t, i18n } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery(getBetAmountGql);

  const { betAmount } = dataBet!;
  const currency = userBalance.currency;

  const baseData: IPaytableData[] = useMemo(() => {
    return config.payTableData.flatMap((tableData) => {
      const icon = getNonNullableValue(setSlotConfig()).icons.find((icon) => icon.id === tableData.slug);

      return {
        slug: tableData.slug === PayTableSlotId.Any7 ? ResourceTypes.symbolSc1 : MAPPED_SYMBOLS[tableData.slug],
        combos: icon ? getCombosDataFromIcon(icon) : [],
      };
    });
  }, []);

  return (
    <section>
      <h1 className={styles.title}>{t('infoPayTableTitle')}</h1>
      <div className={styles['paytable-list']}>
        {baseData.map(({ combos, slug }) => (
          <React.Fragment key={slug}>
            {slug === ResourceTypes.symbolSc1 && (
              <div className={styles['paytable-list__snow']}>
                <div className={styles['image-container']}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                </div>
                {i18n.exists(`infoPayTable.${slug}`) && (
                  <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>
                )}
                <p
                  className={styles.explain}
                  dangerouslySetInnerHTML={{
                    __html: t('infoPayTableSnowExplain'),
                  }}
                />
                <div className={styles.content}>
                  {combos.map((i) => (
                    <React.Fragment key={i.pattern}>
                      <div
                        className={styles.scatter}
                        dangerouslySetInnerHTML={{ __html: t('infoPayTableScatter', { num: i.pattern.match(/\d/) }) }}
                      ></div>
                      <div className={styles.multiplier}>
                        {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount, slug) })}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
            {slug !== ResourceTypes.symbolSc1 && (
              <div className={styles['paytable-list__item']}>
                <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                <div className={styles.content}>
                  {combos.map((i) => (
                    <div key={i.pattern}>
                      <span className={styles.multiplier} data-combo={i.pattern}>
                        {i.pattern}{' '}
                      </span>
                      {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount, slug) })}
                    </div>
                  ))}
                </div>
                {i18n.exists(`infoPayTable.${slug}`) && (
                  <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
