import { getFragmentData } from '../../gql';
import { setSlotConfig, setUserLastBetResult } from '../../gql/cache';
import client from '../../gql/client';
import { betFragment } from '../../gql/fragment';
import { slotHistoryGql } from '../../gql/query';

export const useLastBet = () => {
  const getLastBet = async () => {
    const betsData = await client.query({
      query: slotHistoryGql,
      variables: {
        input: { slotId: setSlotConfig().slotId, limit: 10 },
      },
      fetchPolicy: 'network-only',
    });
    if (betsData.data.betHistory.bets[0]) {
      const bets = getFragmentData(betFragment, betsData.data.betHistory.bets);
      setUserLastBetResult(bets[0]);
    }
  };
  /*
  const getLastBetFromBonusBetId = async () => {
    const lastBetData = setUserLastBetResult();
    if (!lastBetData) return;

    const isBrokenGame = setBrokenGame();
    let reelPositions = lastBetData.result.reelPositions;
    let reelSetId = lastBetData.reelSetId;
    let lastRegularReelPositions: number[] = [];

    if (lastBetData.userBonus && lastBetData.userBonus?.betId) {
      lastRegularReelPositions = await getLastRegularBetReelPositions(lastBetData.userBonus?.betId!);
      setLastBaseReelPositions(getNonNullableValue(lastRegularReelPositions));
    } else {
      setLastBaseReelPositions(getNonNullableValue(lastBetData.result.reelPositions));
    }

    //respin end
    if (lastBetData.data.features.gameRoundStore.freezeRespinData.respinReelPositions) {
      reelPositions = lastBetData.data.features.gameRoundStore.freezeRespinData.respinReelPositions;
    }
    //base to FreeSpin
    if (isBrokenGame) {
      if (
        !lastBetData.userBonus ||
        (lastBetData.userBonus && getBonusKind(lastBetData.userBonus.bonusId) === BonusKind.BUY_FEATURE)
      ) {
        if (getBonusKind(setCurrentBonus().bonusId) === BonusKind.FREE_SPINS) {
          reelPositions = FREE_SPIN_START_REEL_POSITIONS;
          reelSetId = setCurrentBonus().bonus?.reelSetId!;
        }
      }
    }
    setUserLastBetResult({
      ...lastBetData!,
      reelSetId: reelSetId,
      result: {
        ...lastBetData!.result,
        reelPositions: reelPositions,
      },
    });
  };
*/
  return {
    getLastBet,
    //    getLastBetFromBonusBetId,
  };
};
