export const REELS_Z_ORDER_REEL = [1, 1, 1, 1];
export const REELS_Z_ORDER_REEL_SC = [3, 3, 3, 3];

export const REELS_Z_ORDER_STOP_SLOTS = [4, 4, 4, 4];
export const REELS_Z_ORDER_STOP_SLOTS_SC = [5, 5, 5, 5];

export const REELS_Z_ORDER_HIGHLIGHT_SLOT = [8, 8, 8, 8];

export const REELS_Z_ORDER_WIN_SLOTS = [11, 11, 11, 11];
export const REELS_Z_ORDER_WIN_SLOTS_SC = [12, 12, 12, 12];

export const REELS_Z_ORDER_TINT_CONTAINER = 10;

export const REEL_LINE_Z_ORDER = 11;
