export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = -50;

// FS BANNER
export const MESSAGE_FS_BANNER_TITLE_Y = -150;
export const MESSAGE_FS_BANNER_YOU_WON_Y = -90;
export const MESSAGE_FS_BANNER_FEAT_NAME_Y = 0;
export const MESSAGE_FS_BANNER_DESCRIPTION_Y = 90;
export const MESSAGE_FS_BANNER_PRESS_Y = 214;

// WIN BANNER
export const MESSAGE_FS_END_BANNER_TITLE_Y = -148;
export const MESSAGE_FS_END_BANNER_WIN_AMOUNT_Y = 0;
export const MESSAGE_FS_END_BANNER_RESPIN_Y = 152;

export const FREESPIN_START_BANNER_TITLE_POS = { x: -7, y: -535 };
export const FREESPIN_START_BANNER_TITLE_PORTRAIT_POS = { x: -7, y: -605 };
export const FREESPIN_START_BANNER_RANK_TITLE_POS = { x: -373, y: -412 };
export const FREESPIN_START_BANNER_RANK_POS = { x: -25, y: -412 };
export const FREESPIN_START_BANNER_DESCRIPTION_POS = { x: -169, y: 307 };
export const FREESPIN_START_BANNER_MULTIPLIER_POS = { x: 328, y: 292 };
