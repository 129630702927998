import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../consts';
import { setIsOpenAutoplayPopup, setIsPopupOpeningInProgress } from '../../gql/cache';
import { configGql } from '../../gql/query';

import AutoPlaySettings from '.';

const AutoPlaySettingsPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const { data: configData } = useQuery(configGql);
  const { isOpenAutoplayPopup, isSpinInProgress } = configData!;

  useEffect(() => {
    if (isOpenAutoplayPopup) {
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenAutoplayPopup);
  }, [isOpenAutoplayPopup]);

  useEffect(() => {
    if (showPopup && isSpinInProgress) {
      setShowPopup(false);
      setIsOpenAutoplayPopup(false);
    }
  }, [isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.AUTOPLAY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);

    setIsOpenAutoplayPopup(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => null}>
      <div className="popup__title">{t('autoPlay')}</div>
      <Button intent="close" className="popup__close" onClick={handleClose} />
      <AutoPlaySettings handleClose={() => handleClose(true)} />
    </Popup>
  );
};

export default AutoPlaySettingsPopup;
