import { SlotId } from '../../../config';

import { IAnimateSlot } from './IAnimateSlot';
import { SpineAnimateSlot } from './spineAnimateSlot';

export function animateSlotFactory(slotId: SlotId, reelIdx: number): IAnimateSlot {
  switch (slotId) {
    default:
      return new SpineAnimateSlot(slotId, reelIdx);

    //    return new SpineAnimateSlot(slotId);
  }
}
