import type { Howl } from 'howler';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, audioSpriteVolume } from '../../config';
import { GameMode } from '../../consts';
import { setBrokenGame, setCurrentBonus } from '../../gql/cache';
import { EventTypes, SlotMachineState, eventManager } from '../../slotMachine/config';
import { getBgTypeForGameMode, getGameModeByBonusId, wait } from '../../utils';
import { BgType } from '../background/background';

declare module 'howler' {
  interface Howl {
    _soundById(id: number): Sound;
  }
  interface Sound {
    //_node: AudioNode;
    _start: number;
    _node: { bufferSource: AudioBufferSourceNode };
  }
}

enum BgmSoundType {
  BASE = 'base',
  FREESPIN1 = 'freeSpin01',
  FREESPIN2 = 'freeSpin02',
  FREESPIN3 = 'freeSpin03',
}
type BgmIndexType = { type: BgmSoundType; song: ISongs };
type BgmType = Record<BgmSoundType, { bgms: ISongs[]; loopStart?: number }>;
type BgmPlayInfo = { song: ISongs; id: number };
type BgmControlType = Record<BgmSoundType, BgmPlayInfo[]>;

const bgTypeToBgmSoundType: Record<BgType, BgmIndexType> = {
  default: { type: BgmSoundType.BASE, song: ISongs.XT005S_bg_base_loop },
  freeSpin01: { type: BgmSoundType.FREESPIN1, song: ISongs.XT005S_bg_challenge1_loop },
  freeSpin02: { type: BgmSoundType.FREESPIN2, song: ISongs.XT005S_bg_challenge2_loop },
  freeSpin03: { type: BgmSoundType.FREESPIN3, song: ISongs.XT005S_bg_challenge3_loop },
};

export const bgmList: BgmType = {
  base: { bgms: [ISongs.XT005S_bg_base_loop], loopStart: 1 },
  freeSpin01: {
    bgms: [ISongs.XT005S_bg_challenge1_loop],
    loopStart: 1,
  },
  freeSpin02: {
    bgms: [ISongs.XT005S_bg_challenge2_loop],
    loopStart: 1,
  },
  freeSpin03: {
    bgms: [ISongs.XT005S_bg_challenge3_loop],
    loopStart: 1,
  },
};

class BgmControl {
  private bgmListIndex: BgmIndexType;

  private bgmPlayInfo: BgmControlType;

  private restrictedHandler: ((restricted: boolean) => void) | undefined;

  private isRestrict: boolean;

  constructor() {
    this.bgmListIndex = { type: BgmSoundType.BASE, song: ISongs.XT005S_bg_base_loop };
    this.bgmPlayInfo = {
      base: [],
      freeSpin01: [],
      freeSpin02: [],
      freeSpin03: [],
    };
    this.isRestrict = false;
    this.restrictedHandler = undefined;
    AudioApi.on('restricted', (restricted: boolean) => {
      if (this.restrictedHandler) {
        this.restrictedHandler(restricted);
      }
    });

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  public get currentBgmType(): BgmSoundType {
    return this.bgmListIndex.type;
  }

  private get howl(): Howl {
    return AudioApi['audioHowl'];
  }

  private bgmPlayWait = (id: number): Promise<void> => {
    return new Promise((resolve) => {
      this.howl.once('play', () => resolve(), id);
    });
  };

  private waitUnrestricted(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.restrictedHandler) {
        this.restrictedHandler = (restricted: boolean) => {
          if (!restricted) {
            resolve();
            this.restrictedHandler = undefined;
          }
        };
      }
    });
  }

  private async initBgm(): Promise<void> {
    for (const [key, obj] of Object.entries(bgmList)) {
      const soundType = key as BgmSoundType;
      const loopStart = obj.loopStart;

      if (AudioApi.isRestricted) await this.waitUnrestricted();
      this.bgmPlayInfo[soundType] = await Promise.all(
        obj.bgms.map(async (song) => {
          const id = AudioApi.play({ type: song, volume: 0 });
          await this.bgmPlayWait(id);
          if (loopStart) {
            this.setLoopBgm(id, loopStart);
          }
          return { song, id };
        }),
      );
    }
  }

  private onSlotMachineStateChange(_state: SlotMachineState) {}

  private onModeChange({ bgType }: { mode: GameMode; bgType?: BgType }) {
    if (setBrokenGame() || bgType === undefined) {
      return;
    }
    const bgmListIndex = bgTypeToBgmSoundType[bgType];
    const fromStartConditions = [
      this.bgmListIndex.type === BgmSoundType.BASE && bgmListIndex.type === BgmSoundType.FREESPIN1,
      this.bgmListIndex.type === BgmSoundType.FREESPIN1 && bgmListIndex.type === BgmSoundType.FREESPIN2,
      this.bgmListIndex.type === BgmSoundType.FREESPIN2 && bgmListIndex.type === BgmSoundType.FREESPIN3,
      this.bgmListIndex.type !== BgmSoundType.BASE && bgmListIndex.type === BgmSoundType.BASE,
    ];

    if (this.bgmListIndex.type === BgmSoundType.BASE && bgmListIndex.type === BgmSoundType.FREESPIN1) {
      return;
    }
    if (this.bgmListIndex.type === BgmSoundType.FREESPIN1 && bgmListIndex.type === BgmSoundType.FREESPIN2) {
      return;
    }
    if (this.bgmListIndex.type === BgmSoundType.FREESPIN2 && bgmListIndex.type === BgmSoundType.FREESPIN3) {
      return;
    }

    const fromStart = fromStartConditions.some((cond) => cond === true);

    this.playBgm(bgType, fromStart, fromStart);
  }

  public playBgm(bgType?: BgType, forceRestart = false, fromStart = false): void {
    if (this.isRestrict && !forceRestart) {
      return;
    }
    this.isRestrict = false;
    if (AudioApi.isRestricted) {
      return;
    }

    const bgmListIndex = bgType ? bgTypeToBgmSoundType[bgType] : this.bgmListIndex;
    const shouldRestart = this.bgmListIndex.type !== bgmListIndex.type || forceRestart;
    const shouldResume = this.bgmListIndex.type === bgmListIndex.type && this.bgmListIndex.song !== bgmListIndex.song;

    if (shouldRestart || shouldResume) {
      this.stopAllExceptIndex(bgmListIndex);
      this.bgmListIndex = bgmListIndex;
    }

    if (shouldRestart) {
      this.reStartBgm(bgmListIndex, fromStart);
    } else {
      this.resumeBgm(bgmListIndex);
    }
  }

  private resumeBgm(bgmListIndex: BgmIndexType) {
    this.bgmPlayInfo[bgmListIndex.type].forEach((info) => {
      if (AudioApi.getSoundByKey(bgmListIndex.song).id === info.id) {
        this.resetVolume(info.song);
      }
    });
  }

  private reStartBgm(bgmListIndex: BgmIndexType, fromStart?: boolean): void {
    const loopStart = bgmList[bgmListIndex.type]!.loopStart ?? 0;
    const seekPoint = fromStart === true ? 0 : loopStart;

    this.bgmPlayInfo[bgmListIndex.type].forEach((info) => {
      this.seekBgm(info.id, seekPoint);
      this.setLoopBgm(info.id, loopStart); // loopstart is reset by seek
    });
    this.resumeBgm(bgmListIndex);
  }

  private seekBgm(bgmId: number, seekPoint: number): void {
    const sound = this.howl['_soundById'](bgmId);
    this.howl.seek((sound['_start'] as number) + seekPoint / 1000, bgmId);
  }

  private setLoopBgm(bgmId: number, loopStart: number): void {
    const sound = this.howl['_soundById'](bgmId);
    const node = sound['_node'];
    if (node.bufferSource) {
      node.bufferSource.loopStart = (sound['_start'] as number) + loopStart / 1000;
    }
  }

  public stopAll(): void {
    if (AudioApi.isRestricted) {
      return;
    }
    Object.values(this.bgmPlayInfo).forEach((infos) => {
      infos.forEach((info) => {
        AudioApi.setTrackVolume(info.song, 0);
      });
    });
  }

  public stopAllExceptIndex(bgmListIndex: BgmIndexType): void {
    if (AudioApi.isRestricted) {
      return;
    }
    Object.values(this.bgmPlayInfo).forEach((infos) => {
      infos.forEach((info) => {
        if (info.id && AudioApi.getSoundByKey(bgmListIndex.song).id != info.id) {
          AudioApi.setTrackVolume(info.song, 0);
        }
      });
    });
  }

  public stopBgm(bgmListIndex = this.bgmListIndex): void {
    if (AudioApi.isRestricted) {
      return;
    }
    this.bgmPlayInfo[bgmListIndex.type].forEach((info) => {
      if (AudioApi.getSoundByKey(bgmListIndex.song).id === info.id) {
        AudioApi.setTrackVolume(info.song, 0);
      }
    });
  }

  /*
  public isPlayBgm(bgm?: BgmSoundTypes): boolean {
    if (bgm === undefined) {
      return AudioApi.isPlaying(bgmList[this.bgmListIndex]!.base);
    } else {
      //pause is false
      return AudioApi.isPlaying(bgmList[bgm]!.base);
    }
  }
*/
  public fadeInAll(fadeTime: number): void {
    this.fadeInBase(fadeTime);
  }

  public fadeInBase(fadeTime: number): void {
    const bgmName = this.bgmListIndex.song as ISongs;
    AudioApi.fadeIn(fadeTime, bgmName, audioSpriteVolume[bgmName]);
    //setTimeout(() => {}, fadeTime);
  }

  public fadeOutAll(fadeTime: number): void {
    AudioApi.fadeOut(fadeTime, this.bgmListIndex.song);
    //setTimeout(() => {}, fadeTime);
    //  this.howl.fade(0.3, 0, 10, 1002);
    //    fade: function(from, to, len, id) {
  }

  private resetVolume(song: ISongs): void {
    const volume = audioSpriteVolume[song] || 0.3; // TO DO
    AudioApi.setTrackVolume(song, volume);
  }

  public setRestrict(flg: boolean) {
    this.isRestrict = flg;
  }

  public async handleChangeRestriction(): Promise<void> {
    await this.initBgm();
    await wait(10);

    const gamemode = getGameModeByBonusId(setCurrentBonus().packageId);
    const bgType: BgType = getBgTypeForGameMode(gamemode);

    const forceRestart = true;
    this.playBgm(bgType, forceRestart, true);
  }
}

const bgmControl = new BgmControl();

export { bgmControl as BgmControl };
export default bgmControl;
