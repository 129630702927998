import { SlotId } from '../config';
import { GameMode } from '../consts';
import {
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinValue,
  setCurrentBonus,
  setIsAutoSpins,
  setIsBuyFeatureSpin,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
} from '../gql/cache';
import { IBuyFeaturePackages } from '../gql/d';
import { ISettledBet } from '../types';

import { getBetData } from './fragments';
import { urlSearchParams } from './helper';
import { isFishingChallengeMode } from './specific';

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME || mode === GameMode.BUY_FEATURE;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isFreeSpinsBaseMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isFreeSpinsGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isScatter = (slotId: SlotId): boolean => {
  if (slotId === SlotId.SC1 || slotId === SlotId.SC2 || slotId === SlotId.SC3 || slotId === SlotId.SC4) {
    return true;
  }
  return false;
};

export const isBuyFeatureMode = (): boolean => {
  return setIsBuyFeatureSpin();
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isBuyFeaturePopupOpened,
  isOpeningScenePlaying,
  isSpinShortCut,
  isAutoPlay,
  isWheelSpinning,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isBuyFeaturePopupOpened: boolean;
  isOpeningScenePlaying: boolean;
  isSpinShortCut: boolean;
  isAutoPlay: boolean;
  isWheelSpinning: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }
  if (isAutoPlay) {
    return false;
  }
  if (gameMode === GameMode.BASE_GAME && isFreeSpinsWin) {
    return false;
  }
  if (isFreeSpinsMode(gameMode) && isOpenedMessageBanner) {
    return true;
  }
  if (isFreeSpinsMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isFishingChallengeMode(gameMode) && isWheelSpinning) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }
  if (isOpeningScenePlaying) {
    return false;
  }
  if (isSpinShortCut) {
    return false;
  }

  return true;
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

//TO DO
export const isBuyFeatureEnabled = (features: IBuyFeaturePackages[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.packageId === 'freeSpins'); //confirm package id

  return freeSpinFeature?.packageId !== undefined;
};

export const isDemo = urlSearchParams.has('isDemo');

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

//TO DO
export const hasFreeSpinBonus = (): boolean => {
  /*  const freeSpinsBonus = getFreeSpinBonus();
  if (freeSpinsBonus) {
    const isBonus = getBonusKind(freeSpinsBonus.bonus!.id);
    return isBonus === BonusKind.FREE_SPINS;
  }
    */
  return false;
};

export const hasWin = (nextResult: ISettledBet | null): boolean => {
  if (!nextResult) return false;

  return getBetData(nextResult).betStorage?.estimatedWinCoinAmount! > 0;
};

export const checkNonBonusAutoSpinStop = (nextResult: ISettledBet | null): boolean => {
  if (setIsAutoSpins()) {
    const balanceAmount = nextResult ? nextResult.balance.settled.amount : 0;
    const autoSpinsLeft = setAutoSpinsLeft() <= 0;
    const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus());

    const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

    const balanceIncrease =
      setIsStopOnBalanceIncrease() &&
      balanceAmount &&
      setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

    const balanceDecrease =
      setIsStopOnBalanceDecrease() &&
      balanceAmount &&
      setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

    if (autoSpinsLeft || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
      return true;
    }
  }
  return false;
};
