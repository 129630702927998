import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';

import { setIsInTransition } from '../../gql/cache';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { layerTransition } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { TransitionBlizzardType, TransitionFadeType, TransitionProps } from './d';

export class Transition {
  private readonly application: Application;

  private static fadeScreen: Transition;

  public static initScreen = (application: Application): void => {
    Transition.fadeScreen = new Transition(application);
  };
  public static getInstance = (): Transition => Transition.fadeScreen;

  private transitionAnimation: Animation;
  private container: PIXI.Container;
  private sprite: PIXI.Sprite;

  private spine: TickerSpine<'title_logo'>;
  private bindedResize = this.resize.bind(this);
  private bindedStartTransition = this.startTransition.bind(this);

  private constructor(application: Application) {
    this.application = application;

    this.container = new PIXI.Container();
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.tint = 0xffffff;
    this.sprite.alpha = 0;
    this.container.addChild(this.sprite);

    this.spine = new TickerSpine<'title_logo'>('title_logo');
    this.spine.state.setEmptyAnimation(0);
    this.container.addChild(this.spine);

    this.application.stage.addChild(this.container);

    this.transitionAnimation = new AnimationGroup();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.TRANSITION_START, this.bindedStartTransition);

    this.container.parentLayer = layerTransition;
  }

  private createFadeTransition(settings: TransitionFadeType): Animation {
    if (settings.tint !== undefined) {
      this.sprite.tint = settings.tint;
    }
    const animationChain: AnimationChain = new AnimationChain();
    if (settings.fadeOutDuration) {
      const fadeOut = this.getFadeAnimation(1, settings.fadeOutDuration, 0);
      fadeOut.addOnComplete(() => {
        settings.callback && settings.callback();
      });
      animationChain.appendAnimation(fadeOut);
    }
    if (settings.fadeInDuration) {
      const fadeIn = this.getFadeAnimation(0, settings.fadeInDuration, 1);
      animationChain.appendAnimation(fadeIn);
    }
    return animationChain;
  }

  private createBlizzardTransition(settings: TransitionBlizzardType): Animation {
    if (settings.tint !== undefined) {
      this.sprite.tint = settings.tint;
    }
    const animationChain: AnimationChain = new AnimationChain();
    const spineOut = Tween.createDelayAnimation(settings.fadeOutDuration);
    spineOut.addOnStart(() => {
      this.spine.state.setAnimation(0, 'base');
      this.spine.state.addEmptyAnimation(0);
    });
    spineOut.addOnComplete(() => {
      settings.callback && settings.callback();
    });
    animationChain.appendAnimation(spineOut);

    const fadeInAnimation = Tween.createDelayAnimation(settings.fadeInDuration);
    animationChain.appendAnimation(fadeInAnimation);

    return animationChain;
  }

  private startTransition(settings: TransitionProps): void {
    if (setIsInTransition()) {
      return;
    }

    this.transitionAnimation.skip();
    let transitionAnimation: Animation;

    if (settings.type === 'Normal') {
      settings.callback && settings.callback(); //create anime??
      return;
    } else if (settings.type === 'Fade') {
      transitionAnimation = this.createFadeTransition(settings);
    } else if (settings.type === 'Blizzard') {
      transitionAnimation = this.createBlizzardTransition(settings);
    } else {
      return;
    }

    if (transitionAnimation) {
      transitionAnimation.addOnStart(() => {
        this.container.interactive = false;
        setIsInTransition(true);
      });

      transitionAnimation.addOnComplete(() => {
        this.container.interactive = false;
        setIsInTransition(false);
      });
      transitionAnimation.start();
    }
    //fade in fade?
    this.transitionAnimation = transitionAnimation;
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
    this.sprite.width = width;
    this.sprite.height = height;

    const scale = width > height ? width / 2000 : height / 2000;
    this.spine.scale.set(scale);
    this.spine.position.set(width / 2, height / 2);
  }

  private destroy(): void {
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
    this.container.destroy({ children: true });
  }
}
