import * as PIXI from 'pixi.js';

import { GameMode } from '../../consts';
import { layerBackGround } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

export type BgType = 'default' | 'freeSpin01' | 'freeSpin02' | 'freeSpin03';

class Background extends PIXI.Container {
  private base: TickerSpine<'bg_basegame'> = new TickerSpine('bg_basegame');

  private currentBgType: BgType;

  constructor(_bgType: BgType = 'default') {
    super();

    this.addChild(this.base);

    this.base.state.setAnimation(0, 'base', true);

    this.currentBgType = 'default';
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    this.scale.set(1);

    this.parentLayer = layerBackGround;
  }

  private onChangeMode(settings: { mode: GameMode; bgType?: BgType }) {
    const { bgType } = settings;
    if (bgType === undefined) return;

    if (this.currentBgType != bgType) {
      this.currentBgType = bgType;
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 2000, height: 2000 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / size.height;
    } else {
      scale = width / size.width;
    }
    this.base.scale.set(scale);
  }
}

export default Background;
