import TaggedText from 'pixi-tagged-text';
import { IPointData } from 'pixi.js';

import { AutoResizeTextStyle } from '../slotMachine/components/autoResizeText';
import { introTitleStylesInfo } from '../slotMachine/introScreen/Carousel/styles';

import { SpineInterface } from './spine.generated';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const INTRO_CAROUSEL_WIDTH = 1200;
export const INTRO_CAROUSEL_HEIGHT = 800;
export const INTRO_FRAME_SPINE_HEIGHT = 600;

export interface ISlideText {
  i18nKey: string;
  textStyle: TextStyleSet | AutoResizeTextStyle;
  position: IPointData;
}

export interface ISlideImage {
  spineName: keyof SpineInterface;
  animationName: string;
  position: IPointData;
}
export interface ISlide {
  textInfos: readonly ISlideText[];
  spine: ISlideImage | undefined;
}

export const introContents = [
  {
    textInfos: [
      { i18nKey: 'introTitle.page1.tagged', textStyle: introTitleStylesInfo, position: { x: 0, y: 580 } },
    ] as const,
    spine: {
      spineName: 'bg_basegame',
      animationName: 'base',
      position: { x: 0, y: 0 },
    },
  },
  {
    textInfos: [
      { i18nKey: 'introTitle.page2.tagged', textStyle: introTitleStylesInfo, position: { x: 0, y: 580 } },
    ] as const,
    spine: {
      spineName: 'bg_basegame',
      animationName: 'base',
      position: { x: 0, y: 0 },
    },
  },
  {
    textInfos: [
      { i18nKey: 'introTitle.page3.tagged', textStyle: introTitleStylesInfo, position: { x: 0, y: 580 } },
    ] as const,
    spine: {
      spineName: 'bg_basegame',
      animationName: 'base',
      position: { x: 0, y: 0 },
    },
  },
] as const satisfies readonly ISlide[];

export const INTRO_OUTER_TEXT_POSITION: IPointData = {
  x: 80 - INTRO_CAROUSEL_WIDTH / 2,
  y: 678,
};

export const INTRO_DOT_POSITION_Y = INTRO_CAROUSEL_HEIGHT - 90;
